import * as THREE from 'three';
import glslify from 'glslify';
import { gsap } from 'gsap';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import { clamp, map } from '../../../../lib/utils/math.utils';
import { getGoal } from '../../../../lib/utils/match.utils';

import BaseRibbon from './BaseRibbon';

export default class Wire extends BaseRibbon {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Wire';

		this.settings = {
			...this.settings,
			count 			: 8,
			segments 		: 7,
			height 			: 100,
			taperEdge 	: 1.0,
			colorIdx 		: 0,
			// taperFreq 	: 0.05,
			// rotXFreq 		: 0.02,
			// rotZFreq 		: 0.05,
		};

		this.lines = [];

		this.tween = {
			taperY: this.settings.taperY,
			height: this.settings.height,
			range: { min: this.settings.range.min, max: this.settings.range.max },
			...this.tween
		};

		this.initLines();
		this.initData(data);
		this.initGUI();
		this.restoreAnimationSettings();

		// HACK: Line2 needs to be recreated when segments change
		// I don't know why...
		this.removeAndRecreate();
	}

	initLines() {
		const { path } = this;
		const { count } = this.settings;

		for (let i = 0; i < count; i++) {
			const geometry = new LineGeometry();
			const material = new LineMaterial({ linewidth: 2 });
			const mesh = new Line2(geometry, material);

			// use resolution stored in parent path
			material.resolution.copy(path.res);

			this.lines.push(mesh);
			this.object3D.add(mesh);
		}
	}

	updatePositions(skipParent) {
		if (skipParent != true) {
			if (!super.updatePositions()) return;
		}

		const { geometry, lines, vec, vecA, vecB } = this;
		const { segments, count } = this.settings;

		let t, line, positions;

		for (let i = 0; i < count; i++) {
			positions = [];
			line = lines[i];
			t = i / (count - 1);

			for (let j = 0; j < segments + 1; j++) {
				const idxA = j;
				const idxB = j + segments + 1;

				// segment A
				vecA.set(
					geometry.attributes.position.getX(idxA),
					geometry.attributes.position.getY(idxA),
					geometry.attributes.position.getZ(idxA),
				);
				// segment B
				vecB.set(
					geometry.attributes.position.getX(idxB),
					geometry.attributes.position.getY(idxB),
					geometry.attributes.position.getZ(idxB),
				);

				vec.subVectors(vecB, vecA);
				vec.multiplyScalar(t);
				vec.add(vecA);

				positions.push(vec.x, vec.y, vec.z);
			}

			line.geometry.setPositions(positions);
			line.computeLineDistances();
		}
	}

	updateColors(skipUpdateGUI) {
		const { colorIdx } = this.settings;
		const { lines } = this;

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];
		const hex 	= kit.colors[colorIdx];

		lines.forEach(line => line.material.color.setStyle(hex));

		if (!skipUpdateGUI) this.updateGUIColorRadio();
	}

	updatePlayhead(ini = 0, end = 1) {
		super.updatePlayhead(ini, end);
		this.updatePositions(true);
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	restoreAnimationSettings() {
		this.tween.range.min = this.settings.range.min;
		this.tween.range.max = this.settings.range.max;
		this.tween.height 	 = this.settings.height;
	}

  preShow() {
    this.lines.forEach(line => line.visible = false);
  }

	show() {
		this.restoreAnimationSettings();
		return super.show();
	}

	hide() {
		this.restoreAnimationSettings();
		return super.hide();
	}

	onShowUpdate(t) {
		const { tween, settings } = this;
		const { range } = this.settings;

    this.lines.forEach(line => line.visible = true);
		
		tween.range.max = clamp(t, range.min, range.max);
		tween.height = map(t, range.min, range.max * 0.5, 1, settings.height);
		tween.taperY = map(t, range.min, range.max, 7, 8);
		
		this.updatePositions();
	}

	onHideUpdate(t) {
		const { tween, settings } = this;
		const { range } = this.settings;

		tween.range.min = clamp(t, range.min, range.max);
		tween.height = map(t, range.min, range.max, settings.height, 1);

		if (t >= 1) this.lines.forEach(line => line.visible = false);
		
		this.updatePositions();
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

    if (!folder) return;

		folder.addInput(settings, 'segments', 	{ min: 2, max: 20,  step: 1 }).on('change', this.onSegmentsChange.bind(this));
		folder.addInput(settings, 'height', 		{ min: 1, max: 200, step: 1 }).on('change', this.onAnimationSettingsChange.bind(this));
		
		folder.addInput(settings, 'count', 			{ min: 2, max: 10, step: 1 }).on('change', this.onCountChange.bind(this));

		folder.addInput(settings, 'taperEdge', 	{ min: 0, max: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'taperFreq', 	{ min: 0, max: 1 }).on('change', this.onAnimationSettingsChange.bind(this));
		// folder.addInput(settings, 'rotXFreq', 	{ min: 0, max: 1 }).on('change', this.updatePositions.bind(this));
		// folder.addInput(settings, 'rotZFreq', 	{ min: 0, max: 1 }).on('change', this.updatePositions.bind(this));
	}

	onSegmentsChange() {
		this.restoreAnimationSettings();
		this.onGeometryChange();
		this.removeAndRecreate();
	}

	onAnimationSettingsChange() {
		this.restoreAnimationSettings();
		this.updatePositions();
	}

	onCountChange() {
		this.removeAndRecreate();
	}

	// LineGeometry.setPositions is not enough to update the geometry
	// so it needs to be removed and recreated
	removeAndRecreate() {
		// remove old
		this.object3D.clear();
		this.lines.length = 0;

		// recreate
		this.initLines();
		// update
		this.updatePositions();
		this.updateColors();
	}

	onRangeChange() {
		this.restoreAnimationSettings();
		this.updatePositions();
	}
}
