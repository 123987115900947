.match
  .lines
    .top
      span
    .middle
      span
    .bottom
      span
  .team.team-1

  .team.team-2
