import * as THREE from 'three';
import { gsap } from 'gsap';
import glslify from 'glslify';

import Style from './Style';

import { getGoal } from '../../../../lib/utils/match.utils';
import { clamp } from '../../../../lib/utils/math.utils';
import { getPolygonShape } from '../../../../lib/utils/shape.utils';

export default class Ring extends Style {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Ring';

		this.settings 	= {
			offset 				: 0.25,
			radius 				: 25,
			colorIdx			: 0,
			innerRadius 	: 0.8,
			outerRadius 	: 0.98,
			billboard 		: false,
			sides 				: 10,
			...this.settings
		};

		this.vec 	= new THREE.Vector3();
		this.pos  = new THREE.Vector3();
		this.fwd 	= new THREE.Vector3();
		this.nml 	= new THREE.Vector3();
		this.up 	= new THREE.Vector3(0, 1, 0);


		this.initData(data);

		this.tween = {
			scale: 1,
			innerRadius: this.settings.innerRadius,
			...this.tween
		};

		this.initMesh();
		this.initGUI();
		this.updatePositions();
		this.updateColors();
		this.restoreAnimationSettings();
	}

	initMesh() {
		const { outerRadius, sides, radius } = this.settings;
		const { innerRadius } = this.tween;

		// let inner = innerRadius < outerRadius ? innerRadius : outerRadius;
		// let outer = innerRadius < outerRadius ? outerRadius : innerRadius;
		let inner = innerRadius;
		let outer = outerRadius;

		const shape = getPolygonShape(sides, outer, inner);

		const geometry = new THREE.ExtrudeGeometry(shape, { depth: 0.5, bevelEnabled: false });
		const material = new THREE.MeshBasicMaterial();
		const mesh = new THREE.Mesh(geometry, material);

		// mesh.visible = innerRadius != outerRadius;
		mesh.visible = innerRadius < outerRadius;

		this.mesh = mesh;
		this.object3D.add(mesh);
	}

	updatePositions() {
		const { path, mesh, tween } = this;
		const { vec, pos, fwd, nml, up } = this;
		const { offset, radius } = this.settings;
		const { innerRadius } = this.tween;

		const scale = radius * 2 * tween.scale;
		const u = offset;

		if (!mesh) return;

		path.curve.getPointAt(u, pos);
		path.curve.getTangentAt(u, fwd);

		mesh.position.copy(pos);
		mesh.scale.set(scale, scale, 1);

		mesh.matrix.lookAt(pos, fwd.add(pos), up);
		mesh.quaternion.setFromRotationMatrix(mesh.matrix);
	}

	updateColors(skipUpdateGUI) {
		const { colorIdx } = this.settings;
		const { mesh } = this;

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];
		const lng 	= kit.colors.length;
		const hex 	= kit.colors[colorIdx % lng];

		mesh.material.color.setStyle(hex);

		if (!skipUpdateGUI) this.updateGUIColorRadio();
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	restoreAnimationSettings() {
		this.tween.innerRadius = this.settings.innerRadius;
	}

	show() {
		gsap.killTweensOf(this.tween);
		this.offsetShowStarted = false;
		this.tween.scale = 0;

		this.restoreAnimationSettings();
		this.updatePositions();
		
		return super.show();
	}

	hide() {
		return super.hide();
	}

	onShowUpdate(t) {
		const { tween, settings } = this;
		const { offset } = this.settings;
		
		// only call show when t reaches offset
		if (this.offsetShowStarted) return;
		if (!t || t < offset) return;

		this.offsetShow();
	}

	offsetShow() {
		this.offsetShowStarted = true;

		const { tween, settings } = this;

		// const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const timeline = gsap.timeline({ onUpdate: () => this.onGeometryChange() });

		timeline.to(tween, { scale: 1, duration: 0.7, ease: 'expo.out' }, 0.0);
		timeline.to(tween, { innerRadius: 1, duration: 2.0, ease: 'quart.inOut' }, 0.0);

		this.timelineShow = timeline;

		return timeline;
	}

	dispose() {
		this.timelineShow?.kill();

		super.dispose();
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	// since the ring hides automatically
	// we can make it visible by going back to edit mode
	onEditModeChange(value) {
		if (!value) return;

		this.tween.scale = 1;
		
		this.restoreAnimationSettings();
		this.onGeometryChange();
		this.updatePositions();
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

    if (!folder) return;

		// remove default children
		while (folder.children.length) folder.remove(folder.children[0]);

		folder.addInput(settings, 'offset', 			{ min: 0, max: 1  					}).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'sides', 				{ min: 3, max: 16, 	step: 1 }).on('change', this.onGeometryChange.bind(this));
		folder.addInput(settings, 'radius', 			{ min: 1, max: 100, step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'innerRadius',	{ min: 0, max: 1  					}).on('change', this.onAnimationSettingsChange.bind(this));
		// folder.addInput(settings, 'outerRadius',	{ min: 0, max: 1  					}).on('change', this.onAnimationSettingsChange.bind(this));
		// folder.addInput(settings, 'billboard').on('change', this.updatePositions.bind(this));

		return folder;
	}

	onAnimationSettingsChange() {
		this.restoreAnimationSettings();
		this.onGeometryChange();
	}

	onGeometryChange() {
		const { object3D, mesh } = this;
		if (!object3D) return;

		// remove old
		if (mesh) mesh.geometry.dispose();
		if (mesh) mesh.material.dispose();
		object3D.clear();

		this.initMesh();
		this.updatePositions();
		this.updateColors();
	}
}
