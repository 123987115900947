uniform vec3 uColor;

varying vec2 vUv;
varying vec3 vColor;

float circle (vec2 uv, float radius) {
	vec2 dist = uv - vec2(0.5);
	return 1.0 - smoothstep(radius - (radius * 0.02), radius + (radius * 0.02), dot(dist, dist) * 4.0);
}

void main() {
	vec2 uv = vUv;
	vec4 color = vec4(vColor, 1.0);

	// color.a = circle(uv, 1.0);
	// if (color.a < 0.5) discard;

	gl_FragColor = color;
}