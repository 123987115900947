import autoBind from 'auto-bind';
import parseHTML from '../../lib/utils/parseHTML';
import KnockoutTable from '../../components/matches/knockout/Knockout';
import Groups from '../../components/matches/groups/Groups';
import VanillaSwipe from 'vanilla-swipe';
import gsap from 'gsap';
import ArrowButton from '../../components/matches/arrow-button/ArrowButton';

const isTouchEventsSupported = VanillaSwipe.isTouchEventsSupported();

import tmpl from './matches.pug';
import './matches.scss';

class Matches {
  rendered = false;
  constructor() {
    autoBind(this);
    this.knockout = new KnockoutTable();
    this.groups = new Groups();
  }
  render() {
    this.el = parseHTML(tmpl());
    this.btKnockout = new ArrowButton();
    this.btGroups = new ArrowButton();
    this.container = this.el.querySelector('.container');
    this.btToggle = this.el.querySelector('.toggle-view');
    this.knockout.render(this.el.querySelector('.knockout-table'));
    this.el.querySelector('.group-table').appendChild(this.groups.render());
    document.querySelector('.page').appendChild(this.el);
    this.el.querySelector('.bottom-nav').appendChild(this.btKnockout.render());
    this.el.querySelector('.top-nav').appendChild(this.btGroups.render());
    this.rendered = true;
  }

  transitionIn(req, done) {
    if (!this.rendered) {
      this.render();
    } else {
    }
    gsap.to(this.el, {
      x: '0%',
      ease: 'power4.inOut',
      duration: 0,
    });
    this.gotoKnockout();
    this.events();
    done();
  }

  transitionOut(req, done) {
    this.view = 'knockout';
    this.gotoGroups();
    gsap.to(this.el, {
      x: '-110%',
      ease: 'power4.inOut',
      duration: 0,
      onComplete: () => {
        this.groups.transitionOut();
        this.knockout.transitionOut();
      },
    });
    this.dispose();
    done();
  }

  dispose() {
    if (this.VS) {
      this.VS.destroy();
    }
    this.scrolled = false;
    this.btKnockout.events.off('click', this.gotoKnockout);
    this.btGroups.events.off('click', this.gotoGroups);
    this.groups.dispose();
    this.knockout.dispose();
    window.removeEventListener('wheel', this.onWheel);
  }

  gotoKnockout() {
    if (this.view === 'knockout') {
      this.scrolled = false;
      return;
    }
    this.view = 'knockout';
    this.btKnockout.outro();
    gsap.to(this.container, { y: '-100vh', ease: 'expo.out', duration: 0.75 });
    this.groups.transitionOut();
    this.btGroups.intro();
    this.knockout.transitionIn().add(() => {
      this.scrolled = false;
    });
  }

  gotoGroups() {
    if (this.view === 'groups') {
      this.scrolled = false;
      return;
    }
    this.view = 'groups';
    this.btGroups.outro();
    gsap.to(this.container, { y: '0vh', ease: 'expo.out', duration: 0.75 });
    this.btKnockout.intro();
    this.groups.transitionIn().add(() => {
      this.scrolled = false;
    });
    this.knockout.transitionOut();
  }

  events() {
    if (isTouchEventsSupported) {
      this.VS = new VanillaSwipe({
        element: window.document.body,
        directionDelta: 50,
        onSwipeStart: this.onSwipe,
        mouseTrackingEnabled: true,
      });

      this.VS.init();
    }
    this.btKnockout.events.off('click', this.gotoKnockout);
    this.btKnockout.events.on('click', this.gotoKnockout);
    this.btGroups.events.off('click', this.gotoGroups);
    this.btGroups.events.on('click', this.gotoGroups);
    window.removeEventListener('wheel', this.onWheel);
    window.addEventListener('wheel', this.onWheel);
  }

  onSwipe(e, swipeE) {
    if (!this.scrolled) {
      const down = swipeE.deltaY > 0;
      this.direction = down ? 1 : -1;

      if (this.direction === -1) {
        this.gotoGroups();
      } else {
        this.gotoKnockout();
      }
    }
  }

  onWheel(e) {
    if (!this.scrolled) {
      this.scrolled = true;
      const down = e.wheelDelta < 0;

      this.direction = down ? -1 : 1;

      if (this.direction === 1) {
        this.gotoGroups();
      } else {
        this.gotoKnockout();
      }
    }
  }

  resize() {
    this.knockout.resize();
    this.groups.resize();
  }
}

export default new Matches();
