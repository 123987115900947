import parseHTML from '../../../lib/utils/parseHTML';
import gsap from 'gsap';
import ways from 'ways';
import Flag from '../flag/Flag';
import CoverFlag from '../cover-flag/CoverFlag';
import isTouchDevice from '../../../lib/utils/isTouchDevice';

import tmpl from './groups.pug';
import './groups.scss';
import { getGroups } from '../../../lib/utils/match.utils';
import autoBind from 'auto-bind';
import { breakpoints } from '../../../lib/mediaQueries';

export default class Groups {
  el = undefined;
  teams = [];
  matches = [];
  matchesDO = [];
  toggle = undefined;

  constructor() {
    autoBind(this);
  }

  render(parent) {
    this.parent = parent;
    this.groups = getGroups();

    this.el = parseHTML(tmpl({ groups: this.groups }));
    this.addTeams();
    setTimeout(() => {
      this.resize();
    });
    return this.el;
  }

  addTeams() {
    this.matchesDO = [];
    this.matches = [];
    let columns = this.el.querySelectorAll('.group');
    columns.forEach((group, groupIndex) => {
      let matches = group.querySelectorAll('.group-match');
      matches.forEach((match, matchIndex) => {
        let m = this.groups[groupIndex][matchIndex];
        this.matchesDO.push(m);
        let team1 = new CoverFlag(m.done ? m.teamDOs[0] : null);
        let team2 = new CoverFlag(m.done ? m.teamDOs[1] : null);
        match.appendChild(team1.render());
        match.appendChild(team2.render());
        match.setAttribute('data-match-id', m.id);
        this.teams.push(team1);
        this.teams.push(team2);
        match.setAttribute('data-flag1-index', this.teams.length - 2);
        match.setAttribute('data-flag2-index', this.teams.length - 1);
        this.matches.push(match);
      });
    });
  }

  events() {
    this.matches.forEach((m, i) => {
      let matchDO = this.matchesDO[i];
      if (matchDO.done) {
        m.addEventListener('mouseenter', this.onOver);
        m.addEventListener('mouseleave', this.onOut);
        m.addEventListener('click', this.onClick);
      } else {
        m.style.pointerEvents = 'none';
      }
    });
  }

  onClick(e) {
    let id = e.currentTarget.getAttribute('data-match-id');
    ways.go(`/match/${id}`);
  }

  dispose() {
    this.matches.forEach((m, i) => {
      m.classList.remove('over');
    });
  }

  onOver(e) {
    e.currentTarget.classList.add('over');
  }

  resetZIndex() {
    this.matches.forEach((match, matchIndex) => {
      match.style.zIndex = 0;
    });
  }

  onOut(e) {
    e.currentTarget.classList.remove('over');
  }

  transitionIn() {
    if (this.tl) {
      this.tl.clear();
      this.tl.kill();
    }
    let delay = 0.007;
    if (isTouchDevice()) {
      delay = 0.015;
    }
    this.tl = gsap.timeline();
    this.teams.forEach((flag, i) => {
      this.tl.add(flag.intro(), i * delay);
    });
    this.events();
    return this.tl;
  }

  transitionOut() {
    this.teams.forEach((flag, i) => {
      flag.reset();
    });

    this.matches.forEach((m) => {
      m.removeEventListener('mouseenter', this.onOver);
      m.removeEventListener('mouseleave', this.onOut);
      m.removeEventListener('click', this.onClick);
    });

    if (this.tl) {
      this.tl.clear();
      this.tl.kill();
    }
  }

  resize() {
    if (!this.el) return;
    
    if (window.innerWidth < breakpoints.desktop) {
      let width = window.innerWidth / this.el.offsetWidth;
      let height = window.innerHeight / this.el.offsetHeight;
      if (width < 1.2 || height < 1.2) {
        this.el.style.transform = 'initial';
        let margin = 50 / this.el.offsetWidth;

        gsap.set(this.el, { scaleX: width - margin, scaleY: height * 0.65 });
      }
    } else {
      gsap.set(this.el, { scaleY: 1, scaleX: 1 });
    }
  }
}
