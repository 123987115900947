import EventEmitter from 'events';
import parseHTML from '../../../lib/utils/parseHTML';
import autoBind from 'auto-bind';
import './arrow-button.scss';
import gsap from 'gsap';
import ways from 'ways';
import tmpl from './arrow-button.pug';

export default class ArrowButton {
  el = undefined;
  toggle = false;
  constructor() {
    autoBind(this);
    this.events = new EventEmitter();
  }
  intro() {
    this.tl = gsap.timeline();
    this.el.style.opacity = 1;
    this.tl.set(this.line, { y: '-100%' }, 0);
    this.tl.set(this.arrow, { y: -60 }, 0);
    this.tl.to(this.line, { y: '0%', duration: 0.2, ease: 'expo.out' }, 0);
    this.tl.to(this.arrow, { y: 0, duration: 0.2, ease: 'expo.out' }, 0);
    this.tl.to(this.arrow, { opacity: 1, duration: 0.05, ease: 'expo.out' }, 0.15);
    this.tl.to(this.line, { y: '100%', duration: 0.25, ease: 'expo.in' }, 0.15);
  }

  outro() {
    gsap.to(this.el, { opacity: 0, duration: 0.1, ease: 'expo.out' });
  }

  render() {
    this.el = parseHTML(tmpl());
    this.addEvents();
    this.line = this.el.querySelector('.line span');
    this.arrow = this.el.querySelector('.icon');
    return this.el;
  }

  addEvents() {
    ways.on('url:change', () => {
      this.toggle = false;
      this.el.classList.remove('fill');
    });
    this.el.addEventListener('click', this.onClick);
  }

  onClick() {
    if (this.toggle) {
      this.toggle = false;
      this.el.classList.remove('fill');
    } else {
      this.el.classList.add('fill');
      this.toggle = true;
    }
    this.events.emit('click');
  }
}
