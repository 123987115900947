import * as THREE from 'three';
import { gsap } from 'gsap';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import { emitter, events } from '../../../../lib/utils/event.utils';
import { getGUI, addCustomFolder, collapseOthers, FOLDER_PATH } from '../../../../lib/utils/gui.utils';
import { clamp, nrange } from '../../../../lib/utils/math.utils';

import GLApp from '../../../../webgl/GLApp';

import Anchor from './Anchor';
import SubPath from './SubPath';

import Style from '../styles/Style';

import Badge from '../styles/Badge';
import Candy from '../styles/Candy';
import Dots from '../styles/Dots';
import Poly from '../styles/Poly';
import Rhombus from '../styles/Rhombus';
import Ribbon from '../styles/Ribbon';
import Ring from '../styles/Ring';
import Spine from '../styles/Spine';
import Twist from '../styles/Twist';
import Wire from '../styles/Wire';

import { BASE_DURATION } from '../styles/Style';

export default class Path {
	
	constructor(paths, index) {
		this.paths = paths;
		this.index = index;

		this.arcSegments = 100;

		this.vec = new THREE.Vector3();
		this.res = new THREE.Vector2();
		this.object3D = new THREE.Object3D();

		this.styles = [];
		this.anchors = [];
		this.positions = [];

		this.colors = [
			new THREE.Color(0xFF7728), // orange
			new THREE.Color(0xFFFA00), // yellow
			new THREE.Color(0x886CE4), // purple
		];

		this.emojis = [
			'🟧',
			'🟨',
			'🟪',
		]

		this.color = this.colors[this.index % this.colors.length];
		this.emoji = this.emojis[this.index % this.emojis.length];

		this.selected = false;

		// store initial resolution
		GLApp.renderer.getSize(this.res);

		this.initStyles();
		this.initGUI();
		this.addListeners();
	}

	initStyles() {
		this.styleOptions = [
			{ text: '', 						value: '' },
			{ text: 'Badge', 				value: 'Badge', 			constrctr: Badge 	},
			// { text: 'Candy', 				value: 'Candy', 			constrctr: Candy 	},
			{ text: 'Dots', 				value: 'Dots', 				constrctr: Dots 	},
			{ text: 'Poly', 				value: 'Poly', 				constrctr: Poly 	},
			{ text: 'Rhombus', 			value: 'Rhombus', 		constrctr: Rhombus },
			{ text: 'Ribbon', 			value: 'Ribbon', 			constrctr: Ribbon },
			{ text: 'Ring', 				value: 'Ring', 				constrctr: Ring 	},
			{ text: 'Spine', 				value: 'Spine', 			constrctr: Spine },
			{ text: 'Twist', 				value: 'Twist', 			constrctr: Twist },
			{ text: 'Wire', 				value: 'Wire', 				constrctr: Wire 	},
		];
	}

	addListeners() {
		this.handlerEditModeChange = this.onEditModeChange.bind(this);
		emitter.addListener(events.EDIT_MODE_CHANGE, this.handlerEditModeChange);
	}

	removeListeners() {
		emitter.removeListener(events.EDIT_MODE_CHANGE, this.handlerEditModeChange);
	}

	addAnchor(pos) {
		const anchor = new Anchor(this, this.anchors.length, pos);
		this.anchors.push(anchor);
		this.object3D.add(anchor.object3D);
		this.positions.push(anchor.position);

		this.updateCurve();
		this.updatePositions();
		this.updateInteractive();

		this.paths.select(this.index);

		return anchor;
	}

	removeAnchor(index) {
		const anchor = this.anchors.find(a => a.index == index);
		if (!anchor) return;

		this.anchors.splice(anchor.index, 1);
		this.positions.splice(anchor.index, 1);
		this.object3D.remove(anchor.object3D);

		// reset indices
		this.anchors.forEach((a, i) => a.index = i);

		this.updateCurve();
		this.updatePositions();
		this.updateInteractive();

		this.paths.select(this.index);

		return true;
	}

	addStyle(value, data) {
		if (!this.curve) return;

		const option = this.styleOptions.find(s => s.value == value);
		const style = new option.constrctr(this, this.styles.length, data);
		this.styles.push(style);
		this.object3D.add(style.object3D);

		// when loading from data, don't expand the folder automatically
		if (!data && style.folder) style.folder.expanded = true;

		return style;
	}

	removeStyle(index) {
		const style = this.styles.find(s => s.index == index);

		this.folder.remove(style.folder);
		this.styles.splice(style.index, 1);
		this.object3D.remove(style.object3D);
		
		style.dispose();

		// reset indices
		this.styles.forEach((s, i) => {
			s.index = i;
			s.folder.title = `${s.index}. ${s.name}`;
		});

		return true;
	}

	updateCurve() {
		// remove old
		if (this.curve) this.object3D.remove(this.curve.mesh);
		this.curve = null;

		// not enough points
		if (this.positions.length < 2) return;

		const geometry = new LineGeometry();
		const material = new LineMaterial({ color: this.color, linewidth: 3 });
		const mesh = new Line2(geometry, material);

		this.curve = new THREE.CatmullRomCurve3(this.positions);
		this.curve.curveType = 'centripetal';
		this.curve.mesh = mesh;

		this.object3D.add(mesh);
		this.resize();
	}

	updatePositions() {
		if (!this.curve) return;
		if (this.curve.points.length < 2) return;

		const { vec, curve, arcSegments } = this;
		const positions = [];

		for (let i = 0; i < arcSegments; i++) {
			const t = i / (arcSegments - 1);
			curve.getPoint(t, vec);
			positions.push(vec.x, vec.y, vec.z);
		}

		curve.mesh.geometry.setPositions(positions);
		curve.mesh.computeLineDistances();

		this.styles.forEach(style => style.updatePositions());
	}

	updateInteractive() {
		// handle in the parent
		this.paths.updateInteractive();
	}

	updateColors() {
		this.styles.forEach(style => style.updateColors());
	}

	show(delay = 0, onComplete) {
    let shortest = 999;
    let duration = 0;
    let show;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({ onComplete });

		this.styles.forEach(style => {
      style.preShow();
      show = style.show();
      duration = Math.min(show.duration(), BASE_DURATION * style.speed);
      if (duration < shortest) shortest = duration;

      this.timeline.add(show, delay);
    });

    this.timeline.play(0);
    return shortest;
	}

	hide(delay = 0, onComplete) {
    let shortest = 999;
    let duration = 0;
    let hide;

    if (this.timeline) this.timeline.kill();
    this.timeline = gsap.timeline({ onComplete });

		this.styles.forEach(style => {
      hide = style.hide();
      duration = Math.min(hide.duration(), BASE_DURATION * style.speed);
      if (duration < shortest) shortest = duration;

      this.timeline.add(hide, delay);
    });

    this.timeline.play(0);
    return shortest;
	}

	showHide(showDelay = 0, hideDelay = 0, onPathComplete) {
		let shortestShow = 999;
		let shortestHide = 999;
    let countComplete = 0;

    if (this.timeline) this.timeline.kill();

    const onStyleComplete = () => {
      countComplete++;
      if (countComplete == this.styles.length) onPathComplete();
    };

		this.styles.forEach((style, index) => {
      style.preShow();
			const { showDuration, hideComplete } = style.showHide(showDelay, hideDelay, onStyleComplete);
			if (showDuration < shortestShow) shortestShow = showDuration;
			if (hideComplete < shortestHide) shortestHide = hideComplete;
		});

		return { showDuration: shortestShow, hideComplete: shortestHide };
	}

	reload(data) {
		data.anchors.forEach(a => {
			this.addAnchor(new THREE.Vector3().fromArray(a));
		});
		data.styles.forEach(s => {
			this.addStyle(s.name, s);
		});
		this.onEditModeChange(!!getGUI());
	}

	serialize() {
		return {
			anchors	: this.anchors.map(anchor => anchor.serialize()),
			styles	: this.styles.map(style => style.serialize()),
		};
	}

	dispose() {
		this.styles.forEach(style => style.dispose());
		this.folder?.dispose();
		this.object3D.clear();
		this.removeListeners();

		this.styles 		= null;
		this.anchors 		= null;
		this.positions 	= null;
		this.object3D 	= null;
		this.folder 		= null;
	}

	// ---------------------------------------------------------------------------------------------
	// UTILS
	// ---------------------------------------------------------------------------------------------

	getSubPaths(num = 3, options = {}) {
		const { path } = this;
		const { noise2D, range, style } = options;

		const vec = new THREE.Vector3();
		const slice = (range.max - range.min) / num;
		const subpaths = [];
		let ini, end, ext;
		let segment, subpath;

		// Spine: always use ini:0 end:1
		if (style == 'Spine') {
			for (let i = 0; i < num; i++) {
				ini = range.min;
				end = range.max;
				subpath = new SubPath(this, { ini, end }, i, options);
				subpaths.push(subpath);
			}
			return subpaths;
		}

		// Dots: randomly overlapping segments
		// split a range [0,1] into num slices
		// extend ini and end of each segment to overlap
		for (let i = 0; i < num; i++) {
			ini = (i + 0) * slice + range.min;
			end = (i + 1) * slice + range.min;
			// extend ini
			ext = nrange(slice * 1.0, slice * 2.0, noise2D, (i + 0) * 100);
			ini = clamp(ini - ext, range.min, range.max);
			// extend end
			ext = nrange(slice * 0.5, slice * 2.0, noise2D, (i + 1) * 50);
			end = clamp(end + ext, range.min, range.max);

			segment = { ini, end };

			subpath = new SubPath(this, segment, i, options);
			subpaths.push(subpath);
		}

		return subpaths;
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	resize(vw, vh) {
		if (vw && vh) this.res.set(vw, vh);
		if (this.curve) this.curve.mesh.material.resolution.copy(this.res);
	}

	onEditModeChange(value) {
		this.curve.mesh.visible = value;
		this.anchors.forEach(anchor => {
			anchor.object3D.visible = value;
		});
		this.styles.forEach(style => style.onEditModeChange(value));
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
    if (!getGUI()) return;
		if (!parent) parent = getGUI().folderPaths;

		const onAddStyle = (e) => {
			if (!e.value) return;
			e.target.value = '';

			this.addStyle(e.value);
		};

		const onRemoveClick = () => { this.paths.removePath(this.index); };
		
		const onVisibleClick = (value) => { this.object3D.visible = value; };

		const onFold = (e) => { collapseOthers(parent, e.target, e.expanded); }

		const { index, emoji } = this;
		const folder = addCustomFolder(parent, { title: `${emoji} Path`, type: FOLDER_PATH, onRemoveClick, onVisibleClick });
		folder.on('fold', onFold);

		folder.addBlade({
			view: 'list',
			label: '+ STYLE',
			options: this.styleOptions,
			value: this.styleOptions[0].value,
		}).on('change', onAddStyle);

		this.folder = folder;
	}
}
