import * as THREE from 'three';
import { gsap } from 'gsap';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import { getHalfCircleGeometry } from '../../../lib/utils/geometry.utils';

import GLApp from '../../../webgl/GLApp';
import { SPHERE_RADIUS, EXTRA_RADIUS } from './Menu';

import MenuDot from './MenuDot';

export default class MenuExtra {

  constructor(menu) {
    this.menu = menu;
    this.interactive = menu.interactive;

    this.vecA = new THREE.Vector3();

    this.radius = EXTRA_RADIUS;
    this.object3D = new THREE.Object3D();

    this.colorShow = new THREE.Color(0x333333);
    this.colorOut = new THREE.Color(0x737143);
    this.colorOver = new THREE.Color(0xFFFFFF);

    this.timeline = gsap.timeline({ autoRemoveChildren: true, onUpdate: () => this.updatePositions() });
    this.tween = { rot: 0, phi: 0, u: 0 };

    this.res = new THREE.Vector2();
    GLApp.renderer.getSize(this.res);

    this.dots = [];

    this.initMaterial();
    // this.initOutline();
    this.resize();
  }

  initMaterial() {
    const { colorShow } = this;
    const material = new LineMaterial({ color: colorShow, linewidth: 1.5 });

    this.outlineMaterial = material;
  }

  initOutline() {
    const { radius, menu, colorShow, outlineMaterial, ringN, ringS } = this;
    const { phi, rot } = this.tween;

    // remove old
    if (ringN) ringN.parent.remove(ringN);
    if (ringS) ringS.parent.remove(ringS);
    this.ringN = null;
    this.ringS = null;

    // skip if no angle yet
    if (!phi) return;

    const material = outlineMaterial;

    const geoHalf = getHalfCircleGeometry(radius, phi);

    const geometryN = new LineGeometry();
    const geometryS = new LineGeometry();

    geometryN.setPositions(geoHalf.attributes.position.array);
    geoHalf.rotateZ(Math.PI);
    geometryS.setPositions(geoHalf.attributes.position.array);

    const meshN = new Line2(geometryN, material);
    const meshS = new Line2(geometryS, material);

    // scale up slightly
    // const scale = 1.005;
    // meshN.scale.set(scale, scale, scale);
    // meshS.scale.set(scale, scale, scale);

    // rotate while showing
    meshN.rotation.z = rot;
    meshS.rotation.z = rot;

    // append directly to parent to avoid rotating with sphere
    menu.scene.add(meshN);
    menu.scene.add(meshS);
    // this.object3D.add(meshN);
    // this.object3D.add(meshS);

    this.ringN = meshN;
    this.ringS = meshS;
  }

  updatePositions() {
    this.initOutline();

    const { dots, tween } = this;
    const { vecA, peak } = this;
    const radius = EXTRA_RADIUS;
    let t, phi, half, signHalf, goal;

    dots.forEach(dot => {
      goal = dot.goal;

      t = goal.v * tween.u;
      half = goal.half;
      signHalf = half == 1 ? 1 : -1;

      // front dot
      phi = (1 - t) * Math.PI;
      vecA.x = Math.cos(phi * signHalf) * radius;
      vecA.y = Math.sin(phi * signHalf) * radius;
      vecA.z = 0;

      dot.object3D.position.copy(vecA);
    });
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  initData(matchDO) {
    // remove old
    this.object3D.clear();
    this.dots.forEach(dot => dot.dispose());
    this.dots.length = 0;

    // init new
    matchDO.goals.forEach(goal => {
      if (!goal.extra) return;

      const dot = new MenuDot({ extra: this, goal });
      this.dots.push(dot);
      // this.object3D.add(dot.object3D);
      this.menu.scene.add(dot.object3D);
    });

    this.matchDO = matchDO;
  }

  show() {
    if (!this.matchDO.hasExtra) return;

    const { timeline, tween } = this;

    timeline.clear();
    timeline.set(tween, { rot: Math.PI * 0.5, phi: 0, u: 0 }, 0);
    // timeline.call(this.activate, [ this ], 0.5);
    timeline.to(tween, { rot: Math.PI * -1.0, phi: Math.PI, duration: 1.5, ease: 'expo.inOut' }, 0.0);
    timeline.to(tween, { u: 1, duration: 1.5, ease: 'expo.out' }, 0.5);
    timeline.play(0);
  }

  hide() {
    const { timeline, tween } = this;

    timeline.clear();
    // timeline.call(this.activate, [ this ], 0.5);
    timeline.to(tween, { rot: Math.PI * -1.2, phi: 0, duration: 1.0, ease: 'quart.in' }, 0.0);
    timeline.play(0);

    // remove dots immediately
    this.dots.forEach(dot => dot.dispose());
    this.dots.length = 0;
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize() {
    GLApp.renderer.getSize(this.res);
    if (this.outlineMaterial)  this.outlineMaterial.resolution.copy(this.res);
  }
}
