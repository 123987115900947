import EventEmitter from 'events';
import parseHTML from '../../lib/utils/parseHTML';
import autoBind from 'auto-bind';
import gsap from 'gsap';
import './logo.scss';
import isTouchDevice from '../../lib/utils/isTouchDevice.js';
import gsap from 'gsap';
import tmpl from './logo.pug';

export default class Logo {
  el = undefined;
  closed = false;
  constructor() {
    autoBind(this);
    this.events = new EventEmitter();
  }

  render() {
    this.el = parseHTML(tmpl());
    this.square = this.el.querySelector('.square');
    this.type = this.el.querySelector('svg');
    this.addEvents();
    return this.el;
  }

  addEvents() {
    this.el.addEventListener('click', this.onClick);
    if (isTouchDevice()) return;
    this.el.addEventListener('mouseenter', this.onEnter);
    this.el.addEventListener('mouseleave', this.onLeave);
  }

  onEnter() {
    this.overTL = gsap.timeline({});
    this.overTL.set(this.square, {
      x: '0%',
      scaleX: 0,
      transformOrigin: 'top left',
    });
    this.overTL.to(this.square, {
      scaleX: 5,
      duration: 0.15,
      ease: 'expo.out',
      transformOrigin: 'top left',
    });
    // this.overTL.set(this.square, {
    //   x: '400%',
    //   duration: 0,
    //   ease: 'expo.out',
    //   transformOrigin: 'top right',
    // });
    // this.overTL.set(this.type, { opacity: 1 });
    // this.overTL.to(this.square, {
    //   scaleX: 1,
    //   duration: 0.15,
    //   ease: 'expo.out',
    //   transformOrigin: 'top right',
    // });
  }

  onLeave() {
    if (this.overTL) {
      this.overTL.clear();
      this.overTL.kill();
    }
    gsap.to(this.square, {
      scaleX: 0,
      duration: 0.15,
      ease: 'expo.out',
      transformOrigin: 'top left',
    });
  }

  onClick() {
    this.events.emit('click');
  }

  setClosed(isClosed) {
    if (isClosed) {
      this.el.classList.add('close');
    } else {
      this.el.classList.remove('close');
    }
    this.closed = isClosed;
  }
}
