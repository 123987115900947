import { initMatches } from '../lib/utils/match.utils';
import { initTeams, getTeams } from '../lib/utils/team.utils';
import ARG from '../assets/flags/ARG.svg';
import AUS from '../assets/flags/AUS.svg';
import BEL from '../assets/flags/BEL.svg';
import BRA from '../assets/flags/BRA.svg';
import CAN from '../assets/flags/CAN.svg';
import CMR from '../assets/flags/CMR.svg';
import CRC from '../assets/flags/CRC.svg';
import CRO from '../assets/flags/CRO.svg';
import DEN from '../assets/flags/DEN.svg';
import ECU from '../assets/flags/ECU.svg';
import ENG from '../assets/flags/ENG.svg';
import ESP from '../assets/flags/ESP.svg';
import FRA from '../assets/flags/FRA.svg';
import GER from '../assets/flags/GER.svg';
import GHA from '../assets/flags/GHA.svg';
import IRN from '../assets/flags/IRN.svg';
import JPN from '../assets/flags/JPN.svg';
import KOR from '../assets/flags/KOR.svg';
import KSA from '../assets/flags/KSA.svg';
import MEX from '../assets/flags/MEX.svg';
import MOR from '../assets/flags/MOR.svg';
import NED from '../assets/flags/NED.svg';
import POL from '../assets/flags/POL.svg';
import POR from '../assets/flags/POR.svg';
import QAT from '../assets/flags/QAT.svg';
import SEN from '../assets/flags/SEN.svg';
import SRB from '../assets/flags/SRB.svg';
import SUI from '../assets/flags/SUI.svg';
import TUN from '../assets/flags/TUN.svg';
import URU from '../assets/flags/URU.svg';
import USA from '../assets/flags/USA.svg';
import WAL from '../assets/flags/WAL.svg';

export let flags = {
  ARG,
  AUS,
  BEL,
  BRA,
  CAN,
  CMR,
  CRC,
  CRO,
  DEN,
  ECU,
  ENG,
  ESP,
  FRA,
  GER,
  GHA,
  IRN,
  JPN,
  KOR,
  KSA,
  MEX,
  MOR,
  NED,
  POL,
  POR,
  QAT,
  SEN,
  SRB,
  SUI,
  TUN,
  URU,
  USA,
  WAL,
};

let totalImages = 0;

class Loader {
  loadTeams() {
    return fetch('/data/teams.json')
      .then((response) => response.json())
      .then((data) => {
        initTeams(data);
      });
  }
  loadMatches() {
    return fetch('/data/matches.json')
      .then((response) => response.json())
      .then((data) => {
        return initMatches(data);
      });
  }

  loadFlags() {
    function loaded(resolve) {
      totalImages++;
      if (totalImages >= 32) {
        resolve();
      }
    }

    return new Promise((resolve) => {
      for (const [key, value] of Object.entries(flags)) {
        fetch(value).then(() => {
          loaded(resolve);
        });
      }
    });
  }

  load() {
    return this.loadTeams()
      .then(() => {
        return this.loadMatches();
      })
      .then(() => {
        return this.loadFlags();
      });
  }
}

export default new Loader();
