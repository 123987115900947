import * as THREE from 'three';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import { getHalfCircleGeometry } from '../../../lib/utils/geometry.utils';

import GLApp from '../../../webgl/GLApp';
import { NORTH, SOUTH, SPHERE_RADIUS, ROTATION_Y } from './Menu';

export default class MenuSphere {

  constructor(menu) {
    this.menu = menu;
    this.interactive = menu.interactive;

    this.radius = SPHERE_RADIUS;
    this.object3D = new THREE.Object3D();

    this.res = new THREE.Vector2();
    GLApp.renderer.getSize(this.res);

    this.colorShow = new THREE.Color(0x666666);
    this.colorHide = new THREE.Color(0xCCCCCC);
    this.colorBack = new THREE.Color(0x333333);

    this.initSphere();
    this.initHitArea();
    this.initOutline();
    this.initEquator();
    this.resize();
  }

  initSphere() {
    const { radius } = this;

    // invisible sphere intersecting with plane
    const geometry = new THREE.SphereGeometry(radius, 64, 32);
    const material = new THREE.MeshBasicMaterial({ color: 0xFF0000, wireframe: true });
    const mesh = new THREE.Mesh(geometry, material);
    // this.object3D.add(mesh);
    this.sphere = mesh;
  }

  initOutline() {
    const { radius, menu, colorShow } = this;

    const material = new LineMaterial({ color: colorShow, linewidth: 1.5 });

    const geoHalf = getHalfCircleGeometry(radius);

    const geometryN = new LineGeometry();
    const geometryS = new LineGeometry();

    geometryN.setPositions(geoHalf.attributes.position.array);
    geoHalf.rotateZ(Math.PI);
    geometryS.setPositions(geoHalf.attributes.position.array);

    const meshN = new Line2(geometryN, material);
    const meshS = new Line2(geometryS, material);

    // scale up slightly
    const scale = 1.005;
    meshN.scale.set(scale, scale, scale);
    meshS.scale.set(scale, scale, scale);

    // append directly to parent to avoid rotating with sphere
    menu.scene.add(meshN);
    menu.scene.add(meshS);

    this.rimN = meshN;
    this.rimS = meshS;

    this.outlineMaterial = material;
  }

  initEquator() {
    const { radius, colorShow, colorBack } = this;

    const materialF = new LineMaterial({ color: colorShow, linewidth: 1.5 });
    const materialB = new LineMaterial({ color: colorBack, linewidth: 1.5 });

    const geoHalfF = getHalfCircleGeometry(radius);
    const geoHalfB = getHalfCircleGeometry(radius);

    const geometryF = new LineGeometry();
    const geometryB = new LineGeometry();

    geoHalfF.rotateX(Math.PI * 0.5);
    geoHalfF.rotateY(-ROTATION_Y);
    geometryF.setPositions(geoHalfF.attributes.position.array);

    geoHalfB.rotateX(Math.PI * -0.5);
    geoHalfB.rotateY(-ROTATION_Y);
    geometryB.setPositions(geoHalfB.attributes.position.array);

    const meshF = new Line2(geometryF, materialF);
    const meshB = new Line2(geometryB, materialB);

    this.object3D.add(meshF);
    this.object3D.add(meshB);

    this.equatorF = meshF;
    this.equatorB = meshB;

    this.equatorMaterialF = materialF;
    this.equatorMaterialB = materialB;
  }

  initHitArea() {
    const { radius } = this;

    const geometry = new THREE.SphereGeometry(radius, 16, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0x0000FF, visible: false });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.hitSphere = true;
    mesh.passThrough = true;
    this.object3D.add(mesh);

    this.hitMesh = mesh;
    this.interactive.objects.push(mesh);
  }

  update(camera) {
    this.rimN.quaternion.copy(camera.quaternion);
    this.rimS.quaternion.copy(camera.quaternion);
  }

  show() {
    this.rimS.visible = true;
    this.rimN.visible = true;
    this.equatorMaterialF.color.copy(this.colorShow);
  }

  hide() {
    this.rimS.visible = false;
    this.rimN.visible = false;
    this.equatorMaterialF.color.copy(this.colorHide);
  }

  resize() {
    GLApp.renderer.getSize(this.res);
    if (this.outlineMaterial)  this.outlineMaterial.resolution.copy(this.res);
    if (this.equatorMaterialF) this.equatorMaterialF.resolution.copy(this.res);
    if (this.equatorMaterialB) this.equatorMaterialB.resolution.copy(this.res);
  }
}
