uniform sampler2D uTexture;
uniform vec2 uTextureSize;
uniform vec3 uColor;
uniform vec4 uFactor; // x: swap, y: pixelation, z: swap, w: random
uniform vec4 uMask;
uniform float uOutline;
uniform float uThickness;

varying vec2 vUv;

// ------------------------------------
// SOBEL
// ------------------------------------

// https://www.shadertoy.com/view/Mdf3zr
float lookup(vec2 p, float dx, float dy, float thickness) {
	float d = thickness * 0.03;
	vec2 uv = (p.xy + vec2(dx * d, dy * d));
	vec4 c = texture(uTexture, uv.xy);
	// return as luma
	return 0.2126 * c.r + 0.7152 * c.g + 0.0722 * c.b;
}

float simpleSobel(vec2 p, float thickness) {
	// simple sobel edge detection
	float gx = 0.0;
	gx += -1.0 * lookup(p, -1.0, -1.0, thickness);
	gx += -2.0 * lookup(p, -1.0,  0.0, thickness);
	gx += -1.0 * lookup(p, -1.0,  1.0, thickness);
	gx +=  1.0 * lookup(p,  1.0, -1.0, thickness);
	gx +=  2.0 * lookup(p,  1.0,  0.0, thickness);
	gx +=  1.0 * lookup(p,  1.0,  1.0, thickness);

	float gy = 0.0;
	gy += -1.0 * lookup(p, -1.0, -1.0, thickness);
	gy += -2.0 * lookup(p,  0.0, -1.0, thickness);
	gy += -1.0 * lookup(p,  1.0, -1.0, thickness);
	gy +=  1.0 * lookup(p, -1.0,  1.0, thickness);
	gy +=  2.0 * lookup(p,  0.0,  1.0, thickness);
	gy +=  1.0 * lookup(p,  1.0,  1.0, thickness);

	float g = gx*gx + gy*gy;

	return g;
}

// ------------------------------------
// GLITCH / SWAP
// ------------------------------------

// https://www.shadertoy.com/view/XtyXzW
float rand(vec2 co){
	return fract(sin(dot(co.xy, vec2(12.9898, 78.233 + uFactor.w))) * 43758.5453);
}

vec2 glitchCoord(vec2 p, vec2 gridSize) {
	vec2 coord = floor(p / gridSize) * gridSize;;
	coord += (gridSize / 2.0);
	return coord;
}

vec4 swapCoords(vec2 seed, vec2 groupSize, vec2 subGrid, vec2 blockSize) {
	vec2 rand2 = vec2(rand(seed), rand(seed+.1));
	vec2 range = subGrid - (blockSize - 1.);
	vec2 coord = floor(rand2 * range) / subGrid;
	vec2 bottomLeft = coord * groupSize;
	vec2 realBlockSize = (groupSize / subGrid) * blockSize;
	vec2 topRight = bottomLeft + realBlockSize;
	topRight -= groupSize / 2.;
	bottomLeft -= groupSize / 2.;
	return vec4(bottomLeft, topRight);
}

float isInBlock(vec2 pos, vec4 block) {
	vec2 a = sign(pos - block.xy);
	vec2 b = sign(block.zw - pos);
	return min(sign(a.x + a.y + b.x + b.y - 3.), 0.);
}

vec2 moveDiff(vec2 pos, vec4 swapA, vec4 swapB) {
	vec2 diff = swapB.xy - swapA.xy;
	return diff * isInBlock(pos, swapA);
}

void swapBlocks(inout vec2 xy, float factor) {
	vec2 groupSize = vec2(0.5);
	vec2 subGrid = vec2(2.0);
	vec2 blockSize = vec2(1.0);
	vec2 seed = vec2(factor);

	vec2 groupOffset = glitchCoord(xy, groupSize);
	vec2 pos = xy - groupOffset;

	vec2 seedA = seed * groupOffset;
	vec2 seedB = seed * (groupOffset + .1);

	vec4 swapA = swapCoords(seedA, groupSize, subGrid, blockSize);
	vec4 swapB = swapCoords(seedB, groupSize, subGrid, blockSize);

	vec2 newPos = pos;
	newPos += moveDiff(pos, swapA, swapB) * 1.0;
	newPos += moveDiff(pos, swapB, swapA) * 1.0;
	pos = newPos;

	xy = pos + groupOffset;
}


void main() {
	vec2 uv = vUv;
	vec4 color = vec4(0.0);
	
	vec2 uva = uv;
	float g = 0.0;

	// texture
	vec4 colTex = texture2D(uTexture, uv);

	// sobel outline
	g = clamp(simpleSobel(uv, uThickness), 0.0, 1.0);
	vec4 colSob = vec4(uColor * g, colTex.a * g);

	// glitch swap + sobel
	swapBlocks(uva, uFactor.z);
	g = clamp(simpleSobel(uva, 0.3), 0.0, 1.0);
	vec4 colSwp = vec4(uColor * g, colTex.a * g);
	// vec4 colSwp = texture2D(uTexture, uva);

	// pixelate + swap + sobel
	vec2 pix = vec2(uFactor.y, 0.02);
	uva = (uv - 0.5) / pix;
	uva -= fract(uva) - vec2(0.5);
	uva = uva * pix + 0.5;

	swapBlocks(uva, uFactor.x);
	g = clamp(simpleSobel(uva, 0.1), 0.0, 1.0);
	vec4 colPix = vec4(uColor * g, g);
	// vec4 colPix = texture2D(uTexture, uva);

	// large pixel masks
	pix = vec2(0.25, 0.25 * (uTextureSize.x / uTextureSize.y));
	uva = (uv - 0.5) / pix;
	uva -= fract(uva) - vec2(0.5);
	uva = uva * pix + 0.5;

	vec4 colMsk = vec4(0.0);
	colMsk.r = step(rand(uva + 0.0) + 0.01, uMask.r);
	colMsk.g = step(rand(uva + 2.0) + 0.01, uMask.g);
	colMsk.b = step(rand(uva + 3.0) + 0.01, uMask.b);

	// final colour
	vec4 colOut = mix(colTex, colSob, uOutline);

	// animated masks
	color = mix(color, colPix, colMsk.r);
	color = mix(color, colSwp, colMsk.g);
	color = mix(color, colOut, colMsk.b);

	gl_FragColor = color;
}
