import * as THREE from 'three';
import { gsap } from 'gsap';
import glslify from 'glslify';

import Style from './Style';

import { BASE_DURATION } from './Style';
import { getGoal } from '../../../../lib/utils/match.utils';

export default class Rhombus extends Style {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Rhombus';

		this.settings 	= {
			colorIdx			: 0,
			width 				: 8,
			height 				: 24,
			count 				: 60,
			...this.settings
		};

		this.vec 	= new THREE.Vector3();
		this.fwd 	= new THREE.Vector3();
		this.up 	= new THREE.Vector3(0, 1, 0);
		this.sid 	= new THREE.Vector3(1, 0, 0);
		this.dummy = new THREE.Object3D();

		this.tweens = [];

		this.initData(data);
		this.initMesh();
		this.initGUI();
		this.updatePositions();
		this.updateColors();
	}

	initMesh() {
		this.settings.count = Math.ceil(this.path.curve.getLength() / this.settings.width);
		const { count, width, height } = this.settings;

		const shape = new THREE.Shape();
		shape.moveTo(width * -0.5, 0);
		shape.lineTo(0, height * 0.5);
		shape.lineTo(width *  0.5, 0);
		shape.lineTo(0, height * -0.5);

		const geometry = new THREE.ShapeGeometry(shape);
		
		const material = new THREE.MeshBasicMaterial();
		material.side = THREE.DoubleSide;
		
		const mesh = new THREE.InstancedMesh(geometry, material, count);

		this.object3D.add(mesh);
		this.mesh = mesh;

		// store tween properties
		for (let i = 0; i < count; i++) {
			this.tweens.push({ scl: 1 });
		}
	}

	updatePositions() {
		const { path, mesh, noise2D, vec, fwd, up, sid, dummy, tweens } = this;
		const { count } = this.settings;

		let t, scale, tween;

		if (!mesh) return;

		for (let i = 0; i < count; i++) {
			t = i / count;
			tween = tweens[i];

			path.curve.getPointAt(t, vec);
			path.curve.getTangentAt(t, fwd);

			scale = 1 * tween.scl;
			// scale = 1;

			dummy.position.copy(vec);
			dummy.scale.set(scale, scale, scale);

			dummy.lookAt(fwd.add(vec));
			dummy.rotateOnAxis(up, Math.PI * 0.5);
			// dummy.rotateOnAxis(sid, Math.PI * 0.5);

			dummy.updateMatrix();

			mesh.setMatrixAt(i, dummy.matrix);
		}

		mesh.instanceMatrix.needsUpdate = true;
	}

	updateColors(skipUpdateGUI) {
		const { count, colorIdx } = this.settings;
		const { mesh } = this;

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];
		const lng 	= kit.colors.length;
		const hex 	= kit.colors[colorIdx % lng];
		const color = new THREE.Color().setStyle(hex);

		for (let i = 0; i < count; i++) {
			mesh.setColorAt(i, color);
		}

		mesh.instanceColor.needsUpdate = true;

		if (!skipUpdateGUI) this.updateGUIColorRadio();
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	preShow() {
		// hide all on start
		this.tweens.forEach((tween, i) => { tween.scl = 0; });
		this.updatePositions();
	}

	show() {
		const { tweens, index, speed } = this;
		const { count } = this.settings;
		let t, duration, delay;

		const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const delayStep = BASE_DURATION * speed / count;

		timeline.clear();
		tweens.forEach((tween, i) => {
			t = i / tweens.length;
			delay = i * delayStep;

			timeline.set(tween, { scl: 0 }, 0);
			timeline.to(tween, { scl: 1, duration: 0.5, ease: 'expo.out' }, delay);
		});

		this.timelineShow = timeline;

		return timeline;
	}

	hide() {
		const { tweens, index, speed } = this;
		const { count } = this.settings;
		let t, duration, delay;

		const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const delayStep = BASE_DURATION * speed / count;

		timeline.clear();
		tweens.forEach((tween, i) => {
			t = i / tweens.length;
			delay = i * delayStep;

			timeline.to(tween, { scl: 0, duration: 0.2, ease: 'expo.in' }, delay);
		});

		this.timelineHide = timeline;

		return timeline;
	}

	dispose() {
		this.timelineShow?.kill();
		this.timelineHide?.kill();

		super.dispose();
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

    if (!folder) return;

		// remove default children
		while (folder.children.length) folder.remove(folder.children[0]);

		// folder.addInput(settings, 'count', 				{ min: 1, max: 200, step: 1 }).on('change', this.onCountChange.bind(this));
		// folder.addInput(settings, 'radius', 			{ min: 1, max: 100, step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'width', 					{ min: 1, max: 24, step: 1 }).on('change', this.onCountChange.bind(this));
		folder.addInput(settings, 'height', 				{ min: 1, max: 48, step: 1 }).on('change', this.onCountChange.bind(this));

		return folder;
	}

	onCountChange() {
		this.mesh.geometry.dispose();
		this.mesh.material.dispose();
		this.object3D.clear();

		this.initMesh();
		this.updatePositions();
		this.updateColors();
	}
}
