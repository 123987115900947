import * as THREE from 'three';
import glslify from 'glslify';
import { gsap } from 'gsap';
import chroma from 'chroma-js';

import Style from './Style';

import { BASE_DURATION } from './Style';
import { getGoal } from '../../../../lib/utils/match.utils';
import { parabola, gain, npick, nweighted, clamp, map } from '../../../../lib/utils/math.utils';
import { getPolygonShape } from '../../../../lib/utils/shape.utils';

export default class Poly extends Style {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Poly';

		this.settings 	= {
			radius 				: 25,
			sides 				: 4,
			innerRadius 	: 0.9,
			count 				: 60,
			colorIdx			: 0,
			gradient 			: true,
			...this.settings
		};

		this.vec = new THREE.Vector3();
		this.prv = new THREE.Vector3();
		this.nxt = new THREE.Vector3();
		this.up  = new THREE.Vector3(0, 1, 0);

		this.dummy = new THREE.Object3D();

		this.tweens = [];

		this.initData(data);
		this.initMesh();
		this.initGUI();
		this.updatePositions();
		this.updateColors();
	}

	initMesh() {
		const { count, sides, innerRadius } = this.settings;
		const outerRadius = 1.0;

		const shape = getPolygonShape(sides, outerRadius, innerRadius);
		const geometry = new THREE.ExtrudeGeometry(shape, { depth: 0.02, bevelEnabled: false });
		const material = new THREE.MeshBasicMaterial();
		const mesh = new THREE.InstancedMesh(geometry, material, count);

		mesh.visible = innerRadius < outerRadius;

		this.object3D.add(mesh);
		this.mesh = mesh;

		const fillShape = getPolygonShape(sides, innerRadius, 0.0);
		const fillGeometry = new THREE.ShapeGeometry(fillShape);
		const fillMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, side: THREE.DoubleSide });
		const fillMesh = new THREE.InstancedMesh(fillGeometry, fillMaterial, count);

		this.object3D.add(fillMesh);
		this.fillMesh = fillMesh;

		// store tween properties
		for (let i = 0; i < count; i++) {
			this.tweens.push({ scl: 1, rot: 0 });
		}
	}

	updatePositions() {
		const { path, mesh, fillMesh, noise2D, vec, prv, nxt, up, dummy, tweens } = this;
		const { count, radius, sides, innerRadius } = this.settings;

		let t, u, n, p, scale, angle;
		let deltaScale;
		let prevScale = 0;
		let maxDelta = 3.0;
		let tween;

		if (!mesh) return;

		for (let i = 0; i < count; i++) {
			t = i / count;
			p = Math.max((i - 1) / count, 0);
			n = Math.min((i + 1) / count, 1);

			path.curve.getPointAt(t, vec);
			path.curve.getPointAt(p, prv);
			path.curve.getPointAt(n, nxt);

			tween = tweens[i];
			scale = radius;

			// avoid abrupt variations in scale
			if (i) {
				deltaScale = scale - prevScale;
				if (deltaScale >  maxDelta) scale = prevScale + maxDelta;
				if (deltaScale < -maxDelta) scale = prevScale - maxDelta;
			}
			prevScale = scale;

			// add a bit of randomness
			scale += (noise2D(t * 1, t * t) * 0.5 + 0.5) * radius * 0.1;
			scale *= tween.scl;
			// scale *= parabola(t, 0.2);

			dummy.position.copy(vec);
			dummy.scale.set(scale, scale, scale);
			dummy.lookAt(nxt);

			dummy.rotateOnAxis(nxt.set(0, 0, 1), tween.rot);
			dummy.updateMatrix();

			mesh.setMatrixAt(i, dummy.matrix);
			fillMesh.setMatrixAt(i, dummy.matrix);
		}

		mesh.instanceMatrix.needsUpdate = true;
		fillMesh.instanceMatrix.needsUpdate = true;
	}

	updateColors(skipUpdateGUI) {
		const { count, colorIdx, gradient } = this.settings;
		const { index, mesh, noise2D } = this;

		if (!mesh) return;

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];
		const lng 	= kit.colors.length;
		const idx 	= colorIdx;
		const hexA 	= kit.colors[(idx + 0) % lng];
		const hexB 	= gradient ? kit.colors[(idx + 1) % lng] : chroma(hexA).darken(2);
		const color = new THREE.Color();

		const grad 	= chroma.scale([hexB, hexA]).colors(count);

		for (let i = 0; i < count; i++) {
			color.setStyle(grad[i]);
			mesh.setColorAt(i, color);
		}

		mesh.instanceColor.needsUpdate = true;

		if (!skipUpdateGUI) this.updateGUIColorRadio();
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	preShow() {
		// hide all on start
		this.tweens.forEach((tween, i) => { tween.scl = 0; });
		this.updatePositions();
	}

	show() {
		const { tweens, index, speed } = this;
		const { count } = this.settings;
		let t, duration, delay;

		const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const delayStep = BASE_DURATION * speed / count;

		timeline.clear();
		tweens.forEach((tween, i) => {
			t = i / tweens.length;
			delay = i * delayStep;
			duration = Math.min(BASE_DURATION + 1.0, (BASE_DURATION + 1.0) * speed + delay);

			timeline.set(tween, { scl: 0, rot: Math.PI * 1 }, 0);
			timeline.to(tween, { rot: 0, duration, ease: 'quint.out'  }, delay);
			timeline.to(tween, { scl: 1, duration: 0.2, ease: 'linear.out' }, delay);
		});

		this.timelineShow = timeline;

		return timeline;
	}

	hide() {
		const { tweens, index, speed } = this;
		const { count } = this.settings;
		let t, duration, delay, sx, sz;

		const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const delayStep = BASE_DURATION * speed / count;

		timeline.clear();
		tweens.forEach((tween, i) => {
			t = i / tweens.length;
			duration = 1.0;
			delay = i * delayStep;

			timeline.to(tween, { scl: 0, duration: 0.5, ease: 'back.in' }, delay);
		});

		this.timelineHide = timeline;

		return timeline;
	}

	dispose() {
		this.timelineShow?.kill();
		this.timelineHide?.kill();

		super.dispose();
	}


	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

    if (!folder) return;

		// remove default children
		while (folder.children.length) folder.remove(folder.children[0]);

		folder.addInput(settings, 'count', 				{ min: 1, max: 100, step: 1 }).on('change', this.onGeometryChange.bind(this));
		folder.addInput(settings, 'sides', 				{ min: 3, max: 8, 	step: 1 }).on('change', this.onGeometryChange.bind(this));
		folder.addInput(settings, 'radius', 			{ min: 1, max: 100, step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'innerRadius',	{ min: 0, max: 1  					}).on('change', this.onGeometryChange.bind(this));

		folder.addInput(settings, 'gradient').on('change', this.updateColors.bind(this));

		return folder;
	}

	onGeometryChange() {
		this.mesh.geometry.dispose();
		this.mesh.material.dispose();
		this.object3D.clear();

		this.initMesh();
		this.updatePositions();
		this.updateColors();
	}

	onSeedChange() {
		super.onSeedChange();
		this.updateColors();
	}
}
