.knockout
  .side-a
    .round-16

    .quarters

    .semis

  .final

  .winner

  .side-b

    .semis

    .quarters

    .round-16

