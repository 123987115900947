.layout

  .preload
    .preload-logo
      <svg viewBox="0 0 20 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0V30.0175H3.99393V16.27H17.7835V12.9067H3.99393V3.36331H19.7174V0H0Z" fill="white"/>
      </svg>
    .preload-square

  .page
