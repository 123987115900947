import parseHTML from '../../../lib/utils/parseHTML';
import autoBind from 'auto-bind';
import './flag.scss';
import gsap from 'gsap';
import tmpl from './flag.pug';

export default class Flag {
  constructor(data, style) {
    autoBind(this);
    this.data = data;
    this.big = style ? style.big : null;
    this.asset = style ? style.asset : null;
    this.show = style ? style.show : null;
    this.type = 'three';
    if (this.data && this.data.flag.length === 2) {
      this.type = 'two';
    }
  }

  render() {
    this.el = parseHTML(
      tmpl({
        colors: this.data ? this.data.flag : ['#272727', '#1B1B1B', '#272727'],
        type: this.type,
        show: this.show,
        asset: this.asset,
      })
    );
    if (this.big) {
      this.el.classList.add('big');
    }
    this.parts = this.el.querySelectorAll('span');
    if (this.type === 'three') {
      this.topFlag = this.parts[0];
      this.middleFlag = this.parts[1];
      this.bottomFlag = this.parts[2];
    } else {
      this.topFlag = this.parts[0];
      this.bottomFlag = this.parts[1];
    }
    return this.el;
  }

  outro() {
    this.el.classList.remove('show');
  }

  intro() {
    if (this.tl) {
      this.tl.clear();
      this.tl.kill();
    }
    this.tl = gsap.timeline();
    this.tl.add(() => {
      this.el.classList.add('show');
    });
    return this.tl;
  }
}
