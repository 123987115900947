import * as THREE from 'three';
import { gsap } from 'gsap';
import eases from 'eases';
import chroma from 'chroma-js';

import { BASE_DURATION } from './Style';
import { getGoal } from '../../../../lib/utils/match.utils';
import { npick, nrange, parabola, expImpulse, lerp } from '../../../../lib/utils/math.utils';

export default class SubSpine {
	
	constructor(parent, path, index, settings) {
		this.parent = parent;
		this.path = path;
		this.index = index;
		this.settings = settings;
		this.playhead = { ini: 0, end: 1 };
		
		this.vec = new THREE.Vector3();
		this.pos = new THREE.Vector3();
		this.mat = new THREE.Matrix4();

		this.dummy = new THREE.Object3D();
		this.object3D = new THREE.Object3D();

		this.tweens = [];

		this.initMesh();
		this.updatePositions();
		this.updateColors();
	}

	initMesh() {
		const { count, size } = this.settings;

		const geometry = new THREE.PlaneGeometry(size * 0.5, size);
		geometry.rotateX(Math.PI * 0.5);

		const material = new THREE.MeshBasicMaterial();
		material.side = THREE.DoubleSide;

		const mesh = new THREE.InstancedMesh(geometry, material, count);

		this.object3D = mesh;

		// store tween properties
		for (let i = 0; i < count; i++) {
			this.tweens.push({ sx: 1, sz: 1, rot: 1 });
		}
	}

	updatePositions() {
		const { parent, index, path, object3D, dummy, tweens, vec, pos, mat } = this;
		const { count, noise2D, twist } = this.settings;

		let t, u;
		let sx, sy, sz;
		let tween, taperEdge;
		
		const rootSubSpine = parent.subspines[0];

		for (let i = 0; i < count; i++) {
			t = i / count;
			u = parabola(t, 1);
			tween = tweens[i];

			// get point on offset curve
			path.curve.getPointAt(t, pos);
			dummy.position.copy(pos);

			// look at a point on the original curve
			path.path.curve.getPoint(t, vec);

			// look at first subplane curve			
			if (index) {
				rootSubSpine.object3D.getMatrixAt(i, mat);
				vec.setFromMatrixPosition(mat);
			}

			dummy.lookAt(vec);

			// random rotation
			vec.sub(pos).normalize();
			dummy.rotateOnAxis(vec, noise2D(i * 0.02, index) * tween.rot);

			// random scale
			sy = 1;
			sz = (noise2D(t * 2, index) * 0.5 + 0.5) + 0.5;
			sx = (noise2D(sz, t + index) * 0.5 + 0.5);

			// taper edge
			if (index % 3 == 0) taperEdge = 1.0;
			if (index % 3 == 1) taperEdge = 0.6;
			if (index % 3 == 2) taperEdge = 0.8; 

			sz *= t < 0.5 ? u : lerp(1, u, taperEdge);

			dummy.scale.set(sx, sy, sz);

			// tweened scale
			dummy.scale.x *= tween.sx;
			dummy.scale.z *= tween.sz;

			// hide the last ones so the end of the spine looks uneven
			if (index % 3 == 2 && i > count - 4) dummy.scale.x = 0;

			dummy.updateMatrix();
			object3D.setMatrixAt(i, dummy.matrix);
		}

		object3D.instanceMatrix.needsUpdate = true;
	}

	updateColors() {
		const { noise2D, count } = this.settings;
		const { index, object3D } = this;

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];
		const lng 	= kit.colors.length;
		const idx 	= index % lng;
		const hexA 	= kit.colors[idx];
		const hexB 	= chroma(hexA).darken(2);
		const color = new THREE.Color().setStyle(hexA);

		for (let i = 0; i < count; i++) {
			object3D.setColorAt(i, color);
		}

		object3D.instanceColor.needsUpdate = true;
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	show() {
		const { tweens, index, path, parent } = this;
		const { size, noise2D, count, animation } = this.settings;
		let t, duration, delay, sx, sz;

		const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const delayStep = BASE_DURATION * parent.speed / count;

		tweens.forEach((tween, i) => {
			t = i / tweens.length;
			duration = expImpulse(t, 2.5) * 0.5 + 0.2;
			delay = i * (delayStep + (1.0 - eases.quartOut(t)) * delayStep);
			delay += index * 0.45 * parent.speed;

			switch (animation) {
				case 'S':
					sx = 1;
					sz = 0;
					break;
				case 'L':
					sx = 2;
					sz = 5;
					break;
				case 'XL':
					sx = 1;
					sz = 10;
					break;
				case 'M':
				default:
					sx = 5;
					sz = 1;
					break;
			}

			timeline.set(tween, { sx: 0, sz: 0, rot: -2 }, 0);
			timeline.to(tween, { sx, 		sz,		 duration, ease: 'back.out'  }, delay);
			timeline.to(tween, { sx: 1, sz: 1, duration, ease: 'quart.out' }, delay + duration);
			
			timeline.to(tween, { rot: 2, duration: duration * 3, ease: 'back.inOut'  }, delay);
		});

		this.timelineShow = timeline;

		return timeline;
	}

	hide(immediate = false) {
		const { tweens, index, parent } = this;
		const { count } = this.settings;

		if (immediate) {
			tweens.forEach((tween, i) => { tween.sx = tween.sz = 0; });
			this.updatePositions();
			return;
		}

		const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const delayStep = BASE_DURATION * parent.speed / count;
		let t, duration, delay;

		tweens.forEach((tween, i) => {
			t = i / tweens.length;
			duration = 0.5;
			delay = i * 0.01;
			delay += index * 0.25 * parent.speed;

			timeline.to(tween, { sx: 2, duration, ease: 'back.in' }, delay);
			timeline.to(tween, { sx: 0, sz: 0, duration, ease: 'expo.in' }, delay + duration);
			timeline.to(tween, { rot: -2, duration: duration * 2, ease: 'quad.inOut'  }, delay);
		});

		this.timelineHide = timeline;

		return timeline;
	}

	dispose() {
		this.path.dispose();
		this.object3D.clear();
		this.timelineShow?.kill();
		this.timelineHide?.kill();

		this.path = null;
		this.object3D = null;
		this.timeline = null;
	}
}
