uniform float uBillboard;

varying vec2 vUv;

void main() {
	vUv = uv;

	// billboard
	vec2 scale;
	scale.x = length( vec3( modelMatrix[ 0 ].x, modelMatrix[ 0 ].y, modelMatrix[ 0 ].z ) );
	scale.y = length( vec3( modelMatrix[ 1 ].x, modelMatrix[ 1 ].y, modelMatrix[ 1 ].z ) );

	vec4 mvPosition = modelViewMatrix * vec4(0.0, 0.0, 0.0, 1.0);
	mvPosition.xy += position.xy * scale;
	vec4 bbPosition = projectionMatrix * mvPosition;

	// perspective
	vec4 ppPosition = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

	gl_Position = mix(ppPosition, bbPosition, uBillboard);
}
