import TeamDO from '../../model/TeamDO';

let _teams = [];

export const initTeams = async (data) => {
  data.forEach((item, index) => {
    _teams.push(new TeamDO({ index, ...item }));
  });
};

export const getTeams = () => {
  return _teams;
};

export const getTeam = (slugOrName) => {
  return _teams.find(team => team.slug == slugOrName || team.name == slugOrName);
};
