import * as THREE from 'three';

export const getHalfCircleGeometry = (radius, phi = Math.PI) => {
  const points = [];
  const num = 64;
  const slice = phi / (num - 1);

  for (let i = 0; i < num; i++) {
    const vec = new THREE.Vector3();
    vec.x = Math.cos(i * slice) * radius;
    vec.y = Math.sin(i * slice) * radius;
    points.push(vec);
  }

  return new THREE.BufferGeometry().setFromPoints(points);
}
