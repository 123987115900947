import * as THREE from 'three';

import autoBind from 'auto-bind';

class GLApp {
  constructor() {
    autoBind(this);
  }

  init() {
    this.initThree();
    this.addListeners();
    this.resize();
  }

  initThree() {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.domElement.classList.add('gl-app');

    this.clock = new THREE.Clock();
  }

  addListeners() {
    window.addEventListener('resize', this.resize);
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize(vw, vh) {
    if (!this.renderer) return;
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }
}

export default new GLApp();
