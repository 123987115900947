import ways from 'ways';
import autoBind from 'auto-bind';
import { debounce } from 'debounce';
import VanillaSwipe from 'vanilla-swipe';
import gsap from 'gsap';

import parseHTML from '../../lib/utils/parseHTML';
import GLApp from '../../webgl/GLApp';
import menu from './menu/Menu';
import MatchScore from '../../components/match-score/MatchScore';
import ArrowButton from '../../components/matches/arrow-button/ArrowButton';

import { getLatestMatch, getMatch, getNextMatch, getPrevMatch } from '../../lib/utils/match.utils';

import tmpl from './match.pug';
import './match.scss';

const isTouchEventsSupported = VanillaSwipe.isTouchEventsSupported();
let firstAccess = true;

export default class Match {
  scrolled = false;
  constructor() {
    autoBind(this);
  }

  render() {
    this.homeScore = new MatchScore({
      team: this.matchDO.teamDOs[0],
      score: this.matchDO.result?.regular[0] || 0,
    });
    this.visitorScore = new MatchScore({
      team: this.matchDO.teamDOs[1],
      score: this.matchDO.result?.regular[1] || 0,
      visitor: true,
    });
    this.el = parseHTML(tmpl());
    this.next = new ArrowButton();
    document.querySelector('.page').appendChild(this.el);

    this.el.querySelector('.home').appendChild(this.homeScore.render());
    this.el.querySelector('.visitor').appendChild(this.visitorScore.render());
    this.el.querySelector('.scroll').appendChild(this.next.render());

    menu.init(this);
    menu.show();
  }

  addListeners() {
    if (isTouchEventsSupported) {
      this.VS = new VanillaSwipe({
        element: window.document.body,
        directionDelta: 50,
        onSwipeStart: this.onSwipe,
        mouseTrackingEnabled: true,
      });

      this.VS.init();
    }
    this.next.events.on('click', this.onArrrowClick);
    window.addEventListener('wheel', this.onWheel);
  }

  removeListeners() {
    if (this.VS) {
      this.VS.destroy();
    }
    this.next.events.off('click', this.onArrrowClick);
    window.removeEventListener('wheel', this.onWheel);
  }

  onArrrowClick() {
    let matchDO = getNextMatch(this.matchDO.id);
    menu.setDirection(-1);
    this.scrolled = true;
    ways.go(`/match/${matchDO.id}`);
  }

  transitionIn(req, done) {
    this.scrolled = false;
    this.matchDO = getMatch(req.params.id);

    this.render();

    this.introTL = gsap.timeline({
      onComplete: () => {
        this.addListeners();
      },
    });
    this.introTL.add(this.homeScore.transitionIn());
    this.introTL.add(this.visitorScore.transitionIn(), 0);
    if (firstAccess) {
      this.introTL.add(this.next.intro);
      firstAccess = false;
    }
    done();
  }

  transitionOut(req, done) {
    this.removeListeners();
    this.next.outro();
    if (/matches/.test(ways.__nextURL)) {
      this.dispose();
      done();
    } else if (/match/.test(ways.__nextURL)) {
      menu.spin(this.direction);
      this.outroTL = gsap.timeline();
      this.outroTL.add(this.homeScore.transitionOut());
      this.outroTL.add(this.visitorScore.transitionOut(), 0);
      this.outroTL.add(() => {
        this.dispose();
        done();
      }, 1);
    } else if (/goal/.test(ways.__nextURL)) {
      this.outroTL = menu.hide();
      this.outroTL.add(() => {
        this.dispose();
        done();
      });
      // done();
    }
  }

  dispose() {
    menu.stop();
    this.el.parentNode.removeChild(this.el);
  }

  resize() {}

  onSwipe(e, swipeE) {
    if (!this.scrolled) {
      const down = swipeE.deltaY > 0;
      const currIndex = this.matchDO.id;
      const matchDO = down ? getNextMatch(currIndex) : getPrevMatch(currIndex);

      this.direction = down ? 1 : -1;

      if (matchDO) {
        menu.setDirection(this.direction);
        this.scrolled = true;
        ways.go(`/match/${matchDO.id}`);
      }
    }
  }

  onWheel(e) {
    if (!this.scrolled) {
      const down = e.wheelDelta < 0;
      const currIndex = this.matchDO.id;
      const matchDO = down ? getNextMatch(currIndex) : getPrevMatch(currIndex);

      this.direction = down ? -1 : 1;

      if (matchDO) {
        menu.setDirection(this.direction);
        this.scrolled = true;
        ways.go(`/match/${matchDO.id}`);
      }
    }
  }
}
