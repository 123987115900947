import autoBind from 'auto-bind';
import ways from 'ways';
import parseHTML from '~/src/lib/utils/parseHTML';
import glApp from '~/src/webgl/GLApp';
import loader from '../../lib/loader';
import responsiveScale from '../../lib/responsiveScale';
import ButtonToggleView from '../../components/bt-toggle-view/ButtonToggleView';
import Logo from '../../components/logo/Logo';
import { getLatestMatch } from '../../lib/utils/match.utils';
import gsap from 'gsap';

import tmpl from './layout.pug';
import './layout.scss';
let initialized = false;

class Layout {
  btToggle = undefined;
  preloadDone = false;
  preloadAnimationDone = false;
  section = 'match';
  constructor() {
    autoBind(this);
    responsiveScale.initialize({
      screenMin: 1024,
      screenMax: 1920,
      aspectRation: 1920 / 1080,
    });
    responsiveScale.resize();
  }
  render() {
    glApp.init();
    this.el = parseHTML(tmpl());
    this.btToggle = new ButtonToggleView();
    document.querySelector('#root').appendChild(this.el);
    this.el.appendChild(this.btToggle.render());
    this.el.appendChild(glApp.renderer.domElement);
    this.logo = new Logo();
    this.el.appendChild(this.logo.render());
    this.preloadLogo = this.el.querySelector('.preload-logo');
    this.preloadSquare = this.el.querySelector('.preload-square');
    this.events();
  }

  resize() {
    responsiveScale.resize();
  }

  events() {
    this.btToggle.events.on('click', this.toggleView);
    this.logo.events.on('click', this.toggleView);
    ways.on('url:change', this.onPageChange);
  }

  onPageChange(url) {
    if (/matches/.test(url)) {
      this.section = 'matches';
      this.btToggle.setClosed(true);
    } else {
      this.section = 'match';
      this.btToggle.setClosed(false);
    }
  }
  toggleView() {
    let match = getLatestMatch();
    if (this.section === 'matches') {
      ways.go(`/match/${match.id}`);
    } else {
      ways.go(`/matches`);
    }
  }

  transitionIn(req, done) {
    if (this.initialized) {
      done();
      return;
    }
    this.render();
    this.preload(done);
    this.tlPreload = gsap.timeline({ onComplete: this.chechPreload });
    this.tlPreload.fromTo(
      this.preloadSquare,
      {
        opacity: 0,
      },
      { opacity: 1, duration: 1, ease: 'expo.out' }
    );
    this.tlPreload.to(
      this.preloadLogo,
      { duration: 0, opacity: 1, duration: 1, ease: 'expo.out' },
      1
    );
    this.tlPreload.to(this.preloadLogo, { ease: 'expo.inOut', x: '-210%', duration: 1 }, 1);
    this.tlPreload.to(this.preloadSquare, { ease: 'expo.inOut', x: '160%', duration: 1 }, 1);
  }

  chechPreload() {
    this.preloadAnimationDone = true;
    if (this.preloadDone) {
      let latest = getLatestMatch();
      this.outroPreload(() => {
        this.preloadCallback();
        this.initialized = true;
        // ways.go(`/matches`);
        if (ways.pathname().length < 2) {
          ways.go(`/match/${latest.id}`);
        }
      });
    }
  }

  preload(cb) {
    loader.load().then(() => {
      let latest = getLatestMatch();
      this.preloadDone = true;
      if (this.preloadAnimationDone) {
        this.outroPreload(() => {
          cb();
          this.initialized = true;
          ways.go(`/match/${latest.id}`);
        });
      } else {
        this.preloadCallback = cb;
      }

      // ways.go(`/matches`);
    });
  }

  outroPreload(cb) {
    this.tlPreload = gsap.timeline({ onComplete: cb });
    this.tlPreload.to(this.preloadSquare, {
      ease: 'expo.inOut',
      transformOrigin: 'top right',
      scaleX: 4,
      duration: 0.25,
    });
    this.tlPreload.to(this.preloadLogo, {
      ease: 'expo.inOut',
      transformOrigin: 'top right',
      opacity: 0,
      duration: 0,
    });
    this.tlPreload.set(this.preloadSquare, {
      ease: 'expo.inOut',
      x: '-140%',
      transformOrigin: 'top left',
    });
    this.tlPreload.to(this.preloadSquare, {
      ease: 'expo.out',
      transformOrigin: 'top left',
      scaleX: 0.0,
      duration: 0.15,
    });
    this.tlPreload.to(
      this.preloadSquare,
      {
        width: 0.0,
      },
      '-=0.01'
    );
    this.el.classList.add('show');
  }

  transitionOut(req, done) {
    done();
  }
  destroy() {}
}

export default new Layout();
