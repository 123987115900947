import * as THREE from 'three';
import Path from './Path';
import { gsap } from 'gsap';

import { emitter, events } from '../../../../lib/utils/event.utils';
import { getGUI } from '../../../../lib/utils/gui.utils';

export default class Paths {
  
  constructor(webgl) {
    this.webgl = webgl;

    this.object3D = new THREE.Object3D();
    this.object3D.position.y = 5;
    
    this.paths = [];
    this.selected = null;
  }

  addPath() {
    const path = new Path(this, this.paths.length);
    this.paths.push(path);
    this.object3D.add(path.object3D);

    this.select(path.index);

    return path;
  }

  removePath(index) {
    const path = this.paths.find(p => p.index == index);
    if (!path) return;

    if (getGUI()) getGUI().folderPaths.remove(path.folder);
    this.paths.splice(path.index, 1);
    this.object3D.remove(path.object3D);

    path.dispose();

    // reset indices
    this.paths.forEach((p, i) => p.index = i);
    // select last index
    this.select(this.paths.length - 1);

    this.updateInteractive();
  }

  updateInteractive() {
    const { interactive } = this.webgl;
    if (!interactive) return;

    // clear interactive list
    interactive.objects = [];

    // push anchors to interactive
    this.paths.forEach(path => {
      path.anchors.forEach(anchor => {
        interactive.objects.push(anchor.object3D);
      });
    });
  }

  updateColors() {
    this.paths.forEach(path => path.updateColors());
  }

  show(onComplete) {
    let delay = 0;
    let countComplete = 0;

    const onPathComplete = () => {
      countComplete++;
      if (onComplete && countComplete == this.paths.length) onComplete();
    };

    this.paths.forEach(path => delay += path.show(delay, onPathComplete));
  }

  hide(onComplete) {
    let delay = 0;
    let countComplete = 0;

    const onPathComplete = () => {
      countComplete++;
      if (onComplete && countComplete == this.paths.length) onComplete();
    };

    this.paths.forEach(path => delay += path.hide(delay, onPathComplete));
  }

  showHide(onComplete) {
    let showDelay = 0;
    let hideDelay = 1;
    let countComplete = 0;

    const onPathComplete = () => {
      countComplete++;
      if (onComplete && countComplete == this.paths.length) onComplete();
    };

    this.paths.forEach(path => {
      const { showDuration, hideComplete } = path.showHide(showDelay, hideDelay, onPathComplete);
      // start showing next early
      showDelay += showDuration * 1.0;
      // start hiding next early
      hideDelay  = hideComplete * 1.0;
    });
  }

  select(index = null) {
    this.paths.forEach(path => { path.selected = path.index == index; });
    this.selected = this.paths.find(path => path.selected);

    this.updateInteractive();

    emitter.emit(events.PATH_SELECT, this.selected);
  }

  clear() {
    this.paths.forEach(path => path.dispose());
    this.paths = [];
    this.object3D.clear();
    this.select(null);
  }

  reload(goal) {
    // expand folder paths to avoid a Tweakpane bug
    if (getGUI() && getGUI().folderPaths) getGUI().folderPaths.expanded = true;

    goal.paths.forEach(data => {
      this.addPath().reload(data);
    });
    // select last index
    this.select(this.paths.length - 1);
  }

  serialize() {
    return this.paths.map(path => path.serialize());
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize(vw, vh) {
    this.paths.forEach(path => path.resize(vw, vh));
  }

  onInteractiveOver(e) {
    if (!e.object || !e.object.anchor) return;
    e.object.anchor.onInteractiveOver(e);
  }

  onInteractiveOut(e) {
    if (!e.object || !e.object.anchor) return;
    e.object.anchor.onInteractiveOut(e);
  }

  onInteractiveDown(e) {
    if (!e.object || !e.object.anchor) return;
    e.object.anchor.onInteractiveDown(e);

    this.select(e.object.anchor.path.index);
  }
}
