import * as THREE from 'three';
import { gsap } from 'gsap';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import GLApp from '../../../webgl/GLApp';
import { NORTH, SOUTH, SPHERE_RADIUS, ARC_ROTATION } from './Menu';

import MenuDot from './MenuDot';

export default class MenuArc {

  constructor(arcs, sphere, goal) {
    this.arcs   = arcs;
    this.sphere = sphere;
    this.goal   = goal;
    this.interactive = this.arcs.menu.interactive;

    this.vecA = new THREE.Vector3();
    this.vecB = new THREE.Vector3();
    this.vecC = new THREE.Vector3();
    this.vecZ = new THREE.Vector3(0, 0, 1);
    this.peak = new THREE.Vector3();

    this.plane = new THREE.Plane();
    this.lineAB = new THREE.Line3();

    this.res = new THREE.Vector2();
    GLApp.renderer.getSize(this.res);

    this.object3D = new THREE.Object3D();

    this.timeline = gsap.timeline({ autoRemoveChildren: true, onUpdate: () => this.updatePositions() });
    this.timelineOver = gsap.timeline({ autoRemoveChildren: true });
    this.tween = { u: goal.v, rot: ARC_ROTATION };

    this.colorOut = new THREE.Color(0x737143);
    this.colorOver = new THREE.Color(0xFFFFFF);

    this.hemisphere = goal.minute <= 45 ? NORTH : SOUTH;

    this.initDots();
    this.initLine();

    this.updatePositions();
    this.initHitArea();

    this.resize();
  }

  initDots() {
    this.dots = [];

    for (let i = 0; i < 2; i++) {
      const dot = new MenuDot({ arc: this, index: i });
      this.dots.push(dot);
      this.object3D.add(dot.object3D);
    }
  }

  initLine() {
    const { object3D, colorOut } = this;

    const geometry = new LineGeometry();
    const material = new LineMaterial({
      color       : colorOut,
      linewidth   : 1.5,
      dashed      : true,
      dashSize    : 2,
      gapSize     : 2,
      dashScale   : 0.8,
      transparent : true,
    });

    const line = new Line2(geometry, material);
    object3D.add(line);

    this.line = line;
  };


  initHitArea() {
    const { object3D, curve, interactive, colorOut, vecA } = this;

    // remove old
    if (this.hitArea) {
      interactive.objects.splice(interactive.objects.indexOf(this.hitArea), 1);
      object3D.remove(this.hitArea);
    }

    const arcSegments = 100;
    const positions = [];
    const shape = new THREE.Shape();

    for (let i = 0; i < arcSegments; i++) {
      const t = i / (arcSegments - 1);
      curve.getPoint(t, vecA);
      positions.push(vecA.x, vecA.y, vecA.z);

      if (!i) shape.moveTo(vecA.z, vecA.y);
      else shape.lineTo(vecA.z, vecA.y);
    }

    const geometry = new THREE.ShapeGeometry(shape);
    geometry.rotateY(Math.PI * 0.5);

    const material = new THREE.MeshBasicMaterial({ color: colorOut, side: THREE.DoubleSide });
    material.visible = false;

    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.x = positions[0];
    mesh.rotation.z = ARC_ROTATION;
    mesh.scale.y = 1.15;

    object3D.add(mesh);

    this.hitArea = mesh;
    this.hitArea.arc = this;
  }

  updatePositions() {
    const { sphere, goal, dots, object3D, tween } = this;
    const { vecA, vecB, vecC, vecZ, plane, lineAB, peak } = this;
    const radius = SPHERE_RADIUS;
    let t, phi, half, signHalf;

    const intersectionPoints = [];
    let linePoints = [];

    // when t is exactly at 1 the intersection plane
    // is parallel to the yz-axis
    // make t stop slightly before 1 to get an angled plane
    // t = goal.u * 0.999;
    t = tween.u * 0.999;

    half = goal.half;
    signHalf = half == 1 ? 1 : -1;

    // front dot
    phi = (1 - t) * Math.PI;
    vecA.x = Math.cos(phi) * radius;
    vecA.z = Math.sin(phi) * radius;
    vecA.y = 0;

    dots[0].object3D.position.copy(vecA);

    // back dot
    vecB.x = Math.cos(-phi) * radius;
    vecB.z = Math.sin(-phi) * radius;
    vecB.y = 0;

    dots[1].object3D.position.copy(vecB);

    // point to define the intersection plane
    phi = tween.rot;
    vecC.set(0, radius * -signHalf, 0);
    vecC.applyAxisAngle(vecZ, phi);
    vecC.x += vecA.x;

    // intersection plane
    plane.setFromCoplanarPoints(vecA, vecB, vecC);

    intersectionPoints.length = 0;
    linePoints.length = 0;

    // http://jsfiddle.net/kirill321592/0fLnmq7c/12/
    // https://stackoverflow.com/questions/42348495/three-js-find-all-points-where-a-mesh-intersects-a-plane
    const { index } = sphere.sphere.geometry;
    const { position } = sphere.sphere.geometry.attributes;
    let intersects;

    for (let i = 0; i < index.count; i += 3) {
      vecA.fromBufferAttribute(position, index.array[i + 0]);
      vecB.fromBufferAttribute(position, index.array[i + 1]);
      vecC.fromBufferAttribute(position, index.array[i + 2]);

      lineAB.set(vecA, vecB);

      // reuse vecC
      intersects = plane.intersectLine(lineAB, vecC);
      if (intersects) intersectionPoints.push(vecC.clone());
    }

    // clip points north/south hemispheres
    intersectionPoints.forEach(p => {
      if (half == 1 && p.y < 0) return;
      if (half == 2 && p.y > 0) return;
      linePoints.push(p);
    });

    // sort by y based on which side of z-axis
    let frntPoints = linePoints.filter(p => p.z >= 0);
    let backPoints = linePoints.filter(p => p.z < 0);

    frntPoints.sort((a, b) => (half == 2) ? b.y - a.y : a.y - b.y);
    backPoints.sort((a, b) => (half == 2) ? a.y - b.y : b.y - a.y);

    linePoints = frntPoints.concat(backPoints);

    // append first and last dots
    linePoints.splice(0, 0, dots[0].object3D.position);
    linePoints.push(dots[1].object3D.position);

    // peak-y
    peak.set(0, 0, 0);
    linePoints.forEach(p => {
      if (half == 1 && p.y > peak.y) peak.copy(p);
      if (half == 2 && p.y < peak.y) peak.copy(p);
    });

    this.updateCurve(linePoints);
  }

  updateCurve(points) {
    const { object3D, line, colorOut, vecA } = this;

    const curve = new THREE.CatmullRomCurve3(points);
    curve.curveType = 'centripetal';

    const arcSegments = 100;
    const positions = [];

    for (let i = 0; i < arcSegments; i++) {
      const t = i / (arcSegments - 1);
      curve.getPoint(t, vecA);
      positions.push(vecA.x, vecA.y, vecA.z);
    }

    line.geometry.setPositions(positions);
    line.computeLineDistances();

    this.curve = curve;
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  activate(scope = this) {
    const idx = scope.interactive.objects.indexOf(scope.hitArea);
    if (idx == -1) scope.interactive.objects.push(scope.hitArea);
  }

  deactivate() {
    const idx = this.interactive.objects.indexOf(this.hitArea);
    if (idx > -1) this.interactive.objects.splice(idx, 1);
  }

  show() {
    const { timeline, tween, goal } = this;

    const halfU = goal.half == 1 ? 0 : 1;
    const duration = 1.5;

    timeline.clear();
    timeline.set(tween, { u: halfU }, 0);
    timeline.call(this.activate, [ this ], 0.5);
    timeline.to(tween, { u: goal.v, duration, ease: 'expo.out' }, goal.index * 0.1);
    timeline.play(0);

    this.object3D.visible = true;
  }

  hide(fullHide = false) {
    this.deactivate();
    if (fullHide) this.object3D.visible = false;
  }

  toggleHemisphere(_hemisphere) {
    const { line, dots, hemisphere } = this;

    line.material.opacity = _hemisphere == hemisphere ? 1 : 0.35;
    dots.forEach(dot => dot.toggleHemisphere(_hemisphere));
  }

  dispose() {
    this.deactivate();
    this.timeline.kill();
    this.timelineOver.kill();

    this.line.geometry.dispose();
    this.line.material.dispose();
    this.object3D.clear();

    this.dots.forEach(dot => dot.dispose());

    this.line         = null;
    this.curve        = null;
    this.hitArea      = null;
    this.object3D     = null;
    this.timeline     = null;
    this.interactive  = null;
    this.dots         = null;
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  onInteractiveOver(e) {
    const { timelineOver, line, dots, colorOver, arc } = this;

    // if (e.object.arc != arc) return;
    if (!line) return;

    timelineOver.clear();
    timelineOver.to(line.material, { linewidth: 3, duration: 0.3, ease: 'quart.out' });
    timelineOver.play(0);

    line.material.color.copy(colorOver);
    line.material.dashed = false;

    dots.forEach(dot => dot.onInteractiveOver());
  }

  onInteractiveOut(e) {
    const { timelineOver, line, dots, colorOut, arc } = this;

    // if (e.object.arc != arc) return;
    if (!line) return;

    timelineOver.clear();
    timelineOver.to(line.material, { linewidth: 1.5, duration: 0.2, ease: 'quart.out' });
    timelineOver.play(0);

    line.material.color.copy(colorOut);
    line.material.dashed = true;

    dots.forEach(dot => dot.onInteractiveOut());
  }

  onInteractiveDown(e) {
    // if (e.object != this.object3D) return;
  }

  resize() {
    GLApp.renderer.getSize(this.res);
    if (this.line) this.line.material.resolution.copy(this.res);
  }
}
