import * as THREE from 'three';
import { gsap } from 'gsap';
import autoBind from 'auto-bind';
import ways from 'ways';

import GLApp from '../../../webgl/GLApp';
import InteractiveControls from '../../../components/controls/InteractiveControls';
import { breakpoints } from '../../../lib/mediaQueries';

import MenuSphere from './MenuSphere';
import MenuArcs from './MenuArcs';
import MenuLabel from './MenuLabel';
import MenuOutro from './MenuOutro';
import MenuExtra from './MenuExtra';

export const NORTH = 'north';
export const SOUTH = 'south';
export const SPHERE_RADIUS = 100;
export const EXTRA_RADIUS = 120;
export const DOT_RADIUS = 2.5;
export const ARC_ROTATION = (30 * Math.PI) / 180;
export const ROTATION_X = 0.25;
export const ROTATION_Y = -0.2;

class Menu {
  constructor() {
    autoBind(this);
    this.initiated = false;
    this.direction = -1;
  }

  init(page) {
    this.page = page;
    this.matchDO = this.page.matchDO;
    this.lastMatchID = null;

    if (this.initiated) return;
    this.initiated = true;

    this.targetRotation = new THREE.Vector2(ROTATION_X, ROTATION_Y);
    this.timeline = gsap.timeline();
    this.arcs = [];

    this.initThree();
    this.initInteractiveControls();
    this.initSphere();
    this.initArcs();
    this.initExtra();
    this.initLabel();
    this.initOutro();
  }

  initThree() {
    this.camera = new THREE.PerspectiveCamera(20, window.innerWidth / window.innerHeight, 1, 10000);
    this.camera.position.set(0, 0, 1000);

    this.scene = new THREE.Scene();

    // sub-object which rotates independently
    this.container = new THREE.Object3D();
    this.container.rotation.set(ROTATION_X, ROTATION_Y, 0);
    this.scene.add(this.container);
  }

  initSphere() {
    this.sphere = new MenuSphere(this);
    this.container.add(this.sphere.object3D);
  }

  initArcs() {
    this.arcs = new MenuArcs(this);
    this.container.add(this.arcs.object3D);
  }

  initExtra() {
    this.extra = new MenuExtra(this);
    this.container.add(this.extra.object3D);
  }

  initLabel() {
    this.label = new MenuLabel(this);
  }

  initOutro() {
    this.outro = new MenuOutro(this);
    this.scene.add(this.outro.object3D);
  }

  initInteractiveControls() {
    this.interactive = new InteractiveControls(this.camera, GLApp.renderer.domElement);
    this.interactive.addListener('interactive-over', this.onInteractiveOver);
    this.interactive.addListener('interactive-out', this.onInteractiveOut);
    this.interactive.addListener('interactive-move', this.onInteractiveMove);
    this.interactive.addListener('interactive-down', this.onInteractiveDown);
  }

  addListeners() {
    window.addEventListener('resize', this.resize);
  }

  removeListeners() {
    window.removeEventListener('resize', this.resize);
  }

  animate() {
    this.update();
    this.draw();

    this.raf = requestAnimationFrame(this.animate);
  }

  setDirection(direction) {
    this.direction = direction;
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update() {
    const { camera, container, targetRotation, freeCursor } = this;
    this.sphere.update(camera);
    this.outro.update();

    if (!freeCursor) return;

    container.rotation.x += (targetRotation.x - container.rotation.x) * 0.02;
    container.rotation.y += (targetRotation.y - container.rotation.y) * 0.02;
  }

  draw() {
    GLApp.renderer.render(this.scene, this.camera);
  }

  // prev = 1 / next = -1
  spin() {
    const { timeline, container, targetRotation } = this;

    timeline.clear();
    timeline.set(container.rotation, { x: Math.PI * 2 * this.direction + container.rotation.x }, 0);
    timeline.to(container.rotation, { x: ROTATION_X, duration: 2.0, ease: 'expo.inOut' }, 0);
    timeline.play(0);

    this.arcs.hide();
    this.label.hide();
    this.extra.hide();

    targetRotation.x = ROTATION_X;
    targetRotation.y = ROTATION_Y;
    this.freeCursor = false;

    // update data mid-spin
    setTimeout(() => { this.updateData() }, 1000);
  }

  halfSpin() {
    const { timeline, container, targetRotation } = this;

    // spin if not spinning yet
    if (!timeline.isActive()) {
      timeline.clear();
      timeline.set(container.rotation, { x: Math.PI * 1 * this.direction + container.rotation.x }, 0);
      timeline.to(container.rotation, { x: ROTATION_X, duration: 1.0, ease: 'expo.out' }, 0);
      timeline.play(0);
    }

    this.updateData();
  }

  updateData() {
    // skip if still the same match
    if (this.lastMatchID == this.matchDO.id) return;
    this.lastMatchID = this.matchDO.id;

    this.arcs.initData(this.matchDO);
    this.extra.initData(this.matchDO);
    this.arcs.show();
    this.extra.show();
    this.freeCursor = true;
  }

  show() {
    this.container.rotation.z = 0;
    this.container.visible = true;

    this.interactive.enable();
    this.outro.hide();
    this.sphere.show();
    this.label.init();
    this.halfSpin();

    this.addListeners();
    this.animate();
    this.resize();
  }

  hide() {
    const { timeline, container } = this;

    this.interactive.disable();
    this.arcs.hide(true);
    this.label.hide();
    this.sphere.hide();
    this.freeCursor = false;

    // roll and disappear
    timeline.clear();
    timeline.to(container.rotation, { z: Math.PI * -0.5, duration: 0.5, ease: 'quart.in' }, 0.2);
    timeline.set(container, { visible: false }, 0.7);
    timeline.play(0);

    return this.outro.show(0.7);
  }

  stop() {
    cancelAnimationFrame(this.raf);
    this.removeListeners();
  }


  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  onInteractiveOver(e) {
    if (!e.object) return;

    if (e.object.arc) {
      e.object.arc.onInteractiveOver(e);
      this.label.show(e.object.arc, e.object.arc.goal);
    }
    // triggered only on extra
    if (e.object.dot) {
      e.object.dot.onInteractiveOver(e);
      this.label.show(this.extra, e.object.dot.goal, e.object.dot);
    }
  }

  onInteractiveOut(e) {
    if (!e.object) return;

    if (e.object.arc) {
      e.object.arc.onInteractiveOut(e);
      this.label.hide();
    }
    // triggered only on extra
    if (e.object.dot) {
      e.object.dot.onInteractiveOut(e);
      this.label.hide();
    }
  }

  onInteractiveMove(e) {
    const { container, targetRotation } = this;

    if (!e.object || !e.object.hitSphere) return;
    const { point } = e.intersectionData;

    targetRotation.x = (point.y / SPHERE_RADIUS) * 0.05 + ROTATION_X;
    targetRotation.y = (point.x / SPHERE_RADIUS) * -0.05 + ROTATION_Y;
  }

  onInteractiveDown(e) {
    const target = e.object.arc || e.object.dot;
    if (!e.object || !target) return;
    
    const { goal } = target;

    // trigger mouse out
    this.onInteractiveOut(e);
    // start hide sequence
    ways.go(`/goal/${goal.slug}`);
  }

  resize() {
    this.camera.zoom = window.innerWidth < breakpoints.wide ? 0.75 : 1;

    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.arcs.resize();
    this.sphere.resize();
    this.interactive.resize();
    this.outro.resize();
  }
}

export default new Menu();
