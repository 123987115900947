import './index.scss';
import routes from '~/src/pages/routes';
import { CustomEase, gsap } from 'gsap/all';

window.addEventListener('DOMContentLoaded', (event) => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  // register gsap plugins once
  gsap.registerPlugin(CustomEase);

  routes();
});

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
