import * as THREE from 'three';
import glslify from 'glslify';
import { gsap } from 'gsap';
import chroma from 'chroma-js';

import { BASE_DURATION } from './Style';
import { getGoal } from '../../../../lib/utils/match.utils';
import { map } from '../../../../lib/utils/math.utils';

import Style from './Style';

export default class Twist extends Style {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Twist';

		this.settings = {
			count 			: 80,
			size 				: 60,
			twistX 			: 2.5,
			twistY 			: 2.0,
			twistZ 			: 2.0,
			colorIdx		: 0,
			gradient 		: true,
			...this.settings
		};

		// initial count proportional to path length
		this.settings.count = ~~(path.curve.getLength() / 3);

		this.vec = new THREE.Vector3();
		this.pos = new THREE.Vector3();
		this.nxt = new THREE.Vector3();
		this.mat = new THREE.Matrix4();

		this.dummy = new THREE.Object3D();
		this.object3D = new THREE.Object3D();

		this.tweens = [];

		this.initData(data);
		this.initMesh();
		this.initGUI();
		this.updatePositions();
		this.updateColors();
	}

	initMesh() {
		const { count, size } = this.settings;

		const geometry = new THREE.BoxGeometry(1, 0.5, 0.5);
		geometry.rotateX(Math.PI * 0.5);

		const material = new THREE.MeshBasicMaterial();
		material.side = THREE.DoubleSide;

		const mesh = new THREE.InstancedMesh(geometry, material, count);

		this.object3D.add(mesh);
		this.mesh = mesh;

		// store tween properties
		for (let i = 0; i < count; i++) {
			this.tweens.push({ scl: 1, tx: 1 });
		}
	}

	updatePositions() {
		const { index, path, mesh, dummy, tweens, vec, nxt, pos, mat } = this;
		const { count, noise2D, twistX, twistY, twistZ, size } = this.settings;

		let t, n, u;
		let sx, sy, sz;
		let tween, taperEdge;

		if (!mesh) return;

		for (let i = 0; i < count; i++) {
			t = i / count;
			n = Math.min((i + 1) / count, 1);
			// u = parabola(t, 1);
			tween = tweens[i];

			// get point on offset curve
			path.curve.getPointAt(t, pos);
			path.curve.getPointAt(n, nxt);

			sx = size * tween.scl;
			sy = 1 * tween.scl;
			sz = 1 * tween.scl;

			dummy.position.copy(pos);
			dummy.scale.set(sx, sy, sz);
			// dummy.lookAt(nxt);

			// dummy.rotateOnAxis(nxt.normalize(), t * Math.PI);
			dummy.rotation.set(0, 0, t * Math.PI);
			dummy.rotateOnAxis(nxt.set(0, 0, 1), t *  Math.PI * twistZ);
			dummy.rotateOnAxis(nxt.set(1, 0, 0), t * -Math.PI * twistX);
			dummy.rotateOnAxis(nxt.set(0, 1, 0), t *  Math.PI * twistY * tween.tx);

			dummy.updateMatrix();
			mesh.setMatrixAt(i, dummy.matrix);
		}

		mesh.instanceMatrix.needsUpdate = true;
	}

	updateColors(skipUpdateGUI) {
		const { count, colorIdx, gradient } = this.settings;
		const { mesh } = this;

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];
		const lng 	= kit.colors.length;
		const idx 	= colorIdx;
		const hexA 	= kit.colors[(idx + 0) % lng];
		const hexB 	= gradient ? kit.colors[(idx + 1) % lng] : chroma(hexA).darken(2);
		const color = new THREE.Color();

		const grad 	= chroma.scale([hexB, hexA]).colors(count);

		for (let i = 0; i < count; i++) {
			color.setStyle(grad[i]);
			mesh.setColorAt(i, color);
		}

		mesh.instanceColor.needsUpdate = true;

		if (!skipUpdateGUI) this.updateGUIColorRadio();
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	preShow() {
		// hide all on start
		this.tweens.forEach((tween, i) => { tween.scl = 0; });
		this.updatePositions();
	}

	show() {
		const { tweens, index, speed } = this;
		const { count } = this.settings;
		let t, duration, delay;

		const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const delayStep = BASE_DURATION * speed / count;

		timeline.clear();
		tweens.forEach((tween, i) => {
			t = i / tweens.length;
			delay = i * delayStep;

			timeline.set(tween, { scl: 0, tx: 0 }, 0);
			timeline.to(tween, { scl: 1, tx: 1, duration: 1.0, ease: 'expo.out' }, delay);
		});

		this.timelineShow = timeline;

		return timeline;
	}

	hide() {
		const { tweens, index, speed } = this;
		const { count } = this.settings;
		let t, duration, delay;

		const timeline = gsap.timeline({ onUpdate: () => this.updatePositions() });
		const delayStep = BASE_DURATION * speed / count;

		timeline.clear();
		tweens.forEach((tween, i) => {
			t = i / tweens.length;
			delay = i * delayStep;

			timeline.to(tween, { scl: 0, duration: 0.2, ease: 'expo.in' }, delay);
		});

		this.timelineHide = timeline;

		return timeline;
	}

	dispose() {
		this.timelineShow?.kill();
		this.timelineHide?.kill();

		super.dispose();
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

    if (!folder) return;

		// remove default children
		while (folder.children.length) folder.remove(folder.children[0]);

		folder.addInput(settings, 'count', 		{ min: 1, max: 400, step: 1 }).on('change', this.onCountChange.bind(this));
		folder.addInput(settings, 'size', 		{ min: 1, max: 200, step: 1 }).on('change', this.updatePositions.bind(this));

		folder.addInput(settings, 'twistX', 	{ min: 0, max: 5 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'twistY', 	{ min: 0, max: 5 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'twistZ', 	{ min: 0, max: 5 }).on('change', this.updatePositions.bind(this));
		
		folder.addInput(settings, 'gradient').on('change', this.updateColors.bind(this));
	}

	onCountChange() {
		this.mesh.geometry.dispose();
		this.mesh.material.dispose();
		this.object3D.clear();

		this.initMesh();
		this.updatePositions();
		this.updateColors();
	}
}
