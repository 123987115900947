import * as THREE from 'three';

export default class Anchor {
	
	constructor(path, index, position) {
		this.path = path;
		this.index = index;

		this.interactive = path.paths.webgl.interactive;
		this.transform = path.paths.webgl.transform;

		this.colorOver = new THREE.Color(0xFFFFFF);
		this.colorOut  = new THREE.Color(0xCCCCCC);

		this.scaleOver = new THREE.Vector3(1.5, 1.5, 1.5);
		this.scaleOut = new THREE.Vector3(1.0, 1.0, 1.0);

		this.initMesh();

		this.position.copy(position);
	}

	initMesh() {
		const geometry = new THREE.BoxGeometry(5, 5, 5);
		const material = new THREE.MeshBasicMaterial({ color: this.colorOut });
		const mesh = new THREE.Mesh(geometry, material);

		mesh.anchor = this;
		this.object3D = mesh;
	}

	serialize() {
		return this.position.toArray();
	}

	get position() { return this.object3D.position; }

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	onInteractiveOver(e) {
		if (e.object != this.object3D) return;
		this.object3D.material.color.copy(this.colorOver);
		this.object3D.scale.copy(this.scaleOver);
	}

	onInteractiveOut(e) {
		if (e.object != this.object3D) return;
		this.object3D.material.color.copy(this.colorOut);
		this.object3D.scale.copy(this.scaleOut);
	}

	onInteractiveDown(e) {
		if (e.object != this.object3D) return;
		if (this.transform.object == this.object3D) return;
		
		this.transform.attach(this.object3D);
	}
}
