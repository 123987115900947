{
    "name": "escodegen",
    "description": "ECMAScript code generator",
    "homepage": "http://github.com/estools/escodegen",
    "main": "escodegen.js",
    "bin": {
        "esgenerate": "./bin/esgenerate.js",
        "escodegen": "./bin/escodegen.js"
    },
    "files": [
        "LICENSE.BSD",
        "README.md",
        "bin",
        "escodegen.js",
        "package.json"
    ],
    "version": "1.14.3",
    "engines": {
        "node": ">=4.0"
    },
    "maintainers": [
        {
            "name": "Yusuke Suzuki",
            "email": "utatane.tea@gmail.com",
            "web": "http://github.com/Constellation"
        }
    ],
    "repository": {
        "type": "git",
        "url": "http://github.com/estools/escodegen.git"
    },
    "dependencies": {
        "estraverse": "^4.2.0",
        "esutils": "^2.0.2",
        "esprima": "^4.0.1",
        "optionator": "^0.8.1"
    },
    "optionalDependencies": {
        "source-map": "~0.6.1"
    },
    "devDependencies": {
        "acorn": "^7.1.0",
        "bluebird": "^3.4.7",
        "bower-registry-client": "^1.0.0",
        "chai": "^3.5.0",
        "commonjs-everywhere": "^0.9.7",
        "gulp": "^3.8.10",
        "gulp-eslint": "^3.0.1",
        "gulp-mocha": "^3.0.1",
        "semver": "^5.1.0"
    },
    "license": "BSD-2-Clause",
    "scripts": {
        "test": "gulp travis",
        "unit-test": "gulp test",
        "lint": "gulp lint",
        "release": "node tools/release.js",
        "build-min": "./node_modules/.bin/cjsify -ma path: tools/entry-point.js > escodegen.browser.min.js",
        "build": "./node_modules/.bin/cjsify -a path: tools/entry-point.js > escodegen.browser.js"
    }
}
