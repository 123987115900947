import { getMatch } from '../lib/utils/match.utils';
import { getTeam } from '../lib/utils/team.utils';
import { map } from '../lib/utils/math.utils';

export default class GoalDO {
  constructor(match) {
    this.match = match;
    this.index = 0;
    this.team = 'BRA';
    this.teamDO = getTeam(this.team);
    this.player = '';
    this.minute = 0;
    this.added = 0;
    this.paths = [];
    this.camera = null;
    this.half = null;
    this.halfMinute = 0;
    this.halfIndex = 0;
    this.extra = false;
  }

  parse(json) {
    this.meta = json.meta;
    this.match = json.match;
    this.team = json.team;
    this.teamDO = getTeam(json.team);
    this.kit = json.kit || 0;
    this.player = json.player;
    this.minute = json.minute || 0;
    this.added = json.added || 0;
    this.detail = json.detail;
    this.paths = json.paths;
    this.camera = json.camera;

    // pitch side
    this.leftSide = this.getSide();

    this.half = this.minute <= 45 ? 1 : 2;
    this.halfMinute = this.minute <= 45 ? this.minute : this.minute - 45;

    // extra time
    this.extra = this.minute > 90;
    // penalty shootout
    this.shootout = this.minute > 120;

    if (this.extra) {
      this.half = this.minute <= 105 ? 1 : 2;
      this.halfMinute = this.minute <= 105 ? this.minute - 90 : this.minute - 105;
    }

    this.halfAdded = this.halfMinute + this.added;

    // t : normalised minute per half
    // u : t padded to avoid small arcs close to 0' or 45'
    // v : u adjusted to avoid overlapping
    this.t = this.halfMinute / 45;
    this.u = map(this.t, 0, 1, 0.2, 0.8);
    this.v = this.u; // adjusted in MatchDO

    if (this.extra) {
      this.t = this.halfMinute / 15;
      this.u = map(this.t, 0, 1, 0.1, 0.9);
      this.v = this.u;
    }

    this.phi = (1 - this.u) * Math.PI;
  }

  // get side of the pitch
  // left: true / right: false (default)
  getSide() {
    let sumX = 0;

    this.paths.forEach(path => {
      path.anchors.forEach(anchor => {
        sumX += anchor[0];
      })
    });

    return sumX < 0;
  }

  get slug() {
    const match = getMatch(this.match);
    const added = this.added ? `-${this.added}` : ``;
    const detail = this.detail ? `-${this.detail.replace(/\./g, '')}` : ``;
    let _slug;
    
    _slug = `${match.name}-${this.team}-${this.minute}${added}-${this.player.trim()}${detail}`;
    _slug = _slug.replace(/\s+/g, '-'); // replace spaces with dashes
    _slug = _slug.normalize('NFKD').replace(/[^\w-]/g, ''); // convert diacritics to ascii i.e. áíó to aio

    return _slug;
  }

  serialize() {
    const { slug, match, team, kit, player, minute, added, detail, paths, camera } = this;

    return {
      meta: { build: AIV.version },
      slug,
      match,
      team,
      kit,
      player: player.trim(),
      minute,
      added,
      detail,
      paths,
      camera,
    };
  }
}
