import * as THREE from 'three';

export default class CustomMaterial extends THREE.MeshStandardMaterial {

	constructor(params) {
		const { defines, uniforms, vertexShader, fragmentShader } = params;

		delete params.defines;
		delete params.uniforms;
		delete params.vertexShader;
		delete params.fragmentShader;

		super(params);
		this.type = 'CustomMaterial';

		this.uniforms = THREE.UniformsUtils.merge([
			THREE.ShaderLib.standard.uniforms,
			uniforms,
		]);

		this.defines = {
			USE_UV: '',
			...defines,
		};

		this.vertexShader = vertexShader || THREE.ShaderChunk.meshphysical_vert;
		this.fragmentShader = fragmentShader || THREE.ShaderChunk.meshphysical_frag;
	}
}