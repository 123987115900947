import * as THREE from 'three';

import { clamp, nrange, gain } from '../../../../lib/utils/math.utils';

const OFFSET_CURVE	 	= 'offset-curve';
const OFFSET_GEOMETRY = 'offset-geometry';

export default class SubPath {
	
	constructor(path, segment, index, options = {}) {
		this.path = path;
		this.segment = segment;
		this.index = index;

		const {
			style,
			noise2D,
			subpaths,
			gap 					= 12,
			dist 					= 10,
			distAngle 		= 0,
			spiral 				= 1,
			spiralGain		= 1,
			parentPoints	= 5,
			count 				= 100,
			twist 				= 0,
		} = options;

		let _dist, _distAngle, _offsetType;

		switch (style) {
			case 'Dots': {
				_dist 			= nrange(0, dist, noise2D, index);
				_distAngle	= distAngle;
				_offsetType = OFFSET_GEOMETRY;
				break;
			}
			case 'Spine': {
				_dist 			= dist;
				_distAngle	= distAngle;
				_offsetType = OFFSET_CURVE;
				break;
			}
		}

		this.settings = {
			dist 				: _dist,
			distAngle 	: _distAngle,
			offsetType 	: _offsetType,
			subpaths,
			parentPoints,
			spiral,
			spiralGain,
			count,
			gap,
			twist,
		};

		this.positions = [];
		this.object3D = new THREE.Object3D();
		
		this.vec 	= new THREE.Vector3();
		this.vex 	= new THREE.Vector3();
		this.pos  = new THREE.Vector3();
		this.fwd 	= new THREE.Vector3();
		this.nml 	= new THREE.Vector3();
		this.up 	= new THREE.Vector3(0, 1, 0);

		this.initPositions();
		this.updateCurve();
		this.updatePositions();
	}

	initPositions() {
		const { path, segment, positions, vec } = this;
		const { parentPoints } = this.settings;
		const range = segment.end - segment.ini;
		let t, u;

		for (let i = 0; i < parentPoints; i++) {
			t = i / (parentPoints - 1);
			u = segment.ini + t * range;
			path.curve.getPointAt(u, vec);
			positions.push(vec.clone());
		}
	}

	updateCurve() {
		// remove old
		if (this.curve) this.object3D.remove(this.curve.mesh);

		// not enough points
		if (this.positions.length < 2) return;

		const { count, offsetType } = this.settings;

		if (offsetType == OFFSET_CURVE) {

			const { path, segment, positions, index } = this;
			const { vec, pos, fwd, up, nml, vex } = this;
			const { dist, distAngle, spiral, spiralGain, parentPoints, gap, twist, subpaths } = this.settings;
			const range = segment.end - segment.ini;
			let t, u;

			positions.length = 0;

			for (let i = 0; i < parentPoints; i++) {
				t = i / (parentPoints - 1);
				u = segment.ini + t * range;

				path.curve.getPoint(u, pos);
				path.curve.getTangent(u, fwd);

				// normal
				nml.copy(fwd).cross(up);

				u = gain(t, spiralGain);

				// rotated around forward
				vec.copy(nml);
				vec.applyAxisAngle(fwd, u * Math.PI * spiral - distAngle);
				vec.setLength(dist);
				vec.add(pos);

				fwd.set(1, 0, 0);

				// twist
				vex.copy(nml);
				vex.applyAxisAngle(fwd, u * -twist + Math.PI * 0.5);
				// vex.setLength(gap * index - subpaths * gap * 0.5 + gap * 0.5);
				vex.setLength(gap * index);
				vec.add(vex);

				positions.push(vec.clone());
			}

		}

		// create new
		const geometry = new THREE.BufferGeometry();
		geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(count * 3), 3));
		geometry.setAttribute('tangent',  new THREE.BufferAttribute(new Float32Array(count * 3), 3));

		const color = this.path.color.clone().offsetHSL(0, 0, -0.2);

		const material = new THREE.LineBasicMaterial({ color });

		const mesh = new THREE.Line(geometry, material);

		this.curve = new THREE.CatmullRomCurve3(this.positions);
		this.curve.curveType = 'centripetal';
		this.curve.mesh = mesh;
		// this.curve.frenet = this.curve.computeFrenetFrames(count);

		this.object3D.add(mesh);
	}

	updatePositions() {
		if (!this.curve) return;
		if (this.curve.points.length < 2) return;

		const { curve, index } = this;
		const { vec, pos, fwd, up } = this;
		const { dist, distAngle, spiral, spiralGain, count, offsetType } = this.settings;
		const position = curve.mesh.geometry.attributes.position;
		const tangent  = curve.mesh.geometry.attributes.tangent;

		let t, u;

		const idistAngle = distAngle + Math.PI * 0.33 * (index % 3) + Math.PI * 0.1 * index;

		for (let i = 0; i < count; i++) {
			t = i / count;

			curve.getPoint(t, pos);
			curve.getTangent(t, fwd);

			if (offsetType == OFFSET_GEOMETRY) {

				// normal
				vec.copy(fwd).cross(up);

				// fwd.copy(curve.frenet.tangents[i]);
				// vec.copy(curve.frenet.normals[i]);

				u = gain(t, spiralGain);

				// rotated around forward
				vec.applyAxisAngle(fwd, u * Math.PI * spiral + idistAngle);
				vec.setLength(dist);
				pos.add(vec);

			}

			position.setXYZ(i, pos.x, pos.y, pos.z);
			tangent.setXYZ(i, fwd.x, fwd.y, fwd.z);
		}

		position.needsUpdate = true;
		tangent.needsUpdate = true;
	}

	dispose() {
		this.object3D.clear();

		this.path = null;
		this.curve = null;
		this.object3D = null;
		this.positions = null;
	}
}
