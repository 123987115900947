import * as THREE from 'three';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';

import GLApp from '../../../../webgl/GLApp';
import { emitter, events } from '../../../../lib/utils/event.utils';

export default class Pitch {

	constructor(webgl) {
		this.webgl = webgl;

		this.object3D = new THREE.Object3D();

		this.width = 1000;
		this.height = 644;

    this.res = new THREE.Vector2();
    GLApp.renderer.getSize(this.res);

    this.material = new LineMaterial({ color: 0x333333, linewidth: 1.5 });

		this.initMesh();
		this.initHitArea();

		emitter.addListener(events.EDIT_MODE_CHANGE, this.onEditModeChange.bind(this));
	}

	initMesh() {
		const { width: w, height: h } = this;

		const pathBounds = new THREE.Path()
			.moveTo(w * -0.5, h * -0.5)
			.lineTo(w *  0.5, h * -0.5)
			.lineTo(w *  0.5, h *  0.5)
			.lineTo(w * -0.5, h *  0.5)
			.lineTo(w * -0.5, h * -0.5)

		const pathCenterLine = new THREE.Path()
			.moveTo(0, h * -0.5)
			.lineTo(0, h *  0.5)

		const pathArea = new THREE.Path()
			.moveTo(w * -0.082, h * -0.31)
			.lineTo(w *  0.082, h * -0.31)
			.lineTo(w *  0.082, h *  0.31)
			.lineTo(w * -0.082, h *  0.31)

		const pathSmallArea = new THREE.Path()
			.moveTo(w * -0.025, h * -0.14)
			.lineTo(w *  0.025, h * -0.14)
			.lineTo(w *  0.025, h *  0.14)
			.lineTo(w * -0.025, h *  0.14)

		const pathGoal = new THREE.Path()
			.moveTo(w *  0.013, h * -0.06)
			.lineTo(w * -0.013, h * -0.06)
			.lineTo(w * -0.013, h *  0.06)
			.lineTo(w *  0.013, h *  0.06)

		const pathCenterCircle = new THREE.Path()
			.absarc(0, 0, w * 0.095, 0, Math.PI * 2)

		const pathAreaArc = new THREE.Path()
			.absarc(0, 0, w * 0.095, Math.PI * 0.695, Math.PI * 1.305)

		const pathSpot = new THREE.Path()
			.absarc(0, 0, w * 0.002, 0, Math.PI * 2)

		const lineBounds 				= this.getLine(pathBounds);
		const lineCenterLine 		= this.getLine(pathCenterLine);
		const lineCenterCircle 	= this.getLine(pathCenterCircle);
		const lineCenterSpot		= this.getLine(pathSpot);
		const lineAreaL 				= this.getLine(pathArea);
		const lineAreaR 				= this.getLine(pathArea);
		const lineSmallAreaL 		= this.getLine(pathSmallArea);
		const lineSmallAreaR 		= this.getLine(pathSmallArea);
		const lineAreaArcL 			= this.getLine(pathAreaArc);
		const lineAreaArcR 			= this.getLine(pathAreaArc);
		const lineSpotL					= this.getLine(pathSpot);
		const lineSpotR					= this.getLine(pathSpot);
		const lineGoalL					= this.getLine(pathGoal);
		const lineGoalR					= this.getLine(pathGoal);

		lineAreaL.position.x = w * -0.5 + w * 0.082;
		lineAreaR.position.x = w *  0.5 - w * 0.082;
		lineAreaR.rotation.y = Math.PI;

		lineSmallAreaL.position.x = w * -0.5 + w * 0.025;
		lineSmallAreaR.position.x = w *  0.5 - w * 0.025;
		lineSmallAreaR.rotation.y = Math.PI;

		lineGoalL.position.x = w * -0.5 - w * 0.013;
		lineGoalR.position.x = w *  0.5 + w * 0.013;
		lineGoalR.rotation.y = Math.PI;

		lineAreaArcL.position.x = w *  0.39;
		lineAreaArcR.position.x = w * -0.39;
		lineAreaArcR.rotation.y = Math.PI;
		
		lineSpotL.position.x = w *  0.39;
		lineSpotR.position.x = w * -0.39;

		this.object3D.add(lineBounds);
		this.object3D.add(lineCenterLine);
		this.object3D.add(lineCenterCircle);
		this.object3D.add(lineCenterSpot);
		this.object3D.add(lineAreaL);
		this.object3D.add(lineAreaR);
		this.object3D.add(lineSmallAreaL);
		this.object3D.add(lineSmallAreaR);
		this.object3D.add(lineAreaArcL);
		this.object3D.add(lineAreaArcR);
		this.object3D.add(lineSpotL);
		this.object3D.add(lineSpotR);
		this.object3D.add(lineGoalL);
		this.object3D.add(lineGoalR);
	}

	getLine(path) {
		const points = path.getPoints(20);
		const geoPoints = new THREE.BufferGeometry().setFromPoints(points);
    const geometry = new LineGeometry();
    geometry.setPositions(geoPoints.attributes.position.array);

    const material = this.material;

		return new Line2(geometry, material);
	}

	initHitArea() {
		const { width, height } = this;

		const geometry = new THREE.PlaneGeometry(width * 1.2, height * 1.2);
		const material = new THREE.MeshBasicMaterial({
			color: 0xFF0000,
			transparent: true,
			opacity: 0.2,
			visible: false,
		});

		const mesh = new THREE.Mesh(geometry, material);

		this.object3D.add(mesh);
		this.hitArea = mesh;
	}

	onEditModeChange(value) {
		// this.object3D.visible = value;
	}

  resize() {
    GLApp.renderer.getSize(this.res);
    if (this.material) this.material.resolution.copy(this.res);
  }
}
