import ways from 'ways';
import Layout from '~/src/pages/layout/Layout';
import Match from '~/src/pages/match/Match';
import Matches from '~/src/pages/matches/Matches';
import Goal from '~/src/pages/goal/Goal';

ways.flow('destroy+run');
ways.use(ways.addressbar);

export let layout = Layout;
export let matches = Matches;
export let goal = Goal;

let currentMatch;

export default () => {
  ways('/', layout.transitionIn, layout.transitionOut);
  ways('/matches', matches.transitionIn, matches.transitionOut, '/');
  ways(
    '/match/:id',
    (req, done) => {
      currentMatch = new Match();
      currentMatch.transitionIn(req, done);
    },
    (req, done) => {
      if (currentMatch) {
        currentMatch.transitionOut(req, done);
      }
    },
    '/'
  );
  ways('/goal/:id', goal.transitionIn, goal.transitionOut, '/');
  ways.go(ways.pathname().length > 1 ? ways.pathname() : '/');
  // ways.go(`/`);

  ways.on('url:change', (url) => {
    ways.__nextURL = url;
  });

  window.addEventListener('resize', () => {
    layout.resize();
    matches.resize();
    if (currentMatch) {
      currentMatch.resize();
    }
  });
};
