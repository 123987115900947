import * as THREE from 'three';
import { gsap } from 'gsap';

// import { getEditMode } from '../../utils/app.utils';

import Style from './Style';
import SubSpine from './SubSpine';

export default class Spine extends Style {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Spine';

		this.settings 	= {
			subpaths 			: 3,
			parentPoints	: 7,
			count					: 100,
			size 					: 10,
			gap 					: 12,
			spiral 				: 0.5,
			twist 				: 0,
			dist 					: 10,
			distAngle			: 2.4,
			animation 		: 'M',
			...this.settings
		};

		this.subspines = [];
		
		this.initData(data);
		this.initGUI();
		this.updatePositions();
	}

	updateColors() {
		this.subspines.forEach(subspine => subspine.updateColors());
	}

	updatePositions() {
		const { noise2D, settings, name } = this;
		settings.noise2D = noise2D;

		// clear existing subspines
		this.object3D.clear();
		this.subspines.forEach(subspine => subspine.dispose());
		this.subspines.length = 0;

		const options = { style: name, ...settings };
		const subpaths = this.path.getSubPaths(settings.subpaths, options);
		
		subpaths.forEach((subpath, index) => {
			const subspine = new SubSpine(this, subpath, index, settings);
			this.subspines.push(subspine);
			this.object3D.add(subspine.object3D);
			this.object3D.add(subpath.object3D);

			// subpath.object3D.visible = getEditMode();
		});
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	preShow() {
		// hide all on start
		this.subspines.forEach(subspine => subspine.hide(true));
	}

	show() {
		const timeline = gsap.timeline({ autoRemoveChildren: true });

		this.subspines.forEach(subspine => {
			timeline.add(subspine.show(), 0);
		});

		this.timelineShow = timeline;

		return timeline;
	}

	hide() {
		const timeline = gsap.timeline({ autoRemoveChildren: true });
		
		this.subspines.forEach(subspine => {
			timeline.add(subspine.hide(), 0);
		});

		this.timelineHide = timeline;
		
		return timeline;
	}

	dispose() {
		this.timelineShow?.kill();
		this.timelineHide?.kill();

		super.dispose();
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	onEditModeChange(value) {
		this.subspines.forEach(subspine => {
			subspine.path.object3D.visible = value;
		});
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

		const animOptions = {
			S 	: 'S',
			M 	: 'M',
			L 	: 'L',
			XL 	: 'XL',
		};

    if (!folder) return;

		folder.addInput(settings, 'subpaths', 		{ min: 1, max: 6, 	step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'parentPoints', { min: 2, max: 20, 	step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'count', 				{ min: 1, max: 200, step: 1 }).on('change', this.updatePositions.bind(this));

		folder.addInput(settings, 'size', 				{ min: 1, max: 100, step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'gap', 					{ min: 1, max: 100, step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'spiral', 			{ min: 0, max: 10  }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'twist',	 			{ min: 0, max: Math.PI * 2  }).on('change', this.updatePositions.bind(this));
		// folder.addInput(settings, 'spiralGain', 	{ min: 1, max: 10  }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'dist', 				{ min: 1, max: 100 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'distAngle', 		{ min: -Math.PI, max: Math.PI }).on('change', this.updatePositions.bind(this));

		folder.addInput(settings, 'animation', 		{ options: animOptions });

		return folder;
	}

}
