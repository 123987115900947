import EventEmitter from 'events';

let alreadyTested = false;
let passiveSupported = false;

const _emitter = new EventEmitter();

export const emitter = _emitter;

export const events = {
  EDIT_MODE_CHANGE    : 'edit-mode-change',
  PATH_SELECT         : 'path-select',
};

export const passiveEvent = () => {
  const isSupported = () => {
    if (alreadyTested) return passiveSupported;
    alreadyTested = true;

    // Test via a getter in the options object to see if the passive property is accessed
    try {
      let opts = Object.defineProperty({}, 'passive', {
        get: () => {
          passiveSupported = true;
        }
      });
      window.addEventListener('test', null, opts);
    } catch (e) {
      return passiveSupported;
    }
    window.removeEventListener('test', null, opts);
    return passiveSupported;
  };

  return isSupported() ? { passive: true } : false;
};
