import * as THREE from 'three';
import alea from 'alea';
import { createNoise2D } from 'simplex-noise';
import { gsap } from 'gsap';

import { getGUI, addCustomFolder, collapseOthers, FOLDER_STYLE } from '../../../../lib/utils/gui.utils';
import { getGoal } from '../../../../lib/utils/match.utils';

export const BASE_DURATION = 3.0;

export default class Style {
	
	constructor(path, index) {
		this.path = path;
		this.curve = path.curve;
		this.index = index;
		this.name = this.constructor.name;

		this.settings = {
			seed: 22 + index,
			range: { min: 0, max: 1 },
		};

		this.noise2D = createNoise2D(alea(this.settings.seed));
		this.timeline = gsap.timeline();
		this.tween = { ini: 0, end: 1, t: 0 };

		this.object3D = new THREE.Object3D();
	}

	initData(data) {
		if (!data) return;
		this.settings = { ...this.settings, ...data.settings };

		this.onSeedChange();
	}

	updatePositions() { }

	updateColors() { }

	updateGUIColorRadio() {
		const { guiColorRadio, folder, settings } = this;
		const { colorIdx } = this.settings;
		let disabled;

    if (!folder) return;

		if (guiColorRadio) {
			disabled = guiColorRadio.disabled;
			folder.remove(guiColorRadio);
		}

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];
		const lng 	= kit.colors.length;
		const idx 	= colorIdx % lng;
		const hex 	= kit.colors[idx];

		this.guiColorRadio = folder.addInput(settings, 'colorIdx', {
			view: 'radiogrid',
			groupName: 'colorIdx',
			size: [lng, 1],
			cells: (x, y) => ({ title: `${x}`, value: x }),
			label: 'colorIdx',
			disabled,
		}).on('change', () => { this.updateColors(true) });

		// hack element's background color
		const children = this.guiColorRadio.element.children[1].children[0].children;
		for (let i = 0; i < children.length; i++) {
			const child = children[i];
			child.style.background = kit.colors[i % lng];
		}
	}

	serialize() {
		const { name, settings } = this;
		return { name, settings };
	}

	dispose() {
		this.timeline.kill();
		this.folder?.dispose();
		this.object3D.clear();

		this.object3D 	= null;
		this.path 			= null;
		this.curve 			= null;
		this.noise2D 		= null;
		this.timeline 	= null;
		this.folder 		= null;
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

  preShow() {
    // immediate hide
    this.onShowUpdate(0);
  }

	showHide(showDelay = 0, hideDelay = 0, onComplete) {
		const { speed } = this;

		const show = this.show();
		const hide = this.hide();

		const showDuration = BASE_DURATION * speed;
		const hideDuration = BASE_DURATION * speed;

		// the first path starts hiding when its show is complete
		if (!this.path.index) hideDelay += showDuration;

		this.timeline = gsap.timeline({ onComplete });
		this.timeline.add(show, showDelay);
		this.timeline.add(hide, hideDelay);
		this.timeline.play(0);

		// the others start hiding when the previous path hide is complete
    let hideComplete = this.timeline.duration();
    if (this.name == 'Poly') hideComplete = Math.min(this.timeline.duration(), BASE_DURATION);

		return { showDuration, hideComplete };
	}

	show() {
		const { tween, path, speed } = this;
		const timeline = gsap.timeline({ onUpdate: () => this.onShowUpdate(tween.end) });

		const duration = BASE_DURATION * speed;

		timeline.set(tween, { end: 0 }, 0);
		timeline.to(tween,  { end: 1, duration, ease: 'sine.out' }, 0);

		return timeline;
	}

	hide() {
		const { tween, path, speed } = this;
		const timeline = gsap.timeline({ onUpdate: () => this.onHideUpdate(tween.ini)	});

		const duration = BASE_DURATION * speed;

		timeline.set(tween, { ini: 0 }, 0);
		timeline.to(tween,  { ini: 1, duration, ease: 'quad.in' }, 0);

		return timeline;
	}

	onShowUpdate(t) { }

	onHideUpdate(t) { }

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	onEditModeChange(value) { }

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
    if (!getGUI()) return;
		if (!parent) parent = this.path.folder;

		const onRemoveClick = () => { this.path.removeStyle(this.index); };
		
		const onVisibleClick = (value) => { this.object3D.visible = value; };

		const onFold = (e) => { collapseOthers(parent, e.target, e.expanded); }

		const { index, name, settings } = this;
		const folder = addCustomFolder(parent, { title: `${index}. ${name}`, type: FOLDER_STYLE, onRemoveClick, onVisibleClick });
		folder.on('fold', onFold);
		folder.expanded = false;

		folder.addInput(settings, 'seed',  { min: 1, max: 100, step: 1 }).on('change', this.onSeedChange.bind(this));
		folder.addInput(settings, 'range', { min: 0, max: 1 }).on('change', this.onRangeChange.bind(this));

		this.folder = folder;
		return folder;
	}

	onSeedChange() {
		this.noise2D = createNoise2D(alea(this.settings.seed));
		this.updatePositions();
	}

	onRangeChange() {
		this.updatePositions();
	}

	// ---------------------------------------------------------------------------------------------
	// GETTERS / SETTERS
	// ---------------------------------------------------------------------------------------------

	get speed() {
		const { path } = this;
		const { range } = this.settings;

		let _minSpeed = 0.2;
		let _length, _speed;

		_length = path ? path.curve.getLength() : 1000;
		if (range) _length *= (range.max - range.min);

		_speed = _length * 0.001;
		_speed = Math.max(_minSpeed, _speed);

		return _speed;
	}

}
