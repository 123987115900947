import parseHTML from '../../../lib/utils/parseHTML';
import autoBind from 'auto-bind';
import './cover-flag.scss';
import gsap from 'gsap';
import tmpl from './cover-flag.pug';
import Flag from '../flag/Flag';
import { flags } from '../../../lib/loader';

import isTouchDevice from '../../../lib/utils/isTouchDevice';

export default class CoverFlag {
  constructor(data, style) {
    autoBind(this);
    this.data = data;
    this.big = style ? style.big : null;
    this.visitor = style ? style.visitor : false;
    this.type = 'three';
    if (this.data && this.data.flag.length === 2) {
      this.type = 'two';
    }
  }

  render() {
    this.flagSmall = new Flag(this.data, {
      big: this.big,
      asset: this.data ? flags[this.data.slug] : null,
    });
    this.flagBig = new Flag(this.data, { big: this.big });
    this.el = parseHTML(tmpl());
    this.el.appendChild(this.flagSmall.render());
    this.el.appendChild(this.flagBig.render());
    this.flagSmallEl = this.flagSmall.el;
    this.flagBigElTop = this.flagBig.el.querySelectorAll('span')[0];
    this.flagBigElBottom = this.flagBig.el.querySelectorAll('span')[1];
    this.flagBigEl = this.flagBig.el;
    this.flagBig.el.style['pointer-events'] = 'none';
    return this.el;
  }

  out() {
    this.tl = gsap.timeline();
    this.tl.to(this.flagSmallEl, {
      scale: 1,
    });
  }

  over(visit) {
    this.tl = gsap.timeline();
    this.tl.to(this.flagSmallEl, {
      scale: 2,
      duration: 0.1,
      ease: 'power4.out',
      transformOrigin: visit ? '0% 50%' : '100% 50%',
    });
    return this.tl;
  }

  outro() {
    // this.el.classList.remove('show');

    this.tl = gsap.timeline();
    this.tl.set(this.flagSmallEl, {
      opacity: 0,
      scaleX: 5,
      x: this.visitor ? '-100%' : '100%',
      transformOrigin: this.visitor ? 'center right' : 'center left',
    });
    this.tl.set(this.flagBigEl, {
      scaleY: 2,
      x: this.visitor ? '-600%' : '600%',
      scaleX: 10,
      duration: 0.3,
      ease: 'power4.out',
      transformOrigin: 'center center',
    });
    this.tl.set(
      this.flagBigElBottom,
      {
        scaleY: 2,
        transformOrigin: 'bottom left',
      },
      0
    );
    this.tl.fromTo(
      this.flagBigElTop,
      {
        scaleY: 1,
        transformOrigin: 'top left',
      },
      {
        scaleY: 2,
        duration: 0.35,
        ease: 'power.out',
        transformOrigin: 'top left',
      }
    );
    this.tl.to(
      this.flagBigEl,
      {
        scaleY: 1.5,
        scaleX: 8,
        duration: 0.5,
        ease: 'power4.in',
        transformOrigin: 'center center',
      },
      0
    );
    this.tl.set(this.flagBigElTop, {
      scaleY: 0.8,
      ease: 'power.out',
      transformOrigin: 'top left',
    });
    this.tl.fromTo(
      this.flagBigEl,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.01,
        ease: 'power4.out',
      },
      '+=.05'
    );
    this.tl.to(
      this.flagSmallEl,
      {
        opacity: 1,
        duration: 0,
        ease: 'power4.out',
      },
      0.45
    );
    this.tl.to(
      this.flagSmallEl,
      {
        x: '0%',
        duration: 0.1,
        ease: 'power4.out',
      },
      0.45
    );
    this.tl.to(
      this.flagSmallEl,
      {
        scaleX: 1,
        duration: 0.5,
        ease: 'power4.out',
      },
      0.5
    );
    return this.tl;
  }

  reset() {
    this.el.style.opacity = 0;
  }

  intro(scl) {
    let scale = scl || 1;
    this.tl = gsap.timeline();
    this.tl.set(this.el, {
      opacity: 1,
    });
    this.tl.add(this.flagBig.intro());
    this.tl.add(this.flagSmall.intro());
    this.tl.set(this.flagSmallEl, {
      opacity: 0,
      scaleX: 5 * scale,
      x: this.visitor ? '-100%' : '100%',
      transformOrigin: this.visitor ? 'center right' : 'center left',
    });
    this.tl.set(this.flagBigEl, {
      scaleY: 2 * scale,
      x: this.visitor ? `-${600 * scale}%` : `${600 * scale}%`,
      scaleX: 10 * scale,
      duration: 0.3,
      ease: 'power4.out',
      transformOrigin: 'center center',
    });
    if (!isTouchDevice()) {
      this.tl.set(
        this.flagBigElBottom,
        {
          scaleY: 2,
          transformOrigin: 'bottom left',
        },
        0
      );
      this.tl.fromTo(
        this.flagBigElTop,
        {
          scaleY: 1,
          transformOrigin: 'top left',
        },
        {
          scaleY: 2,
          duration: 0.35,
          ease: 'power.out',
          transformOrigin: 'top left',
        }
      );
    }
    this.tl.to(
      this.flagBigEl,
      {
        scaleY: 1.5 * scale,
        scaleX: 8 * scale,
        duration: 0.5,
        ease: 'power4.in',
        transformOrigin: 'center center',
      },
      0
    );
    if (!isTouchDevice()) {
      this.tl.set(this.flagBigElTop, {
        scaleY: 0.8 * scale,
        ease: 'power.out',
        transformOrigin: 'top left',
      });
    }
    this.tl.fromTo(
      this.flagBigEl,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.01,
        ease: 'power4.out',
      },
      '+=.05'
    );
    this.tl.to(
      this.flagSmallEl,
      {
        opacity: 1,
        duration: 0,
        ease: 'power4.out',
      },
      0.45
    );
    this.tl.to(
      this.flagSmallEl,
      {
        x: '0%',
        duration: 0.1,
        ease: 'power4.out',
      },
      0.45
    );
    this.tl.to(
      this.flagSmallEl,
      {
        scaleX: 1,
        duration: 0.5,
        ease: 'power4.out',
      },
      0.5
    );
    return this.tl;
  }
}
