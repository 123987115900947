import * as THREE from 'three';

export const getPolygonShape = (sides = 3, outerRadius = 100, innerRadius = 80) => {
	const phi = Math.PI * 2 / sides;
	const tta = Math.PI * 0.5;
	const shape = new THREE.Shape();
	const hole = new THREE.Path();
	let x, y;

	for (let i = 0; i < sides; i++) {
		x = Math.cos(phi * i + tta) * outerRadius;
		y = Math.sin(phi * i + tta) * outerRadius;

		if (!i) shape.moveTo(x, y);	
		else shape.lineTo(x, y);
	}

	for (let i = 0; i < sides; i++) {
		x = Math.cos(phi * -i + tta) * innerRadius;
		y = Math.sin(phi * -i + tta) * innerRadius;

		if (!i) hole.moveTo(x, y);	
		else hole.lineTo(x, y);
	}

	shape.holes.push(hole);

	return shape;
}
