import parseHTML from '../../../../lib/utils/parseHTML';
import autoBind from 'auto-bind';
import gsap from 'gsap';
import ways from 'ways';
import CoverFlag from '../../cover-flag/CoverFlag';

import tmpl from './knockout-match.pug';

export default class KnockoutMatch {
  data = undefined;
  flags = [];
  el = undefined;
  constructor(data) {
    autoBind(this);
    this.data = data;
  }

  intro() {
    let isSideB = this.el.parentNode.parentNode.classList.contains('side-b');
    let side = isSideB ? 'right' : 'left';

    let tl = gsap.timeline();

    tl.add(() => {
      this.el.classList.add('show');
    });
    tl.add(this.flag1.intro(), 0);
    tl.add(this.flag2.intro(), 0);

    tl.fromTo(
      this.topline,
      { duration: 2, scaleX: 0 },
      { scaleX: 1, transformOrigin: `top ${side}`, ease: 'power4.out' },
      0.3
    );

    tl.fromTo(
      this.bottomLine,
      { duration: 2, scaleX: 0 },
      { scaleX: 1, transformOrigin: `top ${side}`, ease: 'power4.out' },
      0.3
    );

    tl.fromTo(
      this.middleLine,
      { duration: 2, scaleY: 0 },
      { scaleY: 1, transformOrigin: `center ${side}`, ease: 'power4.out' },
      0.5
    );

    setTimeout(() => {
      this.events();
    }, 1000);

    return tl;
  }

  render() {
    this.el = parseHTML(tmpl(this.data));

    this.topline = this.el.querySelector('.top');
    this.middleLine = this.el.querySelector('.middle');
    this.bottomLine = this.el.querySelector('.bottom');

    this.flag1 = new CoverFlag(this.data.teamDOs[0] ? this.data.teamDOs[0] : null);
    this.flag2 = new CoverFlag(this.data.teamDOs[1] ? this.data.teamDOs[1] : null);
    this.el.querySelector('.team-1').appendChild(this.flag1.render());
    this.el.querySelector('.team-2').appendChild(this.flag2.render());
    return this.el;
  }

  events() {
    if (this.data.result) {
      this.el.addEventListener('mouseenter', this.onOver);
      this.el.addEventListener('mouseleave', this.onOut);
      this.el.addEventListener('click', this.onClick);
    } else {
      this.el.classList.add('disabled');
    }
  }

  onClick() {
    if (!this.data.result) return;
    ways.go(`/match/${this.data.id}`);
  }

  onOver() {
    this.el.classList.add('over');
  }

  onOut() {
    this.el.classList.remove('over');
  }

  outro() {
    this.el.classList.remove('show');
    this.flag1.reset();
    this.flag2.reset();
  }
}
