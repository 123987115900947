import * as THREE from 'three';

import MenuArc from './MenuArc';

export default class MenuArcs {

  constructor(menu) {
    this.menu = menu;

    this.arcs = [];

    this.object3D = new THREE.Object3D();
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  initData(matchDO) {
    // remove old
    this.object3D.clear();
    this.arcs.forEach(arc => arc.dispose());
    this.arcs.length = 0;

    // init new
    matchDO.goals.forEach(goal => {
      if (goal.extra) return;

      const arc = new MenuArc(this, this.menu.sphere, goal);
      this.arcs.push(arc);
      this.object3D.add(arc.object3D);
    });

    this.matchDO = matchDO;
  }

  show() {
    this.arcs.forEach(arc => arc.show());
  }

  hide(fullHide = false) {
    this.arcs.forEach(arc => arc.hide(fullHide));
  }

  toggleHemisphere(hemisphere) {
    this.arcs.forEach(arc => arc.toggleHemisphere(hemisphere));
  }

  dispose() {
    this.object3D.clear();
    this.arcs.forEach(arc => arc.dispose());

    this.matchDO = null;
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize(vw, vh) {
    this.arcs.forEach(arc => arc.resize(vw, vh));
  }
}
