import * as THREE from 'three';
import { gsap } from 'gsap';

// import { getEditMode } from '../../utils/app.utils';
import { parabola, lerp, clamp, gain } from '../../../../lib/utils/math.utils';

import Style from './Style';
import SubDots from './SubDots';

export default class Dots extends Style {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Dots';

		this.settings 	= {
			seedColor 		: 4,
			subpaths 			: 4,
			parentPoints	: 7,
			count					: 80,
			size 					: 70,
			spiral 				: 2,
			spiralGain 		: 2,
			dist 					: 35,
			distAngle			: 2.6,
			chroma 				: 'lrgb',
			...this.settings
		};
		
		this.subdots = [];

		this.initData(data);
		this.initGUI();
		this.updatePositions();
	}

	updateColors() {
		this.subdots.forEach(subdot => subdot.updateColors());
	}

	updatePositions() {
		const { noise2D, settings, name } = this;
		settings.noise2D = noise2D;

		// clear existing subdots
		this.object3D.clear();
		this.subdots.forEach(subdot => subdot.dispose());
		this.subdots.length = 0;

		// create new
		const options = { style: name, ...settings };
		const subpaths = this.path.getSubPaths(settings.subpaths, options);
		
		subpaths.forEach((subpath, index) => {
			const subdot = new SubDots(this, subpath, index, settings);
			this.subdots.push(subdot);
			this.object3D.add(subdot.object3D);
			this.object3D.add(subpath.object3D);

			// subpath.object3D.visible = getEditMode();
		});
	}

	show() {
		const timeline = gsap.timeline({ autoRemoveChildren: true });

		this.subdots.forEach(subdot => {
			timeline.add(subdot.show(), 0);
		});

		this.timelineShow = timeline;

		return timeline;
	}

	hide() {
		const timeline = gsap.timeline({ autoRemoveChildren: true });
		
		this.subdots.forEach(subdot => {
			timeline.add(subdot.hide(), 0);
		});

		this.timelineHide = timeline;
		
		return timeline;
	}

	dispose() {
		this.timelineShow?.kill();
		this.timelineHide?.kill();

		super.dispose();
	}

	// ---------------------------------------------------------------------------------------------
	// EVENT HANDLERS
	// ---------------------------------------------------------------------------------------------

	onEditModeChange(value) {
		this.subdots.forEach(subdot => {
			subdot.path.object3D.visible = value;
		});
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

		const chromaOptions = {
			hsl 	: 'hsl',
			lab 	: 'lab',
			lrgb 	: 'lrgb',
			none 	: 'none',
		};

    if (!folder) return;

		folder.addInput(settings, 'subpaths', 		{ min: 1, max: 8, 	step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'parentPoints', { min: 2, max: 20, 	step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'count', 				{ min: 1, max: 100, step: 1 }).on('change', this.updatePositions.bind(this));

		folder.addInput(settings, 'size', 				{ min: 1, max: 100, step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'spiral', 			{ min: -5, max: 5  }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'spiralGain', 	{ min: 1, max: 10  }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'dist', 				{ min: 1, max: 100 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'distAngle', 		{ min: -Math.PI, max: Math.PI }).on('change', this.updatePositions.bind(this));
		
		folder.addInput(settings, 'seedColor', 		{ min: 1, max: 50,	step: 1 }).on('change', this.updateColors.bind(this));
		folder.addInput(settings, 'chroma', 			{ options: chromaOptions }).on('change', this.updateColors.bind(this));

		return folder;
	}
}
