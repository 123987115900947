import KnockoutMatch from './match/KnockoutMatch';
import parseHTML from '../../../lib/utils/parseHTML';
import gsap from 'gsap';
import Flag from '../flag/Flag';

import tmpl from './knockout.pug';
import './knockout.scss';
import { getFinal, getQuarters, getSemis, getSixteen } from '../../../lib/utils/match.utils';
import CoverFlag from '../cover-flag/CoverFlag';
import { breakpoints } from '../../../lib/mediaQueries';
import { getTeam } from '../../../lib/utils/team.utils';

export default class KnockoutTable {
  el = undefined;
  flags = [];
  matches = [];
  sixteen = [];
  quarter = [];
  semi = [];
  final = [];
  render(parent) {
    this.parent = parent;
    this.sixteen = getSixteen();
    this.quarter = getQuarters();
    this.semi = getSemis();
    this.final = getFinal();

    this.el = parseHTML(
      tmpl({
        round16: this.sixteen,
        quarters: this.quarter,
        semis: this.semi,
        final: this.final,
      })
    );
    this.parent.appendChild(this.el);
    this.createSixteen();
    this.createQuarters();
    this.createSemis();
    this.createFinal();
    this.createWinner();
    setTimeout(() => {
      this.resize();
    });
  }

  transitionIn() {
    this.tl = gsap.timeline();
    this.matches.forEach((m, i) => {
      this.tl.add(m.intro(), i * 0.04);
    });
    this.tl.add(this.winnerFlag.intro(), 0.8);
    return this.tl;
  }

  transitionOut() {
    if (this.tl) {
      this.tl.clear();
      this.tl.kill();
    }
    this.matches.forEach((m, i) => {
      m.outro();
    });
    this.winnerFlag.reset();
  }

  dispose() {
    // this.matches.forEach((m, i) => {
    //   m.classList.remove('over');
    // });
  }

  createSixteen() {
    this.sixteenA = this.el.querySelector('.side-a .round-16');
    this.sixteenB = this.el.querySelector('.side-b .round-16');
    this.sixteen.forEach((match, i) => {
      let m = new KnockoutMatch(match);
      this.matches.push(m);
      if (i < 4) {
        this.sixteenA.appendChild(m.render());
      } else {
        this.sixteenB.appendChild(m.render());
      }
    });
  }

  createQuarters() {
    this.quartersA = this.el.querySelector('.side-a .quarters');
    this.quartersB = this.el.querySelector('.side-b .quarters');
    this.quarter.forEach((match, i) => {
      let m = new KnockoutMatch(match);
      this.matches.push(m);
      if (i < 2) {
        this.quartersA.appendChild(m.render());
      } else {
        this.quartersB.appendChild(m.render());
      }
    });
  }

  createSemis() {
    this.quartersA = this.el.querySelector('.side-a .semis');
    this.quartersB = this.el.querySelector('.side-b .semis');
    this.semi.forEach((match, i) => {
      let m = new KnockoutMatch(match);
      this.matches.push(m);
      if (i < 1) {
        this.quartersA.appendChild(m.render());
      } else {
        this.quartersB.appendChild(m.render());
      }
    });
  }

  createFinal() {
    this.finalA = this.el.querySelector('.final');
    let m = new KnockoutMatch(this.final[0]);
    this.matches.push(m);
    this.finalA.appendChild(m.render());
  }

  createWinner() {
    this.winner = this.el.querySelector('.winner');
    let win = getTeam(this.final[0].winner);
    console.log(win);
    this.winnerFlag = new CoverFlag(win, { big: true });
    this.winner.appendChild(this.winnerFlag.render());
  }

  resize() {
    if (!this.el) return;

    if (window.innerWidth < breakpoints.desktop) {
      let width = window.innerWidth / this.el.offsetHeight;
      let height = window.innerHeight / this.el.offsetWidth;
      if (width < 1.2 || height < 1.2) {
        gsap.set(this.el, { rotate: 90, scaleY: width * 0.85, scaleX: height * 0.7 });
      }
    } else {
      gsap.set(this.el, { scaleY: 1, scaleX: 1, rotate: 0 });
    }
  }
}
