import * as THREE from 'three';
import { random } from 'canvas-sketch-util';
import { gsap } from 'gsap';

import { SPHERE_RADIUS } from './Menu';

const margin = 100;

export default class MenuOutro {

  constructor(menu) {
    this.menu = menu;
    this.camera = menu.camera;

    window.outro = this;

    this.initCanvas();
    this.initMesh();
  }

  initCanvas() {
    this.canvas = new OutroCanvas();
  }

  initMesh() {
    const geometry = new THREE.PlaneGeometry(1, 1);
    const material = new THREE.MeshBasicMaterial();
    const mesh = new THREE.Mesh(geometry, material);

    this.object3D = mesh;
    this.object3D.visible = false;
  }

  show(delay) {
    this.object3D.visible = true;
    return this.canvas.show(delay);
  }

  hide() {
    this.object3D.visible = false;
    this.canvas.hide();
  }

  update() {
    this.canvas.draw();
    if (this.texture) this.texture.needsUpdate = true;
  }

  resize() {
    let scale;

    this.fovHeight = 2 * Math.tan((this.camera.fov * Math.PI) / 180 / 2) * this.camera.position.z;
    this.fovRatio = window.innerHeight / this.fovHeight;

    // object scale
    scale = SPHERE_RADIUS * 2 + margin * 2 / this.fovRatio;
    this.object3D.scale.set(scale, scale, 1);

    // canvas scale
    scale = ~~(SPHERE_RADIUS * 2 * this.fovRatio) + margin * 2;
    this.canvas.resize(scale);

    // cannot resize texture, it needs to be recreated
    if (this.texture) this.texture.dispose();

    this.texture = new THREE.CanvasTexture(this.canvas.canvas);
    this.object3D.material.map = this.texture;
    this.object3D.material.needsUpdate = true;
  }
}



// ---------------------------------------------------------------------------------------------
// CANVAS
// ---------------------------------------------------------------------------------------------

class OutroCanvas {

  constructor() {
    this.canvas = document.createElement('canvas');
    this.context = this.canvas.getContext('2d', { alpha: false });

    // this.canvas.style = 'position:absolute; top:0px; left:0px;';
    // document.body.appendChild(this.canvas)

    this.settings = {
      circScale     : 0,
      innerRadius   : 110,
      outerRadius   : 300,
      dotRadius     : 4,
      numRings      : 9,
      ringsVisible  : false,
      ringsRotation : 0,
      noiseThresh   : 0.0,
      noiseFreq     : 0.1,
      connectThresh : 0.8,
    };

    // deferred to show
    // this.initRings();
  }

  initRings() {
    const { innerRadius, outerRadius, dotRadius, numRings } = this.settings;
    const deltaRadius = outerRadius - innerRadius - dotRadius;
    const sliceRadius = deltaRadius / (numRings - 1);
    const rings = [];

    // randomise noise threshold and frequency
    const noiseThresh = random.range(-0.5, 0.0);
    const noiseFreq = 0.01;
    const connectThresh = random.range(0.4, 0.8);

    for (let i = 0; i < numRings; i++) {
      const ringRadius = innerRadius + sliceRadius * i;
      const numDots = ~~(ringRadius / dotRadius * 2);
      const slice = Math.PI * 2 / numDots;

      const ring = { angle: random.range(0, Math.PI), index: i, radius: ringRadius, sliceRadius, dots: [] };

      for (let j = 0; j < numDots; j++) {
        const angle = slice * j;
        const x = Math.sin(angle) * ringRadius;
        const y = Math.cos(angle) * ringRadius;

        const n = random.noise2D(x, y, noiseFreq);
        if (n > noiseThresh) continue;

        const curr = { angle, slice, ring, ringRadius, index: j, radius: dotRadius, phi: 0, tta: 0 };
        const prev = j ? ring.dots[ring.dots.length - 1] : null;
        // assign curr as previous->next
        if (prev) prev.next = curr;

        ring.dots.push(curr);
      }

      rings.push(ring);
    }

    rings.forEach(ring => {
      // filter dots where next isn't immediate neighbour // keep only a small subset
      ring.dotsWithGap = ring.dots.filter(dot => dot.next?.index > dot.index + 1 && random.value() > connectThresh);
      // filter some random dots to jump out of their orbits
      ring.dotsJumpOrbit = ring.dots.filter(dot => dot.next?.index == dot.index + 1 && random.value() > 0.4);
    });

    this.rings = rings;
  }

  draw() {
    const { canvas, context, rings, settings } = this;
    const { circScale, outerRadius, ringsVisible, ringsRotation, dotRadius } = this.settings;
    const { width, height } = canvas;

    // match canvas scale to circle radius
    const scale = (width * 0.5 - margin) / outerRadius;

    context.clearRect(0, 0, width, height);

    context.save();
    context.translate(width * 0.5, height * 0.5);
    context.scale(scale, scale);

    // big circle
    context.fillStyle = 'white';
    context.beginPath();
    context.arc(0, 0, outerRadius * circScale, 0, Math.PI * 2);
    context.fill();

    if (!ringsVisible) {
      context.restore();
      return;
    }

    context.strokeStyle = 'white';
    context.lineWidth = dotRadius * 2;
    context.lineCap = 'round';

    context.rotate(ringsRotation);

    // rings
    rings.forEach(ring => {
      context.rotate(ring.angle);

      ring.dots.forEach(dot => {
        const x = Math.cos(dot.angle) * dot.ringRadius;
        const y = Math.sin(dot.angle) * dot.ringRadius;

        context.beginPath();
        context.arc(x, y, dot.radius, 0, Math.PI * 2);
        context.fill();
      });


      ring.dotsWithGap.forEach(dot => {
        const iniAngle = dot.angle;
        const endAngle = dot.next.angle;
        const dltAngle = endAngle - iniAngle;

        const phi = iniAngle + dot.phi * dltAngle;
        const tta = iniAngle + dot.tta * dltAngle;

        const x = Math.cos(phi) * ring.radius;
        const y = Math.sin(phi) * ring.radius;

        context.beginPath();
        context.moveTo(x, y);
        context.arc(0, 0, ring.radius, phi, tta);
        context.stroke();
      });


      context.rotate(-ring.angle);
    });

    context.restore();
  }

  show(delay = 0) {
    this.initRings();

    const { settings, rings } = this;
    const timeline = gsap.timeline();

    // expand quickly
    timeline.set(settings, { circScale: 0.8 }, 0);
    timeline.to(settings, { circScale: 1.01, duration: 0.3, ease: 'expo.out' }, 0);
    // hide big circle
    timeline.set(settings, { circScale: 0, ringsVisible: 1, ringsRotation: Math.PI * -0.15 }, delay);
    // reveal rings
    timeline.to(settings, { ringsRotation: 0, duration: 0.8, ease: 'quart.out' }, delay);

    // random delays for ring spins
    let rdelays = [];
    for (let i = 0; i < rings.length; i++) { rdelays.push(i); }
    rdelays = random.shuffle(rdelays);

    // random delays for dots
    const delays = [0.0, 0.2, 0.3, 0.4];

    rings.forEach(ring => {
      // spin rings
      // const rdelay = rdelays[ring.index] * 0.1;
      const rdelay = 0.;
      const rduration = 0.2 + ring.index * 0.1;
      const angle = 0.1 + (rings.length - ring.index) * 0.04;
      timeline.to(ring, { angle: ring.angle + angle, duration: rduration, ease: 'quad.out' }, rdelay + delay);

      // jump dots
      ring.dotsJumpOrbit.forEach(dot => {
        const ddelay = random.pick(delays);
        const sradius = random.boolean() ? -ring.sliceRadius : ring.sliceRadius
        timeline.to(dot, { ringRadius: dot.ringRadius + sradius, duration: 0.4, ease: 'quad.out' }, ddelay + delay);
      });

      // connect dots
      ring.dotsWithGap.forEach(dot => {
        // const ddelay = random.pick(delays);
        const ddelay = 0;
        timeline.set(dot, { tta: 1 }, 0);
        timeline.to(dot, { phi: 1, duration: 0.6, ease: 'quad.out'  }, ddelay + delay);

        // timeline.to(dot, { tta: 1, duration: 0.4, ease: 'quad.out' }, ddelay + delay);
        // timeline.to(dot, { phi: 1, duration: 0.4, ease: 'quad.in'  }, ddelay + delay + 0.4);
      });
    });

    return timeline;
  }

  hide() {
    this.settings.ringsVisible = 0;
  }

  resize(size) {
    this.canvas.width = size;
    this.canvas.height = size;
  }
}
