varying vec2 vUv;
varying vec3 vColor;

void main() {
	vUv = uv;
	vColor = instanceColor;

	float scale = length(instanceMatrix[0].xyz);

	vec4 mvPosition = modelViewMatrix * instanceMatrix * vec4(0.0, 0.0, 0.0, 1.0);
	mvPosition.xyz += position * scale;

	gl_Position = projectionMatrix * mvPosition;
}