import * as THREE from 'three';
import { gsap } from 'gsap';
import glslify from 'glslify';

import Style from './Style';

import { getGoal } from '../../../../lib/utils/match.utils';

import vertexShader from '../shaders/ring.vert';
import fragmentShader from '../shaders/badge.frag';

export default class Badge extends Style {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Badge';

		this.settings   = {
			asset         : '',
			offset        : 0.25,
			size          : 40,
			dist          : 20,
			distAngle     : 0,
			outline       : false,
			thickness 		: 0.3,
			colorIdx      : 0,
			animation 		: 'COMPLEX',
			...this.settings
		};

		this.vec  = new THREE.Vector3();
		this.pos  = new THREE.Vector3();
		this.fwd  = new THREE.Vector3();
		this.nml  = new THREE.Vector3();
		this.up   = new THREE.Vector3(0, 1, 0);

		this.loader = new THREE.TextureLoader();
		this.lastAsset = null;
		this.lastTeam = null;

		this.tween = {
			...this.tween
		};

		this.initData(data);
		this.initMesh();
		this.initGUI();
		this.updatePositions();
		this.updateColors();
	}

	initMesh() {
		const { index } = this;
		const { outline, thickness } = this.settings;

		const geometry = new THREE.PlaneGeometry(1, 1, 4, 4);

		const material = new THREE.ShaderMaterial({
			uniforms: {
				uColor      	: { value: new THREE.Color(1, 1, 1) },
				uBillboard  	: { value: 1 },
				uTexture    	: { value: null },
				uTextureSize	: { value: new THREE.Vector2() },
				uOutline    	: { value: outline },
				uThickness		: { value: thickness },
				uFactor  			: { value: new THREE.Vector4(0.0, 0.0, 0.0, index) },
				uMask  				: { value: new THREE.Vector4(0.0, 0.0, 1.0, 0.0) },
			},
			vertexShader,
			fragmentShader,
			transparent: true,
      depthWrite: false,
			// side: THREE.DoubleSide,
		});

		const mesh = new THREE.Mesh(geometry, material);

		this.object3D = mesh;
	}

	updatePositions() {
		const { path, object3D, texture, tween } = this;
		const { vec, pos, fwd, nml, up } = this;
		const { offset, size, dist, distAngle } = this.settings;

		const aspect = (texture) ? texture.image.width / texture.image.height : 1;

		const scale = size;
		const u = offset;

		path.curve.getPointAt(u, pos);
		path.curve.getTangentAt(u, fwd);

		// normal
		nml.copy(fwd).cross(up);

		// rotated around forward
		vec.copy(nml);
		vec.applyAxisAngle(fwd, -distAngle);
		vec.setLength(dist);
		vec.add(pos);

		object3D.position.copy(vec);
		object3D.scale.set(scale * aspect, scale, scale);
	}

	updateColors(skipUpdateGUI) {
		const { object3D, guiColorRadio } = this;
		const { outline, thickness, colorIdx } = this.settings;

		this.updateAssets();

		const goal  = getGoal();
		const team  = goal.teamDO;
		const kit   = team.kits[goal.kit];
		const lng   = kit.colors.length;
		const hex   = kit.colors[colorIdx % lng];

		object3D.material.uniforms.uOutline.value = outline;
		object3D.material.uniforms.uThickness.value = thickness;
		object3D.material.uniforms.uColor.value.setStyle(hex);

		if (!skipUpdateGUI) this.updateGUIColorRadio();
	}

	updateAssets() {
		const { guiAssets, folder, settings, object3D, texture, lastAsset, lastTeam } = this;
		const { asset } = this.settings;

		const goal = getGoal();
		const team = goal.teamDO;
		const options = {};

		// skip it nothing changed
		if (asset == lastAsset && team == lastTeam) return;

		// remove old
		if (guiAssets) folder.remove(guiAssets);
		if (texture) texture.dispose();
		if (object3D) object3D.material.uniforms.uTexture.value = null;

		team.badges.forEach(badge => {
			options[badge] = badge;
		});

		if (folder) this.guiAssets = folder.addInput(settings, 'asset', { options, index: 0 }).on('change', this.onAssetChange.bind(this));

		// set initial
		settings.asset = options[asset] || Object.values(options)[0];
		if (settings.asset) this.onAssetChange();

		// store old
		this.lastAsset = asset;
		this.lastTeam = team;
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	show() {
		this.offsetShowStarted = false;
		this.offsetHideStarted = false;
		this.offsetShowComplete = false;
		this.object3D.material.uniforms.uMask.value.set(0.0, 0.0, 0.0, 0.0);

		this.offsetShow();

		return super.show();
	}

	onShowUpdate(t) {
		const { offset } = this.settings;

		// only call show when t reaches offset
		// if (this.offsetShowStarted) return;
		// if (!t || t < offset) return;

		// this.offsetShow();
	}

	offsetShow() {
		this.offsetShowStarted = true;

		const { animation } = this.settings;
		const { uMask, uFactor } = this.object3D.material.uniforms;

		const timeline = gsap.timeline({ onComplete: () => this.onOffsetShowComplete() });
		let frame = 0;
		let rnd;

		if (animation == 'SIMPLE') {
			timeline.set(uMask.value,  	{ x: 0.0, y: 0.0, z: 0.0 }, 0);
			timeline.to(uMask.value, 		{ y: 1.0, duration: 0.8, ease: 'quad.out' }, 0.2);
			timeline.to(uMask.value, 		{ z: 1.0, duration: 1.0, ease: 'back.out' }, 0.4);
		}

		if (animation == 'COMPLEX') {
			timeline.set(uMask.value,  	{ x: 0.0, y: 0.0, z: 0.0 }, 0);
			timeline.to(uMask.value, 		{ x: 1.0, duration: 0.8, ease: 'quad.out' }, 0);
			timeline.to(uMask.value, 		{ y: 1.0, duration: 0.8, ease: 'quad.out' }, 0.2);
			timeline.to(uMask.value, 		{ z: 1.0, duration: 1.0, ease: 'back.out' }, 0.8);

			rnd = Math.random() > 0.5 ? 0.5 : 0.3;

			timeline.set(uFactor.value, { x: 0.1, y: 0.4 }, 0);
			timeline.to(uFactor.value,  { y: rnd, duration: 0.8, ease: 'linear.out', onUpdate: () => {
				if (frame > 20) {
					uFactor.value.x += 0.02;
					uFactor.value.z += 0.05;
					frame = 0;
				}
				frame++;
			} }, 0);
		}

		this.timelineShow = timeline;

		return timeline;
	}

	onOffsetShowComplete() {
		this.offsetShowComplete = true;
		if (this.offsetHideStarted) this.offsetHide();
	}

	hide() {
		this.offsetHideStarted = false;
		return super.hide();
	}

	onHideUpdate(t) {
		const { offset } = this.settings;

		// only call show when t reaches offset
		if (this.offsetHideStarted) return;
		if (!t || t < offset) return;

		this.offsetHide();
	}

	offsetHide() {
		this.offsetHideStarted = true;
		if (!this.offsetShowComplete) return;

		const { animation } = this.settings;
		const { uMask, uFactor } = this.object3D.material.uniforms;

		const timeline = gsap.timeline();

		timeline.set(uMask.value,  	{ x: 0.4, y: 1.0, z: 0.4 }, 0);
		timeline.to(uMask.value, 		{ x: 0.0, duration: 0.8, ease: 'quad.in' }, 0.0);
		timeline.to(uMask.value, 		{ y: 0.0, duration: 0.8, ease: 'quad.in' }, 0.0);
		timeline.to(uMask.value, 		{ z: 0.0, duration: 0.4, ease: 'linear.in' }, 0.0);

		this.timelineHide = timeline;

		return timeline;
	}

	dispose() {
		this.timelineShow?.kill();
		this.timelineHide?.kill();

		super.dispose();
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

		const animOptions = {
			SIMPLE 	: 'SIMPLE',
			COMPLEX : 'COMPLEX',
		};

    if (!folder) return;

		// remove default children
		while (folder.children.length) folder.remove(folder.children[0]);

		folder.addInput(settings, 'offset',       { min: 0, max: 1            }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'size',         { min: 1, max: 200, step: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'dist',         { min: 1, max: 100 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'distAngle',    { min: -Math.PI, max: Math.PI }).on('change', this.updatePositions.bind(this));

		folder.addInput(settings, 'outline').on('change', this.updateColors.bind(this));
		folder.addInput(settings, 'thickness', { min: 0, max: 1 }).on('change', this.updateColors.bind(this));
		folder.addInput(settings, 'animation', 		{ options: animOptions });

		return folder;
	}

	onAssetChange(e) {
		const { loader, object3D } = this;
		const { asset } = this.settings;

		const url = `/badges/${asset}.png`;

		loader.load(url, (texture) => {
			// texture.minFilter = THREE.LinearFilter;

			object3D.material.uniforms.uTexture.value = texture;
			object3D.material.uniforms.uTextureSize.value.set(texture.image.width, texture.image.height);

			this.texture = texture;
			this.updatePositions();
		});
	}

}
