import MatchDO from '../../model/MatchDO.js';

const _matches = [];
let _goal;

export const initMatches = async (data) => {
  let total = data.length;
  let loaded = 0;

  return new Promise((resolve) => {
    data.forEach(async (item, index) => {
      const match = new MatchDO(item, index);
      await match.loadGoals();
      _matches.push(match);
      loaded++;
      if (loaded >= total) resolve();
    });
  });
};

export const getLatestMatch = () => {
  let subset;
  // filter only finished matches
  subset = _matches.filter((match) => match.done);
  // sort by most recent
  subset.sort((a, b) => new Date(b.date) - new Date(a.date));

  return subset[0];
};

export const getGroups = () => {
  let groups = [];
  groups.push(
    _matches.filter((match) => {
      return match.round === 'A';
    })
  );
  groups.push(
    _matches.filter((match) => {
      return match.round === 'B';
    })
  );
  groups.push(
    _matches.filter((match) => {
      return match.round === 'C';
    })
  );
  groups.push(
    _matches.filter((match) => {
      return match.round === 'D';
    })
  );
  groups.push(
    _matches.filter((match) => {
      return match.round === 'E';
    })
  );
  groups.push(
    _matches.filter((match) => {
      return match.round === 'F';
    })
  );
  groups.push(
    _matches.filter((match) => {
      return match.round === 'G';
    })
  );
  groups.push(
    _matches.filter((match) => {
      return match.round === 'H';
    })
  );
  // sort by date, oldest first
  groups.map((group) => group.sort((a, b) => new Date(a.date) - new Date(b.date)));
  return groups;
};

export const getSixteen = () => {
  const sequence = [49, 50, 53, 54, 51, 52, 55, 56];
  const result = [];
  sequence.forEach((id) => {
    result.push(_matches.find((match) => match.id === id));
  });
  return result;
};

export const getQuarters = () => {
  const sequence = [57, 58, 59, 60];
  const result = [];
  sequence.forEach((id) => {
    result.push(_matches.find((match) => match.id === id));
  });
  return result;
};

export const getSemis = () => {
  const sequence = [61, 62];
  const result = [];
  sequence.forEach((id) => {
    result.push(_matches.find((match) => match.id === id));
  });
  return result;
};

export const getFinal = () => {
  const sequence = [64];
  const result = [];
  sequence.forEach((id) => {
    result.push(_matches.find((match) => match.id === id));
  });
  return result;
};

export const getMatch = (id) => {
  return _matches.find((m) => m.id == id);
};

export const getMatches = () => {
  return _matches;
};

export const getPrevMatch = (id) => {
  let subset;
  // filter only finished matches
  subset = _matches.filter((match) => match.done);
  // sort by most recent
  subset.sort((a, b) => new Date(b.date) - new Date(a.date));
  // find index of given id
  const index = subset.findIndex((match) => match.id == id);

  return index > 0 ? subset[index - 1] : false;
};

export const getNextMatch = (id) => {
  let subset;
  // filter only finished matches
  subset = _matches.filter((match) => match.done);
  // sort by most recent
  subset.sort((a, b) => new Date(b.date) - new Date(a.date));
  // find index of given id
  const index = subset.findIndex((match) => match.id == id);

  return index < _matches.length - 1 ? subset[index + 1] : false;
};

// when called without an argument, return the stored _goal
export const getGoal = (slug = null) => {
  if (!slug) return _goal;

  for (let i = 0; i < _matches.length; i++) {
    _goal = _matches[i].goals.find((goal) => goal.slug == slug);
    if (_goal) return _goal;
  }
};

// store goal for convenience
export const setGoal = (value) => {
  _goal = value;
};
