import * as THREE from 'three';
import autoBind from 'auto-bind';
import { gsap } from 'gsap';

import GLApp from '../../../webgl/GLApp';

import { getGUI } from '../../../lib/utils/gui.utils';
import { getGoal } from '../../../lib/utils/match.utils';
import { breakpoints } from '../../../lib/mediaQueries';

import Pitch from './pitch/Pitch';
import Paths from './paths/Paths';

export const CAMERA_TOP = 'camera-top';
export const CAMERA_FREE = 'camera-free';
export const CAMERA_CENTER = 'camera-center';

class Trajectory {
  constructor() {
    autoBind(this);
    this.initiated = false;
  }

  init(page) {
    if (this.initiated) return;
    this.initiated = true;
    this.page = page;

    this.dummy = new THREE.Object3D();

    this.initThree();
    this.initPitch();
    this.initPaths();
    this.initLights();
  }

  initThree() {
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 10000);
    this.camera.position.set(0, 100, 1000);

    this.clock = new THREE.Clock();
  }

  initPitch() {
    this.pitch = new Pitch(this);
    this.pitch.object3D.rotation.x = Math.PI * -0.5;
    this.scene.add(this.pitch.object3D);
  }

  initPaths() {
    this.paths = new Paths(this);
    this.scene.add(this.paths.object3D);
  }

  initLights() {
    this.lights = [];
    let light;

    light = new THREE.DirectionalLight(0xFFFFFF, 0.3);
    light.position.set(1, 1, 0);
    this.lights.push(light);

    light = new THREE.DirectionalLight(0xFFFFFF, 0.3);
    light.position.set(0, -1, 1);
    this.lights.push(light);

    light = new THREE.AmbientLight(0xFFFFFF, 0.6);
    this.lights.push(light);

    this.lights.forEach(light => this.scene.add(light));
  }

  initData() {
    const goal = getGoal();
    if (!goal) return;

    const sign = goal.leftSide ? 1 : -1;

    this.storedCamera = {
      free: {
        position  : new THREE.Vector3(660, 220, 320),
        quaternion: new THREE.Quaternion(-0.12, 0.52, 0.07, 0.83),
        target    : new THREE.Vector3(0, 0, 0),
      },
      center: {
        position  : new THREE.Vector3(100 * sign, 200, 0),
        quaternion: new THREE.Quaternion(-0.1814, 0.6834 * sign, 0.1814 * sign, 0.6834),
        target    : new THREE.Vector3(250 * -sign, 0, 0),
      }
    };

    this.restoreCamera(goal);
    this.paths.reload(goal);
  }

  addListeners() {
    window.addEventListener('resize', this.resize);
  }

  removeListeners() {
    window.removeEventListener('resize', this.resize);
  }

  restoreCamera(goal) {
    if (!goal.camera) return;

    const { position: p, quaternion: q, target: t } = goal.camera;

    this.storedCamera.free.position.set(p.x, p.y, p.z);
    this.storedCamera.free.quaternion.set(q.x, q.y, q.z, q.w);
    this.storedCamera.free.target.set(t.x, t.y, t.z);

    this.moveCamera(CAMERA_FREE, true);
  }

  animate() {
    this.update();
    this.draw();

    this.raf = requestAnimationFrame(this.animate);
  }

  // ---------------------------------------------------------------------------------------------
  // PUBLIC
  // ---------------------------------------------------------------------------------------------

  update() {
    if (this.orbit?.enabled) this.orbit.update();
  }

  draw() {
    GLApp.renderer.render(this.scene, this.camera);
  }

  preShow() {
    this.scene.visible = false;
  }

  show(onComplete) {
    this.initData();
    this.addListeners();
    this.animate();
    this.resize();

    this.scene.visible = true;
    this.dualShow(onComplete);
  }

  // show once -> change camera -> show again
  dualShow(onComplete) {
    this.showCamera(CAMERA_CENTER);
    this.paths.show(() => {
      this.showCamera(CAMERA_FREE);
      this.paths.showHide(onComplete);
    });
  }

  stop() {
    this.paths.clear();

    cancelAnimationFrame(this.raf);
    this.removeListeners();
  }

  showCamera(label) {
    const { camera, storedCamera } = this;

    // dry cut to camera position
    this.moveCamera(label, true);
    // stop existing animation
    if (this.timeline) this.timeline.kill();

    if (label == CAMERA_CENTER) {
      const posA = storedCamera.center.position.clone();
      const tgtA = storedCamera.center.target.clone();

      // vecA between position and target
      const vecA = posA.clone().subVectors(posA, tgtA);
      // vecB: zoomed out vecA
      const vecB = vecA.clone().setLength(vecA.length() * 1.1);
      // add target back
      vecA.add(tgtA);
      vecB.add(tgtA);

      camera.position.copy(vecB);
      this.timeline = gsap.timeline({ onUpdate: () => camera.lookAt(tgtA) });
      this.timeline.to(camera.position,  { x: vecA.x, y: vecA.y, z: vecA.z, duration: 4, ease: 'quad.out' }, 0);
    }
    if (label == CAMERA_FREE) {
      const posA = storedCamera.free.position.clone();
      const tgtA = storedCamera.free.target.clone();

      const vec0 = new THREE.Vector2();
      // vecA between position and target
      const vecA = posA.clone().subVectors(posA, tgtA);
      // vecB: zoomed out vecA
      const vecB = vecA.clone().setLength(vecA.length() * 0.9);
      // vecC/vecD: shadow of vecB/vecA on the pitch
      const vecC = vec0.clone().set(vecB.x, vecB.z);
      const vecD = vec0.clone().set(vecA.x, vecA.z);
      // vecE/vecF: vecC/vecD rotated around center
      const vecE = vecC.clone().rotateAround(vec0,  0.05);
      const vecF = vecD.clone().rotateAround(vec0, -0.05);
      // vecG/vecH: initial/final rotation
      const vecG = posA.clone().set(vecE.x, vecB.y, vecE.y).add(tgtA);
      const vecH = posA.clone().set(vecF.x, vecA.y, vecF.y).add(tgtA);

      camera.position.copy(vecG);
      this.timeline = gsap.timeline({ onUpdate: () => camera.lookAt(tgtA) });
      this.timeline.to(camera.position,  { x: vecH.x, y: vecH.y, z: vecH.z, duration: 4, ease: 'quad.out' }, 0);
    }
  }

  moveCamera(label, immediate = false) {
    const { camera, scene, orbit, dummy, storedCamera } = this;
    const center = new THREE.Vector3();
    const tween = { value: 0 };
    const duration = immediate ? 0 : 0.4;

    const onUpdate = () => {
      camera.quaternion.slerpQuaternions(camera.lastQuaternion, dummy.quaternion, tween.value);
    };

    const onComplete = () => {
      if (orbit) orbit.enabled = true;
    };

    if (label == CAMERA_TOP) {
      dummy.position.set(0, 1000, 0);
      dummy.lookAt(center);
      dummy.quaternion.conjugate();
      if (orbit) orbit.target.copy(scene.position);
    }
    else if (label == CAMERA_CENTER) {
      dummy.position.copy(storedCamera.center.position);
      dummy.quaternion.copy(storedCamera.center.quaternion);
      if (orbit) orbit.target.copy(storedCamera.center.target);
    }
    else {
      dummy.position.copy(storedCamera.free.position);
      dummy.quaternion.copy(storedCamera.free.quaternion);
      if (orbit) orbit.target.copy(storedCamera.free.target);
    }

    camera.lastQuaternion = camera.quaternion.clone();
    if (orbit) orbit.enabled = label != CAMERA_TOP;

    const { position: dp } = dummy;

    gsap.to(camera.position, { x: dp.x, y: dp.y, z: dp.z, duration, ease: 'power2.out' });
    gsap.to(tween, { value: 1, duration, ease: 'power2.out', onUpdate, onComplete });
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  resize(vw, vh, editMode = false) {
    if (!vw || vw instanceof Event) vw = window.innerWidth;
    if (!vh) vh = window.innerHeight;

    // override GLApp.resize
    GLApp.renderer.setSize(vw, vh);

    this.camera.zoom = 1;
    if (vw < breakpoints.wide) this.camera.zoom = 0.75;
    if (vw < breakpoints.desktop) this.camera.zoom = 0.60;
    if (editMode) this.camera.zoom = 1;

    this.camera.aspect = vw / vh;
    this.camera.updateProjectionMatrix();

    this.pitch.resize();
  }
}

export default new Trajectory();
