import * as THREE from 'three';
import { gsap } from 'gsap';

import { DOT_RADIUS } from './Menu';

export default class MenuDot {

  constructor({ arc, index, extra, goal }) {
    this.arc = arc;
    this.extra = extra;
    this.parent = arc || extra;
    this.interactive = this.parent.interactive;

    this.index = index;
    this.goal = goal;

    this.radius = index ? DOT_RADIUS * 0.8 : DOT_RADIUS;
    this.object3D = new THREE.Object3D();
    this.timeline = gsap.timeline({ autoRemoveChildren: true });

    this.colorOut = this.parent.colorOut;
    this.colorOver = this.parent.colorOver;

    this.scaleOut = 0.8;
    this.scaleOver = 1.0;

    this.initMesh();
  }

  initMesh() {
    const { radius, object3D, colorOut, colorOver } = this;
    const { scaleOut, scaleOver } = this;
    let geometry, material, mesh, line, edges;

    // stroke
    geometry = new THREE.SphereGeometry(radius, 16);
    material = new THREE.MeshBasicMaterial({ color: colorOut, side: THREE.BackSide, transparent: true });
    mesh = new THREE.Mesh(geometry, material);
    this.object3D.add(mesh);
    this.strokeMesh = mesh;

    // fill / hover
    geometry = new THREE.SphereGeometry(radius, 16);
    material = new THREE.MeshBasicMaterial({ color: 0x000000 });
    mesh = new THREE.Mesh(geometry, material);
    mesh.scale.set(scaleOut, scaleOut, 1);
    this.object3D.add(mesh);
    this.hoverMesh = mesh;

    // hit area
    const hitScale = this.extra ? 2.0 : 1.2;

    geometry = new THREE.SphereGeometry(radius * hitScale, 16);
    material = new THREE.MeshBasicMaterial({ color: 0xFF0000, visible: false });
    mesh = new THREE.Mesh(geometry, material);
    this.object3D.add(mesh);
    this.hitArea = mesh;

    // add to interactive
    this.hitArea.arc = this.arc;
    this.hitArea.dot = this;
    this.interactive.objects.push(this.hitArea);
  }

  dispose() {
    const idx = this.interactive.objects.indexOf(this.hitArea);
    if (idx > -1) this.interactive.objects.splice(idx, 1);

    this.timeline.kill();
    this.object3D.clear();

    this.hitArea.arc  = null;
    this.hitArea.dot  = null;

    this.parent       = null;
    this.arc          = null;
    this.extra        = null;
    this.goal         = null;
    this.object3D     = null;
    this.hoverMesh    = null;
    this.strokeMesh   = null;
    this.hitArea      = null;
    this.interactive  = null;
    this.timeline     = null;
  }

  // ---------------------------------------------------------------------------------------------
  // EVENT HANDLERS
  // ---------------------------------------------------------------------------------------------

  onInteractiveOver(e) {
    const { hoverMesh, timeline, colorOver, scaleOver } = this;

    hoverMesh.material.color.copy(colorOver);

    timeline.clear();
    timeline.to(hoverMesh.scale, { x: scaleOver, y: scaleOver, z: scaleOver, duration: 0.5, ease: 'back.out' }, 0);
    timeline.play(0);
  }

  onInteractiveOut(e) {
    const { hoverMesh, timeline, scaleOut } = this;

    hoverMesh.material.color.setHex(0x000000);

    timeline.clear();
    timeline.to(hoverMesh.scale, { x: scaleOut, y: scaleOut, z: scaleOut, duration: 0.01, ease: 'linear' }, 0);
    timeline.play(0);
  }
}
