import EventEmitter from 'events';
import parseHTML from '../../lib/utils/parseHTML';
import autoBind from 'auto-bind';
import gsap from 'gsap';
import './bt-toggle-view.scss';
import isTouchDevice from '../../lib/utils/isTouchDevice.js';
import gsap from 'gsap';
import tmpl from './bt-toggle-view.pug';

export default class ButtonToggleView {
  el = undefined;
  closed = false;
  constructor() {
    autoBind(this);
    this.events = new EventEmitter();
  }

  render() {
    this.el = parseHTML(tmpl());
    this.square = this.el.querySelector('.square');
    this.type = this.el.querySelector('svg');
    this.addEvents();
    return this.el;
  }

  addEvents() {
    this.el.addEventListener('click', this.onClick);
    if (isTouchDevice()) return;
    this.el.querySelector('.square').addEventListener('mouseover', this.onEnter);
    this.el.querySelector('.square').addEventListener('mouseout', this.onLeave);
  }

  onEnter() {
    if (isTouchDevice()) return;
    this.overTL = gsap.timeline({});
    this.overTL.to(this.square, {
      scaleX: 5,
      duration: 0.15,
      ease: 'expo.out',
      transformOrigin: 'top right',
    });
  }

  onLeave() {
    if (isTouchDevice()) return;
    if (this.overTL) {
      this.overTL.clear();
      this.overTL.kill();
    }
    gsap.to(this.square, {
      scaleX: 1,
      duration: 0.15,
      ease: 'expo.out',
      transformOrigin: 'top right',
    });
  }

  onClick() {
    this.events.emit('click');
  }

  setClosed(isClosed) {
    if (isClosed) {
      this.el.classList.add('close');
    } else {
      this.el.classList.remove('close');
    }
    this.closed = isClosed;
  }
}
