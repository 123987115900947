export default class TeamDO {
  constructor(json) {
    this.index = 0;
    this.name = '';
    this.slug = '';
    this.kits = [];
    this.badges = [];

    this.parse(json);
  }

  parse(json) {
    this.index = json.index;
    this.name = json.name;
    this.slug = json.slug;
    this.kits = json.kits;
    this.flag = json.flag;
    this.done = json.done;
    this.badges = json.badges || [];
  }
}
