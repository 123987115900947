import * as THREE from 'three';
import { gsap } from 'gsap';
import eases from 'eases';

import { parabola, lerp } from '../../../../lib/utils/math.utils';

import Style from './Style';

export default class BaseRibbon extends Style {
	
	constructor(path, index, data) {
		super(path, index);

		this.settings = {
			segments 		: 7,
			height 			: 60,
			taperEdge 	: 1.00,
			taperFreq 	: 0.05,
			taperY 			: 8,
			rotXFreq 		: 0.02,
			rotZFreq 		: 0.05,
			...this.settings
		};

		this.vec0 = new THREE.Vector3();
		this.vec1 = new THREE.Vector3();
		this.mid0 = new THREE.Vector3();
		this.mid1 = new THREE.Vector3();

		this.vecA = new THREE.Vector3();
		this.vecB = new THREE.Vector3();
		this.vec 	= new THREE.Vector3();
		this.fwd 	= new THREE.Vector3();
		this.mid 	= new THREE.Vector3();
		this.up 	= new THREE.Vector3(0, 1, 0);

		this.initGeometry();
	}

	initData(data) {
		if (!data) return;
		this.preventUpdate = true;

		super.initData(data);
		this.onGeometryChange();

		this.preventUpdate = false;
	}

	initGeometry() {
		const { segments, height } = this.settings;

		this.geometry = new THREE.PlaneGeometry(segments * height, height, segments);
		// store the OG geometry
		this.ogeometry = this.geometry.clone();
	}

	updatePositions() {
		const { geometry, ogeometry, preventUpdate } = this;
		const { taperEdge, taperFreq, rotXFreq, rotZFreq, segments } = this.settings;
		const taperY = this.tween.taperY || this.settings.taperY;
		let taper, rotX, rotZ, t, u;
		
		if (!geometry) return;
		if (preventUpdate) return;

		// restore OG geometry
		geometry.copy(ogeometry);

		for (let i = 0; i < segments + 1; i++) {
			t = i / segments;
			u = parabola(t, 2);

			taper = this.noise2D(i * taperFreq * 2, taperY) * 0.5 + 0.5;
			// taper start from 0, end down to taperEdge
			taper *= t < 0.5 ? u : lerp(1, u, taperEdge);
			rotX = this.noise2D(t * rotXFreq, 99);
			rotZ = this.noise2D(t * rotZFreq,  1) * 1.5;

			this.transformSegment(i, { taper, rotX, rotZ });
		}

		geometry.computeVertexNormals();

		// store the transformed geometry
		this.tgeometry = geometry.clone();

		return true;
	}

	transformSegment(index, { taper = 1, rotX = 0, rotZ = 0 }) {
		const { geometry, curve, vecA, vecB, vec, mid, fwd, up } = this;
		const { segments } = this.settings;
		const idxA = index;
		const idxB = index + segments + 1;
		const nxtA = index + 1;

		const range = this.tween.range || this.settings.range;
		const height = this.tween.height || this.settings.height;

		let t = index / segments;
		// adjust to range
		t = t * (range.max - range.min) + range.min;

		curve.getPoint(t, vec);
		
		vecA.copy(vec);
		vecB.copy(vec);

		vecA.y -= height * 0.5;
		vecB.y += height * 0.5;

		geometry.attributes.position.setXYZ(idxA, vecA.x, vecA.y, vecA.z);
		geometry.attributes.position.setXYZ(idxB, vecB.x, vecB.y, vecB.z);

		// segment A
		vecA.set(
			geometry.attributes.position.getX(idxA),
			geometry.attributes.position.getY(idxA),
			geometry.attributes.position.getZ(idxA),
		);
		// segment B
		vecB.set(
			geometry.attributes.position.getX(idxB),
			geometry.attributes.position.getY(idxB),
			geometry.attributes.position.getZ(idxB),
		);

		// axis of rotation: forward
		if (idxA < segments) {
			fwd.set(
				geometry.attributes.position.getX(nxtA),
				geometry.attributes.position.getY(nxtA),
				geometry.attributes.position.getZ(nxtA),
			);
			fwd.sub(vecA).normalize();
		}

		// middle of A and B with origin at 0
		vec.subVectors(vecA, vecB).multiplyScalar(0.5);
		// middle of the segment
		mid.subVectors(vecA, vec);
		
		// rotate vector with origin at 0 (forward)
		vec.applyAxisAngle(fwd, rotX);

		// set axis to perpendicular
		fwd.cross(up);
		// rotate vector with origin at 0 (perpendicular)
		vec.applyAxisAngle(fwd, rotZ);

		// taper
		vec.multiplyScalar(taper);
		// set vertex A to middle + rotated vector
		vecA.addVectors(mid, vec);
		// set vertex B to middle - rotated vector
		vecB.subVectors(mid, vec);

		geometry.attributes.position.setXYZ(idxA, vecA.x, vecA.y, vecA.z);
		geometry.attributes.position.setXYZ(idxB, vecB.x, vecB.y, vecB.z);

		geometry.attributes.position.needsUpdate = true;
	}

	updatePlayhead(ini = 0, end = 1, isHidding = false) {
		const { vec0, vec1, mid0, mid1, vecA, vecB, mid, vec } = this;
		const { segments } = this.settings;
		const { geometry, tgeometry } = this;

		const position = geometry.attributes.position;
		const tposition = tgeometry.attributes.position;

		const endSeg 	 = end * segments;
		const endFloor = Math.floor(endSeg);
		const endFract = endSeg % 1;

		const iniSeg 	 = ini * segments;
		const iniFloor = Math.floor(iniSeg);
		const iniFract = iniSeg % 1;

		let t, u, fract;

		for (let i = 0; i < segments + 1; i++) {
			const idxA = i;
			const idxB = i + segments + 1;

			const iniA = iniFloor;
			const iniB = iniFloor + segments + 1;

			const endA = endFloor;
			const endB = endFloor + segments + 1;


			// define mid points/vectors for each segment
			// idxTA: can be iniA or endA
			// idxTB: can be iniB or endB
			const setMids = (idxTA, idxTB) => {
				vecA.set(tposition.getX(idxTA + 0), tposition.getY(idxTA + 0), tposition.getZ(idxTA + 0));
				vecB.set(tposition.getX(idxTB + 0), tposition.getY(idxTB + 0), tposition.getZ(idxTB + 0));
				vec0.subVectors(vecB, vecA);
				mid0.copy(vec0).multiplyScalar(0.5).add(vecA);

				vecA.set(tposition.getX(idxTA + 1), tposition.getY(idxTA + 1), tposition.getZ(idxTA + 1));
				vecB.set(tposition.getX(idxTB + 1), tposition.getY(idxTB + 1), tposition.getZ(idxTB + 1));
				vec1.subVectors(vecB, vecA);
				mid1.copy(vec1).multiplyScalar(0.5).add(vecA);

				mid.subVectors(mid1, mid0);
			};

			const setPos = () => {
				// rotate edge
				vec1.applyAxisAngle(mid.clone().normalize(), Math.PI * 0.5 * (1 - fract));

				t = eases.quadInOut(fract);
				// t = 1 - t;
				u = isHidding ? 1 - t : t;

				position.setX(idxA, mid0.x + mid.x * fract - lerp(vec0.x, vec1.x, t) * 0.5 * u);
				position.setY(idxA, mid0.y + mid.y * fract - lerp(vec0.y, vec1.y, t) * 0.5 * u);
				position.setZ(idxA, mid0.z + mid.z * fract - lerp(vec0.z, vec1.z, t) * 0.5 * u);

				position.setX(idxB, mid0.x + mid.x * fract + lerp(vec0.x, vec1.x, t) * 0.5 * u);
				position.setY(idxB, mid0.y + mid.y * fract + lerp(vec0.y, vec1.y, t) * 0.5 * u);
				position.setZ(idxB, mid0.z + mid.z * fract + lerp(vec0.z, vec1.z, t) * 0.5 * u);
			}


			// snap to segment
			if (i > iniFloor && i <= endFloor) {
				position.setXYZ(idxA, tposition.getX(idxA), tposition.getY(idxA), tposition.getZ(idxA));
				position.setXYZ(idxB, tposition.getX(idxB), tposition.getY(idxB), tposition.getZ(idxB));
			}
			// lerp first / last fractions
			else if (i <= iniFloor) {
				fract = iniFract;
				setMids(iniA, iniB);
				setPos();
			}
			else if (i > endFloor) {
				fract = endFract;
				setMids(endA, endB);
				setPos();
			}
		}

		geometry.attributes.position.needsUpdate = true;
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

		return folder;
	}

	onGeometryChange() {
		const { segments, height } = this.settings;

		if (this.geometry)  this.geometry.dispose();
		if (this.ogeometry) this.ogeometry.dispose();
		if (this.tgeometry) this.tgeometry.dispose();

		this.geometry = new THREE.PlaneGeometry(segments * height, height, segments);
		this.ogeometry = this.geometry.clone();

		this.object3D.geometry = this.geometry;

		this.updatePositions();
	}
}
