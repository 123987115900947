export const breakpoints = {
  mobile: 480,
  tablet: 900,
  desktop: 1024,
  wide: 1600,
};
let _isMobile = false;

export const setMobileState = (state) => {
  _isMobile = state;
};

export const isMobile = () => {
  return _isMobile;
};

export const from = (breakpoint, cb) => {
  const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);
  if (mediaQuery.matches) {
    // Then trigger an alert
    return cb();
  }
};

export const until = (breakpoint, cb) => {
  const mediaQuery = window.matchMedia(`(max-width: ${breakpoint - 1}px)`);
  if (mediaQuery.matches) {
    // Then trigger an alert
    return cb();
  }
};
