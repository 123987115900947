import * as THREE from 'three';
import glslify from 'glslify';
import { gsap } from 'gsap';

import { map } from '../../../../lib/utils/math.utils';
import { getGoal } from '../../../../lib/utils/match.utils';

import BaseRibbon from './BaseRibbon';
import CustomMaterial from '../materials/CustomMaterial';

import fragmentShader from '../shaders/ribbon-standard.frag';

export default class Ribbon extends BaseRibbon {
	
	constructor(path, index, data) {
		super(path, index);

		this.name = 'Ribbon';

		this.settings = {
			lights 			: true,
			vertical 		: false,
			numColors 	: 2,
			...this.settings
		};

		this.initData(data);
		this.initMesh();
		this.initGUI();
		this.updatePositions();
		this.updateColors();
	}

	initMesh() {
		const { geometry } = this;
		const { numColors, vertical } = this.settings;

		const colors = [];
		for (let i = 0; i < 4; i++) {
			colors.push(new THREE.Color());
		}

		const material = new CustomMaterial({
			uniforms: {
				uColors 		: { value: colors },
				uNumColors 	: { value: numColors },
				uVertical  	: { value: vertical },
				uUseLight  	: { value: 1 },
			},
			// fragmentShader: glslify(require('../shaders/ribbon-standard.frag')),
      fragmentShader,
			flatShading: true,
			side: THREE.DoubleSide,
		});

		const mesh = new THREE.Mesh(geometry, material);

		this.object3D = mesh;
	}

	updateColors() {
		const { uniforms } = this.object3D.material;
		const { numColors, vertical } = this.settings;

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];

		kit.colors.forEach((color, i) => {
			uniforms.uColors.value[i].setStyle(color);
		});

		// uniforms.uNumColors.value = kit.colors.length;
		uniforms.uNumColors.value = Math.min(numColors, kit.colors.length);
		uniforms.uVertical.value = vertical;
	}

	// ---------------------------------------------------------------------------------------------
	// MOTION
	// ---------------------------------------------------------------------------------------------

	onShowUpdate(t) {
		const { tween } = this;
		const { range } = this.settings;
		
		const end = map(t, range.min, range.max, 0, 1);

		this.updatePlayhead(0, end);
	}

	onHideUpdate(t) {
		const { tween } = this;
		const { range } = this.settings;
		
		const ini = map(t, range.min, range.max, 0, 1);
		
		this.updatePlayhead(ini, 1, true);
	}

	// ---------------------------------------------------------------------------------------------
	// GUI
	// ---------------------------------------------------------------------------------------------

	initGUI(parent) {
		const folder = super.initGUI(parent);
		const { settings } = this;

    if (!folder) return;

		folder.addInput(settings, 'segments', 	{ min: 2, max: 100, step: 1 }).on('change', this.onGeometryChange.bind(this));
		folder.addInput(settings, 'height', 		{ min: 1, max: 200, step: 1 }).on('change', this.onGeometryChange.bind(this));

		folder.addInput(settings, 'taperEdge', 	{ min: 0, max: 1 }).on('change', this.updatePositions.bind(this));
		folder.addInput(settings, 'taperFreq', 	{ min: 0, max: 1 }).on('change', this.updatePositions.bind(this));
		// folder.addInput(settings, 'rotXFreq', 	{ min: 0, max: 1 }).on('change', this.updatePositions.bind(this));
		// folder.addInput(settings, 'rotZFreq', 	{ min: 0, max: 1 }).on('change', this.updatePositions.bind(this));
		
		folder.addInput(settings, 'numColors', 	{ min: 0, max: 4, step: 1 }).on('change', this.updateColors.bind(this));
		folder.addInput(settings, 'vertical').on('change', this.updateColors.bind(this));
		folder.addInput(settings, 'lights').on('change', this.onLightsChange.bind(this));
	}

	onLightsChange() {
		this.object3D.material.uniforms.uUseLight.value = this.settings.lights;
	}
}
