import { getMatches } from './match.utils';
import { getTeams } from './team.utils';

let _gui;
let _icons;

export const FOLDER_PATH = 'folder-path';
export const FOLDER_STYLE = 'folder-style';

export const getGUI = () => _gui;

export const setGUI = (value) => _gui = value;

export const getMatchOptions = () => {
	const options = {};
	getMatches().sort((a, b) => a.id - b.id).forEach(match => {
		options[match.name] = match.id;
	});
	return options;
};

export const getTeamOptions = () => {
  const options = {};
  getTeams().forEach(team => {
    options[team.name] = team.slug;
  });
  return options;
};

export const getDetailOptions = () => {
	const options = {
		'' 		 : '',
		'pen.' : 'pen.',
		'o.g.' : 'o.g.',
	};
	return options;
};

export const setIcons = (iconMap) => {
	_icons = iconMap;
};

export const addCustomFolder = (parent, params = {}) => {
	const folder = parent.addFolder(params);
	const el = folder.controller_.view.buttonElement;

	switch (params.type) {
		case FOLDER_PATH: {
			_addListeners(el, params);
			_addIconEye(el);
			_addIconTrash(el);
			break;
		}
		case FOLDER_STYLE: {
			_addListeners(el, params);
			_addIconEye(el);
			_addIconTrash(el);
			break;
		}
	}

	return folder;
};

const _addListeners = (el, params) => {
	el.addEventListener('click', (e) => {
		// only intervene when a 'fa' element is clicked
		if (!e.target.classList.contains('fa')) return true;

		e.preventDefault();
		e.stopPropagation();
		
		if (e.target.classList.contains('fa-eye')) {
			// expect a property/function onVisibleClick
			if (params.onVisibleClick) params.onVisibleClick(!e.target.classList.toggle('disabled'));
		}

		if (e.target.classList.contains('fa-trash')) {
			// expect a property/function onRemoveClick
			if (params.onRemoveClick) params.onRemoveClick();
		}

	}, true);
};

const _addIconEye = (el) => {
	let div, svg;

	div = document.createElement('div');
	div.classList.add('fa', 'fa-eye');
	el.appendChild(div);
	
	// svg = AsyncPreloader.items.get('fa-eye').documentElement;
	svg = _icons.get('fa-eye').documentElement;
	svg = svg.cloneNode(true);
	svg.classList.add('eye');
	div.appendChild(svg);

	svg = _icons.get('fa-eye-slash').documentElement;
	svg = svg.cloneNode(true);
	svg.classList.add('eye-slash');
	div.appendChild(svg);

};

const _addIconTrash = (el) => {
	let div, svg;

	div = document.createElement('div');
	div.classList.add('fa', 'fa-trash');
	el.appendChild(div);
	
	svg = _icons.get('fa-trash').documentElement;
	svg = svg.cloneNode(true);
	div.appendChild(svg);
};

// when expanding a folder, collapse others
export const collapseOthers = (parent, target, expanded) => {
	if (!expanded) return;
	
	parent.children.forEach(child => {
		if (child.expanded == undefined) return;
		child.expanded = child == target;
	});
};
