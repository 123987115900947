export const PI = Math.PI;
export const HALF_PI = Math.PI / 2;
export const TWO_PI = Math.PI * 2;
export const QUARTER_PI = Math.PI / 4;
export const DEG_TO_RAD = Math.PI / 180;
export const RAD_TO_DEG = 180 / Math.PI;

export const clamp = (num, min, max) => {
	if (max < min) {
		const tmax = min;
		min = max;
		max = tmax;
	}

	if (num < min) return min;
	else if (num > max) return max;

	return num;
};

export const lerp = (min, max, amount) => {
	return min + amount * (max - min);
};

export const map = (num, min1, max1, min2, max2, round = false, constrainMin = true, constrainMax = true) => {
	if (constrainMin && num < min1) return min2;
	if (constrainMax && num > max1) return max2;

	const num1 = (num - min1) / (max1 - min1);
	const num2 = (num1 * (max2 - min2)) + min2;
	if (round) return Math.round(num2);
	return num2;
};

export const mod = (n, m) => {
	return ((n % m) + m) % m;
};

export const random = (min, max) => {
	if (Object.prototype.toString.call(min) === '[object Array]') return min[~~(Math.random() * min.length)];

	if (typeof max !== 'number') {
		max = min || 1;
		min = 0;
	}

	return min + Math.random() * (max - min);
};

export const sign = (n) => {
  if (n > 0) return 1;
  else if (n < 0) return -1;
  else return 0;
};

export const rotate2D = (center, point, angle) => {
	const cos = Math.cos(angle);
	const sin = Math.sin(angle);

	return {
		x: (cos * (point.x - center.x)) + (sin * (point.y - center.y)) + center.x,
		y: (cos * (point.y - center.y)) - (sin * (point.x - center.x)) + center.y,
	}
};

// https://iquilezles.org/articles/functions/
export const parabola = (x, k = 1.0) => {
	return Math.pow(4.0 * x * (1.0 - x), k);
}

export const gain = (x, k = 1.0) => {
	const a = 0.5 * Math.pow(2.0 * ((x < 0.5) ? x : 1.0 - x), k);
	return (x < 0.5) ? a : 1.0 - a;
}

export const expImpulse = (x, k = 1.0) => {
  const h = k * x;
  return h * Math.exp(1.0 - h);
}

// random range based on simplex-noise noise2D
export const nrange = (min, max, noise2D, x = 0) => {
  const n = (noise2D) ? noise2D(x, 99) * 0.5 + 0.5 : Math.random();
  return min + n * (max - min);
};

export const npick = (arr, noise2D, x = 0) => {
  const n = (noise2D) ? noise2D(x, 456) * 0.5 + 0.5 : Math.random();
  return arr[~~(n * arr.length)];
};

// https://github.com/mattdesl/canvas-sketch-util/blob/master/random.js
export const nweighted = (weights, noise2D, x = 0) => {
  weights = weights || [];
  let totalWeight = 0;

  for (let i = 0; i < weights.length; i++) {
    totalWeight += weights[i];
  }

  let random = (noise2D(x, 5781) * 0.5 + 0.5) * totalWeight;

  for (let i = 0; i < weights.length; i++) {
    if (random < weights[i]) return i;
    random -= weights[i];
  }
  return 0;
}
