class ResponsiveScale {
  initialize({ screenMin, screenMax, aspectRatio }) {
    this.screenMin = screenMin;
    this.screenMax = screenMax;
    this.scaleMin = screenMin / screenMax;
    this.scaleMax = 1;
    this.aspectRatio = aspectRatio;
  }

  scale({ scaleMin, scaleMax, screenMin, screenMax, vertical }) {
    let unit = vertical ? window.innerHeight * 0.01 : window.innerWidth * 0.01;
    if (window.innerWidth > screenMin) {
      this.scaleValue =
        scaleMin + ((scaleMax - scaleMin) * (unit * 100 - screenMin)) / (screenMax - screenMin);
    }
  }

  resize() {
    this.scale({
      scaleMin: this.scaleMin,
      scaleMax: this.scaleMax,
      screenMin: this.screenMin,
      screenMax: this.screenMax,
      vertical: false,
    });

    if (window.innerWidth / window.innerHeight >= Math.round(this.aspectRatio * 100) / 100) {
      this.scale({
        scaleMin: this.scaleMin,
        scaleMax: this.scaleMax,
        screenMin: this.screenMin / this.aspectRatio,
        screenMax: this.screenMax / this.aspectRatio,
        vertical: true,
      });
    }
  }

  getValue(value) {
    return (value * this.scaleValue) / window.devicePixelRatio;
  }

  getScale() {
    return this.scaleValue;
  }
}

export default new ResponsiveScale();
