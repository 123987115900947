import ways from 'ways';
import autoBind from 'auto-bind';

import parseHTML from '../../lib/utils/parseHTML';
import { setGoal, getGoal } from '../../lib/utils/match.utils';

import trajectory from './trajectory/Trajectory';

import tmpl from './goal.pug';
import './goal.scss';

class Goal {
  constructor() {
    autoBind(this);
  }

  render() {
    this.el = parseHTML(tmpl());
    document.querySelector('.page').appendChild(this.el);

    trajectory.init(this);
    trajectory.preShow();
  }

  transitionIn(req, done) {
    setGoal(getGoal(req.params.id));

    this.render();
    done();

    // start showHide sequence
    this.introTL = trajectory.show(() => {
      // navigate out when hide complete
      ways.go(`/match/${getGoal().match}`);
    });
  }

  transitionOut(req, done) {
    trajectory.stop();
    done();
  }

  dispose() {}
}

export default new Goal();
