// import dateformat from 'dateformat';

import { DOT_RADIUS, SPHERE_RADIUS, EXTRA_RADIUS } from '../pages/match/menu/Menu';
import { getTeam, getTeams } from '../lib/utils/team.utils';
import GoalDO from './GoalDO';
import TeamDO from './TeamDO';

export default class MatchDO {
  constructor(data, index) {
    this.data = data;
    this.index = index;
    this.name = '';
    this.id = 0;
    this.round = null;
    this.date = null;
    this.result = null;
    this.teams = [];
    this.teamDOs = [];
    this.goals = [];

    // parse
    this.id = data.id;
    this.round = data.round || '';
    this.date = new Date(data.date);
    this.teams = data.teams;
    this.result = data.result;
    this.winner = data.winner;
    this.done = !!data.result;
    this.hasExtra = data.result && !!data.result.extra;
    this.hasShootout = data.result && !!data.result.shootout;

    const padId = `${this.id}`.padStart(2, '0');
    this.name = `${padId} ${this.round} ${this.teams[0]} x ${this.teams[1]}`;

    data.teams.forEach((slug) => {
      this.teamDOs.push(getTeam(slug));
    });
  }

  async loadGoals() {
    let total = this.data.goals.length;
    let loaded = 0;
    return new Promise((resolve) => {
      if (!this.data.goals.length) {
        loaded++;
        if (loaded >= total) {
          this.processGoals();
          resolve();
        }
      }
      this.data.goals.forEach((goal) => {
        window
          .fetch(`/goals/${goal}.json`)
          .then((response) => {
            return response.json();
          })
          .then((resource) => {
            const goal = new GoalDO(this);
            goal.parse(resource);
            this.goals.push(goal);
            loaded++;
            if (loaded >= total) {
              this.processGoals();
              resolve();
            }
          });
      });
    });
  }

  processGoals() {
    // sort by minute + added
    this.goals.sort((a, b) => a.minute - b.minute || a.added - b.added);

    // set goal index i.e. 45+2' (1st half) needs to come before 46' (2nd half)
    this.goals.forEach((goal, index) => (goal.index = index));

    // sort by half minute + added
    this.goals.sort((a, b) => a.halfMinute - b.halfMinute || a.half - b.half || a.added - b.added);
    // set halfIndex for convenience
    this.goals.forEach((goal, index) => (goal.halfIndex = index));

    // assign temporary props required for collision solving
    this.goals.forEach((goal, index) => {
      goal.x = Math.cos(goal.phi) * SPHERE_RADIUS;
      goal.fixed = (!goal.t && !index) || (goal.t == 1 && index == this.goals.length - 1);
      goal.w = goal.fixed ? 0 : 1;
    });

    // nudge overlapping dots
    const solve = () => {
      let a, b, dx, diff;
      const minDist = DOT_RADIUS * 2;

      for (let i = 0; i < this.goals.length; i++) {
        a = this.goals[i];

        for (let j = i + 1; j < this.goals.length; j++) {
          b = this.goals[j];

          dx = b.x - a.x;
          if (!dx) dx = 0.0001;

          if (Math.abs(dx) >= minDist) continue;
          diff = (minDist - dx) / (dx * (a.w + b.w));
          a.x -= a.w * dx * diff;
          b.x += b.w * dx * diff;
        }
      }
    };

    // iterate solve
    for (let i = 0; i < 5; i++) {
      solve();
    }

    // calculate the new t and phi (u and tta)
    this.goals.forEach((goal) => {
      goal.tta = Math.acos(goal.x / SPHERE_RADIUS);
      goal.v = 1 - goal.tta / Math.PI;

      // delete temporary props
      delete goal.x;
      delete goal.w;
      delete goal.fixed;
    });

    // exception penalty shootout
    const dh = EXTRA_RADIUS * Math.PI;
    const dd = (DOT_RADIUS * 4) / dh;
    let count = [0, 0];
    let idxTeam, idxGoal, result;

    this.goals
      .filter((goal) => goal.shootout)
      .forEach((goal, index) => {
        idxTeam = this.teams.indexOf(goal.team);
        idxGoal = count[idxTeam];
        result = this.result.shootout[idxTeam];

        goal.half = goal.team === this.teams[0] ? 1 : 2;

        goal.t = (goal.added - 1 - ~~(result * 0.5)) * dd;
        if (idxTeam) goal.t = 1 - goal.t;

        goal.v = goal.u = goal.t;
      });
  }
}
