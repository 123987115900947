import * as THREE from 'three';

import GLApp from '../../../webgl/GLApp';

export default class MenuLabel {

  constructor(menu) {
    this.menu = menu;
    this.offset = new THREE.Vector2(10, 40);

    this.init();
  }

  init() {
    this.el = document.querySelector('.menu-label');
    this.elPlayer = this.el.querySelector('.player');
    this.elMinute = this.el.querySelector('.minute');
  }

  // parent can be MenuArc or MenuExtra
  show(parent, goal, dot) {
    const { menu, offset } = this;
    const { camera } = menu;

    if (!parent || !parent.object3D) return;
    if (!goal) return;

    const vec = new THREE.Vector3();
    const size = new THREE.Vector3();

    let x, y, ox, oy;

    parent.object3D.updateWorldMatrix(true, false);
    parent.object3D.getWorldPosition(vec);

    // add arc peak
    if (parent.peak) vec.add(parent.peak);
    // or add dot position (extra)
    else if (dot) vec.add(dot.object3D.position);

    vec.project(camera);

    GLApp.renderer.getSize(size);

    this.elPlayer.classList.add('show');
    this.elMinute.classList.add('show');

    const added = goal.added ? `+${goal.added}` : '';
    const detail = goal.detail ? `(${goal.detail})` : '';

    this.elPlayer.innerText = `${goal.player}`;
    this.elMinute.innerText = `${goal.minute}${added}'${detail}`;

    // exception: penalty shootout
    if (goal.shootout) this.elMinute.innerText = `${detail}`;

    x = (vec.x *  0.5 + 0.5) * size.x;
    y = (vec.y * -0.5 + 0.5) * size.y;

    ox = (goal.half == 1) ? -offset.x - this.el.offsetWidth : offset.x - 10;
    oy = (goal.half == 1) ? -offset.y : offset.y - 10;

    // exception: extra time
    if (dot) {
      vec.copy(dot.object3D.position);
      vec.setLength(20);

      // avoid division by zero
      if (!vec.y) vec.y = 0.0001;

      ox = (vec.x > 0) ? vec.x : vec.x - this.el.offsetWidth;
      oy = Math.min(Math.abs(vec.y), 20) * -(vec.y / Math.abs(vec.y));
      if (oy < 0) oy -= this.el.offsetHeight;
    }

    x += ox;
    y += oy;

    this.el.style.left = `${~~x}px`;
    this.el.style.top  = `${~~y}px`;
  }

  hide() {
    this.elPlayer.classList.remove('show');
    this.elMinute.classList.remove('show');

    this.elPlayer.innerText = ``;
    this.elMinute.innerText = ``;
  }
}
