import EventEmitter from 'events';
import parseHTML from '../../lib/utils/parseHTML';
import autoBind from 'auto-bind';
import menu from '../../pages/match/menu/Menu';
import './match-score.scss';
import gsap from 'gsap';
import tmpl from './match-score.pug';
import CoverFlag from '../matches/cover-flag/CoverFlag.js';
import { CustomEase, SlowMo } from 'gsap/all';
import ways from 'ways';

export default class MatchScore {
  el = undefined;
  closed = false;
  constructor({ team, score, visitor }) {
    autoBind(this);
    this.team = team;
    this.score = score;
    this.visitor = visitor;
    this.flag = new CoverFlag(this.team, { big: true, visitor: visitor });
    this.events = new EventEmitter();
  }

  render() {
    this.el = parseHTML(
      tmpl({
        visitor: this.visitor ? 'match-score-visitor' : '',
        name: this.team.slug,
        score: this.score,
      })
    );
    this.el.querySelector('.match-flag').appendChild(this.flag.render());
    this.flagEl = this.el.querySelector('.match-flag');
    this.scoreEl = this.el.querySelector('.score');
    this.teamEl = this.el.querySelector('.team');
    this.flag.intro();
    this.addEvents();
    return this.el;
  }

  addEvents() {
    this.el.addEventListener('click', this.onClick);
  }

  onClick() {
    this.events.emit('click');
    ways.go('/matches');
  }

  transitionIn() {
    let from = this.visitor ? '-10%' : '10%';
    this.introTL = gsap.timeline();
    this.introTL.add(this.flag.intro(2));
    this.introTL.to(this.scoreEl, { duration: 0.1, ease: 'power4.inOut', opacity: 1 }, 0.4);
    this.introTL.fromTo(
      this.scoreEl,
      { x: from },
      { duration: 0.5, ease: 'power4.inOut', x: '0' },
      0.4
    );
    this.introTL.to(this.teamEl, { duration: 0.1, ease: 'power4.inOut', opacity: 1 }, 0.5);
    this.introTL.fromTo(
      this.teamEl,
      { x: from },
      { duration: 0.5, ease: 'power4.inOut', x: '0' },
      0.5
    );
    return this.introTL;
  }

  transitionOut() {
    let easing = CustomEase.create(
      'custom',
      'M0,0 C0,0 0.075,0.619 0.2,0.8 0.245,0.866 0.57,0.966 0.612,0.988 0.649,1.007 1,1 1,1 '
    );
    let from = `${-menu.direction * 30}vh`;
    let from2 = `${-menu.direction * 22}vh`;
    let from3 = `${-menu.direction * 25}vh`;
    this.outroTL = gsap.timeline();
    this.outroTL.to(this.scoreEl, { duration: 2, ease: 'power4.inOut', y: from }, 0);
    this.outroTL.to(this.teamEl, { duration: 2, ease: 'power4.inOut', y: from2 }, 0);
    this.outroTL.to(this.flagEl, { duration: 2, ease: 'power4.inOut', y: from3 }, 0);

    // this.outroTL = gsap.timeline();
    // this.outroTL.to(this.scoreEl, { duration: 0, opacity: 0 }, 0);
    // this.outroTL.to(this.teamEl, { duration: 0, opacity: 0 }, 0);
    // this.outroTL.add(this.flag.outro());
    return this.outroTL;
  }
}
