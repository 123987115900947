import * as THREE from 'three';
import glslify from 'glslify';
import { gsap } from 'gsap';
import eases from 'eases';
import chroma from 'chroma-js';

import { BASE_DURATION } from './Style';
import { getGoal } from '../../../../lib/utils/match.utils';
import { parabola, gain, npick, nweighted, clamp, map } from '../../../../lib/utils/math.utils';

import vertexShader from '../shaders/dot.vert';
import fragmentShader from '../shaders/dot.frag';

export default class SubDots {
	
	constructor(parent, path, index, settings) {
		this.parent = parent;
		this.path = path;
		this.index = index;
		this.settings = settings;
		this.playhead = { ini: 0, end: 1 };
		
		this.vec = new THREE.Vector3();
		this.prv = new THREE.Vector3();
		this.nxt = new THREE.Vector3();
		this.mat = new THREE.Matrix4();

		this.dummy = new THREE.Object3D();
		this.object3D = new THREE.Object3D();

		this.timeline = gsap.timeline();
		this.tween = { ini: 0, end: 1, spiral: 0, distAngle: 0 };

		this.initMesh();
		this.updatePositions();
		this.updateColors();
	}

	initMesh() {
		const { size, count } = this.settings;

		const geometry = new THREE.CircleGeometry(0.5, 12);
		
		const material = new THREE.ShaderMaterial({
			uniforms: {
				uColor: { value: new THREE.Color(1, 1, 1) },
			},
			vertexShader,
			fragmentShader,
			transparent: true,
		});
		
		const mesh = new THREE.InstancedMesh(geometry, material, count);

		this.object3D = mesh;
	}

	updatePositions() {
		const { path, object3D, vec, prv, nxt, mat, dummy } = this;
		const { count, size, noise2D } = this.settings;
		const { ini, end } = this.playhead;

		const endSeg 	 = end * count;
		const endFloor = Math.floor(endSeg);
		const endFract = endSeg % 1;

		const iniSeg 	 = ini * count;
		const iniFloor = Math.floor(iniSeg);
		const iniFract = iniSeg % 1;

		let t, u, n, p, scale, angle;
		let deltaScale;
		let prevScale = 0;
		let maxDelta = 1.0;

		for (let i = 0; i < count; i++) {
			t = i / count;
			p = Math.max(i - 1, 0);
			n = Math.min(i + 1, count - 1);

			vec.set(
				path.curve.mesh.geometry.attributes.position.getX(i),
				path.curve.mesh.geometry.attributes.position.getY(i),
				path.curve.mesh.geometry.attributes.position.getZ(i),
			);

			prv.set(
				path.curve.mesh.geometry.attributes.position.getX(p),
				path.curve.mesh.geometry.attributes.position.getY(p),
				path.curve.mesh.geometry.attributes.position.getZ(p),
			);

			nxt.set(
				path.curve.mesh.geometry.attributes.position.getX(n),
				path.curve.mesh.geometry.attributes.position.getY(n),
				path.curve.mesh.geometry.attributes.position.getZ(n),
			);

			// angle between prev and next points
			angle = vec.clone().sub(prv).angleTo(nxt.sub(vec));
			if (i == n || i == p) angle = 0;
			// remap
			angle = map(angle, 0, Math.PI, 0.02, 1.0);

			// the more curvature the bigger
			scale = angle * size;

			// avoid abrupt variations in scale
			deltaScale = scale - prevScale;
			if (deltaScale >  maxDelta) scale = prevScale + maxDelta;
			if (deltaScale < -maxDelta) scale = prevScale - maxDelta;
			prevScale = scale;

			// add a bit of randomness
			scale += (noise2D(t * 4, t * t) * 0.5 + 0.5) * size * 0.1;
			scale *= parabola(t, 1);

			if (i == iniFloor - 0) 			scale *= 1 - iniFract;
			else if (i == endFloor + 1) scale *= eases.backOut(endFract);
			else if (i < iniFloor || i > endFloor) scale *= 0;

			dummy.position.copy(vec);
			dummy.scale.set(scale, scale, scale);
			dummy.updateMatrix();

			object3D.setMatrixAt(i, dummy.matrix);
		}

		object3D.instanceMatrix.needsUpdate = true;
	}

	updateColors() {
		const { noise2D, count, seedColor, chroma: chromaMode } = this.settings;
		const { index, object3D } = this;
		const { geometry } = object3D;

		const goal 	= getGoal();
		const team 	= goal.teamDO;
		const kit 	= team.kits[goal.kit];
		const idxA 	= nweighted(kit.weights, noise2D, index * 11 + seedColor);
		const idxB 	= nweighted(kit.weights, noise2D, index * 88 + seedColor);
		const hexA 	= kit.colors[idxA];
		const hexB 	= kit.colors[idxB];
		const color = new THREE.Color();

		let grad;

		// see https://gka.github.io/chroma.js/
		if (chromaMode != 'none') {
			grad = chroma.scale([hexA, hexB]).mode(chromaMode).gamma(1.5).colors(count);
		}
		else {
			color.setStyle(hexA);
		}

		for (let i = 0; i < count; i++) {
			if (grad) color.setStyle(grad[i]);
			object3D.setColorAt(i, color);
		}

		object3D.instanceColor.needsUpdate = true;
	}

	updatePlayhead(ini = 0, end = 1) {
		this.playhead.ini = ini;
		this.playhead.end = end;
		this.updatePositions();
	}

	show() {
		const { distAngle, spiral, spiralGain, range } = this.settings;
		const { timeline, tween, index, path, parent } = this;
		const { ini, end } = path.segment;

		const duration = (6.0 + ini) * parent.speed;
		const delay = index * (0.4 + 0.2) * parent.speed + range.min * BASE_DURATION * parent.speed;

		const iniDistAngle = distAngle;
		const iniSpiral = spiral;

		timeline.clear();
		timeline.set(tween, { ini: 0, end: 0, distAngle: distAngle - Math.PI * 0.5, spiral: spiral * 0.1 });
		timeline.to(tween, { end: 1, duration, delay, ease: 'quart.out', onUpdate: () => {
			this.updatePlayhead(tween.ini, tween.end);
		}});

		timeline.to(tween, { spiral: iniSpiral, distAngle: iniDistAngle, duration: duration * 0.5, delay, ease: 'quart.out', onUpdate: () => {
			this.path.settings.distAngle = tween.distAngle;
			this.path.settings.spiral = tween.spiral;
			this.path.updatePositions();
		}}, 0);

		this.updatePlayhead(0, 0);

		return timeline;
	}

	hide() {
		const { tween, index, path, parent } = this;
		const { ini, end } = path.segment;

		const timeline = gsap.timeline();

		const duration = (BASE_DURATION + ini) * parent.speed;
		const delay = index * 0.4 * parent.speed;

		timeline.to(tween, { ini: 1, duration, ease: 'quad.in', onUpdate: () => {
			this.updatePlayhead(tween.ini, tween.end);
		}}, delay);

		this.timelineHide = timeline;

		return timeline;
	}

	dispose() {
		this.path.dispose();
		this.object3D.clear();
		this.timeline.kill();
		this.timelineHide?.kill();

		this.path = null;
		this.object3D = null;
		this.timeline = null;
	}
}
